import React, { Component } from 'react'
import InputSearch from "../../component/common/InputSearch";
import NewButton from "../../component/common/NewButton";
import ButtonSmall from "../../component/common/ButtonSmall";
import { Link, withRouter } from 'react-router-dom';
import filterIcon from '../../assets/images/filter.png'
import ReactTooltip from 'react-tooltip'
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/cancel.svg'
import Table from '../../component/common/Table';
import Paginate from "../../component/Paginate/index";
import PerPage from "../../component/Paginate/PerPage";
import { connect } from 'react-redux';
import { LIST_PRODUCT_REQUEST, DELETE_PRODUCT_REQUEST, GET_EXPORT_FILE, BASE_IMG } from '../../constants/Config'
import { postDataWithParams, getDataByID, getTakenData, postData, putData, deleteById } from '../../services/base_services';
import { confirmDelete, afterRemove, showMessage, moneyFormat, confirmChoice } from './../../helpers/table';
import { PER_PAGE } from "../../constants/Variable";
import { withTranslation } from 'react-i18next';

class Product extends Component {
    constructor(props) {
        super(props);

        const { t } = this.props;

        this.search=""
        this.state = {
            tableData: [],
            perPage: PER_PAGE,
            paginate: [],
            isLoading: true,
            tableHeaders: [
                {
                    label: 'Stt',
                    index: 'stt',
                    option: {
                        className: "text-center position-relative"
                    },
                    callback: null

                },
                {
                    label: 'code',
                    index: null,
                    option: {
                        className: ""
                    },
                    callback: data => {
                        return (
                            <div>
                                <span>{data && data.product_code}</span>
                            </div>
                        )
                    }
                }
                , {
                    label: 'type',
                    index: null,
                    option: {
                        className: "text-center"
                    },
                    callback: data => {
                        return (
                            <div>
                                <span>{data && data.category_product}</span>
                            </div>
                        )
                    }
                },
                {
                    label: 'price',
                    index: null,
                    option: {
                        className: "text-center"
                    },
                    callback: data => {
                        return (
                            <div>
                                {
                                    data && data.current_price !=0?

                                        <span>{data && data.current_price && moneyFormat(data && data.current_price)}</span>
                                        :
                                        <span>{data && data.price && moneyFormat(data && data.price)}</span>

                                }
                            </div>
                        )
                    }
                },
                {
                    label: 'distributor',
                    index: null,
                    option: {
                        className: "text-center"
                    },
                    callback: data => {
                        return (
                            <div>
                                <span>{data && data.distributor && data.distributor.name}</span>
                            </div>
                        )
                    }
                },
                {
                    label: 'status',
                    index: null,
                    option: {
                        className: "text-center"
                    },
                    callback: data => data.status_product ? <span>{t(`productStatuses.status_${data.status_product.id}`)}</span> : ""
                },
                {
                    label: 'functions',
                    index: null,
                    option: {
                        className: 'text-center fixed-collumn'
                    },
                    callback: (data) => {
                        if (data.id) {
                            return (
                                <div className="btn-group">
                                    <span data-tip={t('editProduct')} className="link-action mr-2">
                                        <img src={editIcon} className="btn-icon"
                                            onClick={() => this.editProduct(data.id)}
                                            alt=""
                                        />
                                    </span>
                                    <span data-tip={t('deleteProduct')} className="link-action ml-2">
                                        <img src={deleteIcon} className="btn-icon"
                                            onClick={() => this.onDeleteProduct(data.id)}
                                            alt=""
                                        />
                                    </span>
                                    <ReactTooltip className="tooltip-button" place="bottom" effect="float" />
                                </div>
                            );
                        }
                    }
                }
            ]
        }
    }

    editProduct = (id) => {
        this.props.history.push(`/product/edit/${id}/information`);
    }


    onDeleteProduct = (id) => {
        confirmDelete().then((res) => {
            if (res.value == true) {
                this.removeProductRequest(id);
            }
        })

    }

    removeProductRequest = (id) => {
        deleteById(DELETE_PRODUCT_REQUEST, id).then(res => {
            showMessage(res.data.message, true);
            this.getListProduct();
        }).catch(err => {
            afterRemove('Has error.', false);
        })
    }

    componentDidMount = async () => {
        this.getListProduct()
    }

    getListProduct = async () => {
        let { page, perPage } = this.state
        this.getListRequest({ page: page, per_page: perPage })
    }

    getListRequest = async (params) => {
        await postDataWithParams(LIST_PRODUCT_REQUEST, this.search, params).then(res => {
            this.setState({
                tableData: res.data.data,
                paginate: res.data.paginate,
                isLoading: false
            })
        }).catch(error => {
            return Promise.reject(error)
        })

    }

    onChangeSearch = (e) => {
        clearTimeout(this.timer);
        let { page, perPage } = this.state;
        let target = e.target;
        let name = target.name;
        let value = target.value ? target.value : "";

        this.search = {
            ...this.search,
            [name]: value
        }

        this.timer = setTimeout(() => this.getListRequest({ page: page, per_page: perPage }), 500);
    }



    changePage = (pageNumber, e) => {
        let { page, perPage } = this.state;
        if (pageNumber != page) {
            this.getListRequest({ page: pageNumber, per_page: perPage });
            this.setState({ page: pageNumber });
        }
    }

    exportExcel = () => {
        confirmChoice().then((res) => {
            if (res.value === true) {
                getTakenData(GET_EXPORT_FILE).then((res) => {
                    const url = `${BASE_IMG}${res?.data?.data}`;
                    const a = document.createElement("a");
                    a.href = url;
                    a.download = url.split("/").pop();
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                });
            }
        });
    };

    render() {
        let { tableHeaders, paginate, tableData, isLoading } = this.state
        const { t } = this.props;
        tableHeaders = tableHeaders.map(item => ({...item, label: t(item.label)}));

        return (
            <>
                <div className="container-fluid container-shadow">
                    <div className="row top-table">
                        <div className="col-md-4 top-table-title">
                            <p>{t('products')}</p>
                        </div>
                        <div className="col-md-12 d-flex">
                            <div className="d-flex align-items-center justify-content-between w-100">
                                <div className="d-flex">
                                    <InputSearch onChange={this.onChangeSearch} placeholder={t('productSearch')} />
                                    <Link to="/product/new" className="ml-2 remove-decoration">
                                        <ButtonSmall label={t('createProduct')} />
                                    </Link>
                                    <Link to="product/newlist" className="ml-2 remove-decoration">
                                        <ButtonSmall label={t('createMultiProduct')} />
                                    </Link>
                                </div>
                                <ButtonSmall label={t('exportExcel')}  onClick={this.exportExcel}/>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table fixed={true} tableHeaders={tableHeaders} tableData={tableData} tablePaginate={paginate} isLoading={isLoading} />
                        </div>
                        <div className="table-pagenatie ml-auto">
                            <div className="paginate-wrapper">
                                <Paginate
                                    paginate={paginate}
                                    changePage={this.changePage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {


    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('product')(withRouter(Product)));
