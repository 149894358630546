import React, { Component } from "react";
import {
  getError,
  checkErrorSingle,
  checkErrorMultikey,
} from "../../helpers/error";
import { uploadDocument } from "../../helpers/form";
import Alert from "./Alert";
import close from "../../assets/images/close.png";
import attach from "../../assets/images/upload.png";
import { BASE_IMG } from "../../constants/Config";
import Carousel from "react-multi-carousel";

class GroupImageCustomFile extends Component {
  constructor(props, context) {
    super(props, context);
    props.onChange(props.default);
    this.state = {
      loading: false,
      data: props.default,
      error: {},
    };
  }

  onChangeImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      this.setLoading(true);
      uploadDocument(file)
        .then((res) => {
          let data = `${BASE_IMG}${res.data.file}`;
          let image = [data, ...this.state.data];
          this.setState({ data: image, error: {} });
          this.props.onChange(image);
          this.setLoading(false);
        })
        .catch((err) => {
          this.setState({ error: err.error_upload });
          this.setLoading(false);
        });
    }
  };

  setLoading = (status) => {
    this.setState({
      loading: status,
    });
  };

  clickUpload = () => {
    document.querySelector(`#upload-input-${this.props.order}`).click();
  };

  removeImage = (key) => {
    let { data } = this.state;
    window._.remove(data, function (n, index) {
      return index == key;
    });

    this.setState({ data: [...data] });
    this.props.onChange([...data]);
  };

  render() {
    let { errors, name, label } = this.props;
    let { data, error, loading } = this.state;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div
        className={`${checkErrorMultikey(error, [
          "file",
          name,
        ])} ${checkErrorSingle(error, "file")}`}
      >
        <div className="upload-field up-gr-img">
          {label && <label className="input-label">{label}</label>}

          <button
            className={`btn btn-upload btn-upload${this.props.order} `}
            id="upload-label_2"
            disabled={loading}
            onClick={this.clickUpload}
          >
            {loading ? (
              <i className="ml-1 fas fa-circle-notch fa-spin text-white"></i>
            ) : (
              <img className="icon-upload" src={attach} />
            )}
          </button>
          <input
            type="file"
            className="upload-input"
            id={`upload-input-${this.props.order}`}
            onChange={(e) => this.onChangeImage(e)}
            onClick={(e) => {
              e.target.value = null;
            }}
          />
          <Carousel
            arrows={false}
            responsive={responsive}
            infinite={true}
            showDots={true}
          >
            {data.map((item, index) => (
              <div key={index}>
                <div className>
                  <a data-fancybox="images" href={item} key={index}>
                    <img src={item} style={{ textAlign: "center" }} />
                  </a>
                </div>
                <div className="remove-custom">
                  <span
                    className="remove"
                    onClick={() => this.removeImage(index)}
                  >
                    <img src={close} />
                  </span>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <Alert messsage={getError(errors, name)} />
        <Alert messsage={getError(error, "file")} />
      </div>
    );
  }
}

export default GroupImageCustomFile;
