import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ProductManager = (props) => {
  const { idProduct } = props;
  const { t } = useTranslation('product');

  return (
    <div className="manager_menu">
      <ul id="list-menu" className="manager_menu_list d-flex">

        <li>
          <NavLink
            to={`/product/edit/${idProduct}/information`}
            activeClassName="active"
            className="manager_menu_list_navLink"
          >
            <span>{t('commonInfomation')}</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/product/edit/${idProduct}/picture`}
            activeClassName="active"
            className="manager_menu_list_navLink"
          >
            <span>{t('productImage')}</span>
          </NavLink>
        </li>

      </ul>
    </div>
  )
}

export default ProductManager;
