import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import ProductBlock from './../container/Product/ProductBlock';
import ProductBDS from './../container/Product/ProductBDS';
import ProductBuilding from './../container/Product/ProductBuilding';
import ProductFloor from './../container/Product/ProductFloor';
import Product from './../container/Product/Product'
import ProductNew from './../container/Product/ProductNew'
import ProductTemplateNew from "../container/Product/ProductTemplateNew";
import ProductTemplate from "../container/Product/ProductTemplate"
import ProductTemplateEdit from "../container/Product/ProductTemplateEdit";
import ProductFloorTemplate from "../container/Product/ProductFloorTemplate"
import ProductFloorTemplateNew from '../container/Product/ProductFloorTemplateNew'
import ProductFloorTemplateEdit from '../container/Product/ProductFloorTemplateEdit'
import ProductNewList from '../container/Product/ProductNewList'
import ProductEdit from '../container/Product/ProductEdit'
import ProductEditInformation from '../container/Product/ProductEditInformation'
import ProductEditFile from '../container/Product/ProductEditFile'
import ProductStatus from '../container/Product/ProductStatus';
import ProductPhase from '../container/Product/ProductPhase';
import ProductTemplateEditInformation from '../container/Product/ProductTemplateEditInformation'
import ProductTemplateEditFile from '../container/Product/ProductTemplateEditFile'
import ProductPhaseNew from '../container/Product/ProductPhaseNew'
import ProductPhaseEdit from '../container/Product/ProductPhaseEdit'
import AuthRoute from './AuthRoute';

const routes = [
    {
        path: '/product',
        component: () => <Product />,
        exact: true
    },
    {
        path: '/product/new',
        component: () => <ProductNew />,
        exact: true
    },
    {
        path: '/product/status',
        component: () => <ProductStatus />,
        exact: true,
    },
    {
        path: '/product/phase',
        component: () => <ProductPhase />,
        exact: true,
    },
    {
        path: '/product/phase/new',
        component: () => <ProductPhaseNew />,
        exact: true,
    },
    {
        path: '/product/phase/edit/:id',
        component: () => <ProductPhaseEdit />,
        exact: true,
    },
    {
        path: '/product/bds',
        component: () => <ProductBDS />,
        exact: true,
    },
    {
        path: '/product/block',
        component: () => <ProductBlock />,
        exact: true,
    },
    {
        path: '/product/building',
        component: () => <ProductBuilding />,
        exact: true,
    },
    {
        path: '/product/floor',
        component: () => <ProductFloor />,
        exact: true,
    },
    {
        path: '/product/template/new',
        component: () => <ProductTemplateNew />,
        exact: true,
    },
    {
        path: '/product/template',
        component: () => <ProductTemplate />,
        exact: true,
    },
    {
        path: '/product/template/edit/:id/information',
        component: () => <ProductTemplateEditInformation />,
        exact: true,
    },
    {
        path: '/product/template/edit/:id/picture',
        component: () => <ProductTemplateEditFile />,
        exact: true,
    },
    {
        path: '/product/floortemp',
        component: () => <ProductFloorTemplate />,
        exact: true,
    },
    {
        path: '/product/floortemp/new',
        component: () => <ProductFloorTemplateNew />,
        exact: true,
    },
    {
        path: '/product/floortemp/edit/:id',
        component: () => <ProductFloorTemplateEdit />,
        exact: true,
    },
    {
        path: '/product/newlist',
        component: () => <ProductNewList />,
        exact: true,
    },
    {
        path: '/product/edit/:id',
        component: () => <ProductEdit />,
        exact: true,
    },
    {
        path: '/product/edit/:id/information',
        component: () => <ProductEditInformation />,
        exact: true,
    },
    {
        path: '/product/edit/:id/picture',
        component: () => <ProductEditFile />,
        exact: true,
    },
];


class ProductRoute extends Component {
    render() {
        if (typeof (window) !== "undefined") {
            return (
                <React.Fragment>
                    <Switch>
                        {
                            routes.map(route => (
                                <AuthRoute key={route.path} {...route} />
                            ))
                        }
                    </Switch>
                </React.Fragment>
            )
        }
    }
}

export default ProductRoute;
