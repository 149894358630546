import React, { Component } from "react";
import { Switch } from "react-router-dom";
import SalesPolicyTable from "./../container/SalesPolicy/SalesPolicyTable";
import SalesPolicyNew from "./../container/SalesPolicy/SalesPolicyNew";
import SalesPolicyEdit from "./../container/SalesPolicy/SalesPolicyEdit";
import AuthRoute from "./AuthRoute";

const routes = [
  {
    path: "/sales_policy",
    component: () => <SalesPolicyTable />,
    exact: true,
    meta: {
      action: "sales_pocily.list",
      subject: "sales_pocilys",
    },
  },
  {
    path: "/sales_policy/new",
    component: () => <SalesPolicyNew />,
    exact: true,
    meta: {
      action: "sales_pocily.add",
      subject: "sales_pocilys",
    },
  },
  {
    path: "/sales_policy/:id/edit",
    component: () => <SalesPolicyEdit />,
    exact: true,
    meta: {
      action: "sales_pocily.edit",
      subject: "sales_pocilys",
    },
  },
];

class SalesPolicyRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
            {routes.map((route) => (
              <AuthRoute key={route.path} {...route} />
            ))}
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default SalesPolicyRoutes;
