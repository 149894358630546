import React, { Component } from "react";
import ButtonSave from "../../component/common/ButtonSave";
import { Link, withRouter } from "react-router-dom";
import Select from "../../component/common/Select";
import InputCurrency from "../../component/common/InputCurrency";
import {
  GET_BUILDING_LIST,
  UPDATE_TEMP_PRODUCT_FLOOR_LIST,
  CREATE_MULTI_PRODUCT,
  GET_DISTRIBUTOR_SELECT_URL,
} from "../../constants/Config";
import { getTakenData, postData } from "../../services/base_services";
import { getFormDataFromRef } from "../../helpers/form";
import { destructServerErrors } from "../../helpers/error";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import { showMessage } from "./../../helpers/table";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class ProductNewList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      perPage: 5,
      tableData: [],
      dataTable: [],
      isLoadTable: true,
      listDis: [],
      isLoading: true,
      listBDS: [],
      listFloor: [],
      paginate: [],
      errors: [],
      tableHeaders: [
        {
          label: "Stt",
          index: "stt",
          option: {
            className: "text-center position-relative",
          },
        },
        {
          label: "Mã sản phẩm",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.product_code}</span>
              </div>
            );
          },
        },
        {
          label: "Tầng",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.floor && data.floor.name}</span>
              </div>
            );
          },
        },
        {
          label: "Loại căn",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>
                  {data && data.template_product && data.template_product.name}
                </span>
              </div>
            );
          },
        },
        {
          label: "Trạng thái",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                {data.status == 1 ? (
                  <span>Tạo thành công</span>
                ) : (
                  <span>Trùng mã căn</span>
                )}
              </div>
            );
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getList();
  };
  getList = async () => {
    await getTakenData(GET_BUILDING_LIST).then((res) => {
      this.setState({
        listBDS: res.data.data,
        loading_list: false,
      });
    });
    await getTakenData(UPDATE_TEMP_PRODUCT_FLOOR_LIST).then((res) => {
      this.setState({
        listFloor: res.data.data,
        loading_list: false,
      });
    });
    await getTakenData(GET_DISTRIBUTOR_SELECT_URL).then((res) => {
      this.setState({
        listDis: res.data.data,
      });
    });
  };
  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);

    postData(CREATE_MULTI_PRODUCT, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true,
          dataTable: res.data.data,
          tableData: res.data.data.slice(0, 5),
          isLoadTable: false,
          paginate: {
            total: res.data.data.length,
            per_page: 5,
            prev: null,
            next: res.data.data.length < 6 ? null : 2,
            current_page: 1,
          },
        });
      })
      .catch((err) => {
        let errs = destructServerErrors(err);
        this.setState({
          errors: errs,
        });
      });
  };

  changePage = (pageNumber, e) => {
    let { page, perPage, dataTable } = this.state;
    if (pageNumber != page) {
      this.setState({
        tableData: dataTable.slice(pageNumber * 5 - 5, pageNumber * 5),
        paginate: {
          total: dataTable.length,
          per_page: 5,
          prev: pageNumber == 1 ? null : pageNumber - 1,
          next: pageNumber == dataTable.length / 5 ? null : pageNumber + 1,
          current_page: pageNumber,
        },
      });
      this.setState({ page: pageNumber });
    }
  };

  render() {
    let {
      listBDS,
      listFloor,
      errors,
      tableData,
      isLoadTable,
      paginate,
      tableHeaders,
    } = this.state;
    const { t, setting } = this.props;
    tableHeaders = tableHeaders.map(item => ({...item, label: t(item.label)}));

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('createMultipleProduct')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/product">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {setting?.[2]?.status == 1 && (
                <Select
                  name="building_id"
                  ref="building_id"
                  className="form-control input-form form-control-product mb-3"
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  include_blank={t('productBuilding')}
                  data={listBDS}
                  label={`${t('productBuilding')}:`}
                />
              )}
            </div>
            <div className="col-md-6">
              <Select
                name="template_floor_id"
                ref="template_floor_id"
                className="form-control input-form form-control-product  mb-3"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('productFloorTemplate')}
                data={listFloor}
                label={`${t('productFloorTemplate')}:`}
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={t('applyFromFloor')}
                name="from_floor"
                ref="from_floor"
                errors={errors}
                type="text"
                label={`${t('applyFromFloor')}:`}
                maxLength={3}
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={t('applyToFloor')}
                name="to_floor"
                ref="to_floor"
                errors={errors}
                type="text"
                label={`${t('applyToFloor')}:`}
                maxLength={3}
              />
            </div>
          </div>

          {isLoadTable ? (
            ""
          ) : (
            <div className="row pt-5">
              <div className="col-md-12">
                <Table
                  fixed={true}
                  tableHeaders={tableHeaders}
                  tableData={tableData}
                  tablePaginate={paginate}
                  isLoading={isLoadTable}
                />
              </div>
              <div className="table-pagenatie ml-auto">
                <div className="paginate-wrapper">
                  <Paginate paginate={paginate} changePage={this.changePage} />
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    setting: state.user?.user?.settings,
  };
};

export default connect(mapStateToProps, null)(withTranslation('product')(withRouter(ProductNewList)));
