import React, { Component } from 'react'
import CustomerTag from './CustomerTag'
import { withRouter } from 'react-router-dom';
import { getDetailCustomerRequest } from '../../actions/customer'

class CustomerDetail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {},
      errors: {}
    }
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      await getDetailCustomerRequest(id).then(res => {
        this.setState({ data: res.data.data });
      }).catch(err => {
        this.setState({ errors: err.err });
      })
    }
  }

  render() {
    let data = this.state.data;

    return (
      <>
        <div className="container-fluid container-shadow">
          <CustomerTag />
          <div className="content-customer-detail">
            <p className="title">Thông tin cơ bản</p>
            <div className="row">
              <div className="col-6">
                <p className="label">Họ và tên</p>
                <span>{data && data.name}</span>
              </div>
              <div className="col-6">
                <p className="label">Email</p>
                <span>{data && data.email}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <p className="label">Số điện thoại</p>
                <span>{data && data.phone}</span>
              </div>
              <div className="col-6">
                <p className="label">Địa chỉ</p>
                <span>{data && data.address}</span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <p className="label">Đơn vị phân phối</p>
                <span>{data && data.distributor && data.distributor.name}</span>
              </div>
              <div className="col-6">
                <p className="label">Số khách hàng tiếp cận</p>
                <span></span>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-6">
                <p className="label">Số lượng sản phẩm đã bán</p>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withRouter(CustomerDetail);