import React from "react";

const ButtonSave = (props) => {
  const { text, onClick, className, disabled, ...input } = props;

  return (
    <div className={`button-new ${className ? `button-new-${className}` : ""}`} {...input}>
      <span className={`${disabled ? "btn__disabled" : ""}`} onClick={onClick}>
        {text}
      </span>
    </div>
  );
};

export default ButtonSave;
