import * as types from "./../constants/actionType";

const initialState = {
  data: [],
  paginate: {},
  isLoading: true,
  message: null,
  isRequest: false,
  status: false,
  errors: {},
  roles: null,
};

const Roles = (state = initialState, action) => {
  switch (action.type) {
    case types.ROLES_REQUEST:
      return {
        ...state,
        isLoading: action.status,
      };
    case types.RESET_ROLES:
      return {
        ...state,
        isRequest: false,
        status: false,
        errors: {},
        distributor: null,
      };
    case types.GET_ROLES_LIST:
      return {
        ...state,
        data: [...action.data.data],
        paginate: { ...action.data.paginate },
        isLoading: false,
      };

    case types.GET_PERMISSIONS_LIST:
      return {
        ...state,
        permissions: [...action.data.data],
        isLoading: false,
      };

    case types.CREATE_PERMISSIONS:
      if (action.status) {
        return {
          ...state,
          status: true,
          message: "Create role success!",
          errors: {},
        };
      } else {
        return {
          ...state,
          status: false,
          message: "Please check errors below.",
          errors: action.data,
        };
      }
    default:
      return { ...state };
  }
};

export default Roles;
