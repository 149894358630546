import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import { Link, withRouter, Redirect } from "react-router-dom";
import {
  getDetailCustomerRequest,
  updateCustomerRequest,
} from "../../actions/customer";
import { getFormDataFromRef } from "../../helpers/form";
import InputPhoneCustom from "../../component/common/InputPhoneCustom";
import Select from "../../component/common/Select";
import { phoneFormatCustom, showMessage } from "./../../helpers/table";
import { getTakenData, postDataWithParams } from "../../services/base_services";
import { LIST_DISTRIBUTOR, GET_LIST_SALE_URL } from "../../constants/Config";
import CustomerTag from "./CustomerTag";
import ButtonSave from "../../component/common/ButtonSave";
import Datepicker from "../../component/common/Datepicker";
import InputCurrency from "../../component/common/InputCurrency";
import TextArea from "../../component/common/TextArea";
import CheckboxRowCustom from "../../component/common/CheckboxRowCustom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
class CustomerEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDistributor: [],
      errors: {},
      data: {},
      listSale: [],
    };
  }

  componentDidMount = async () => {
    let listDistributor = await getTakenData(LIST_DISTRIBUTOR);
    this.setState({
      listDistributor: listDistributor.data.data,
    });
    let { id } = this.props.match.params;
    if (id) {
      getDetailCustomerRequest(id)
        .then((res) => {
          this.setState({ data: res.data.data });
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
    if(this.props.user.position_id === 1 ) {
      this.loadListSale();
    }
  };

  loadListSale = async () => {
    await postDataWithParams(GET_LIST_SALE_URL, {}, {})
      .then((res) => {
        this.setState({
          listSale: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  onUpdateCustomer = (e) => {
    let { id } = this.props.match.params;
    e.preventDefault();
    let data = getFormDataFromRef(this.refs);
    let var_birthday = data.birthday;
    data.birthday = var_birthday?.split("-").reverse().join("-");
    let val = data.phone;
    data.phone = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`;
        } else if (s) {
          return `${f}${s}`;
        } else if (f) {
          return `${f}`;
        }
      });
    data = {
      ...data,
      status: data.status_id,
      public_flg: data.public_flg ? 1 : 2,
      description: this.description,
    };
    delete data.status_id;
    if (!this.state.data?.creator_flg) {
      delete data.public_flg;
    }
    if (id) {
      updateCustomerRequest(id, data)
        .then((res) => {
          showMessage(res.data.message, true);
          this.props.history.goBack();
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
  };

  onChangeTextArea = (data) => {
    this.description = data;
  };

  onChangeDVPP = (e) => {
    postDataWithParams(GET_LIST_SALE_URL, {}, { distributor_id: e.target.value })
      .then((res) => {
        this.setState({
          listSale: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  render() {
    let { errors, listDistributor, data, listSale } = this.state;
    let { t, user } = this.props;
    let position_id = user?.position_id;
    let check_public_flg = data?.public_flg === 1 ? true : false;
    let checkboxesAll = document.getElementsByName("public_flg");
    if (checkboxesAll.length > 0) {
      if (check_public_flg) {
        checkboxesAll[0].checked = true;
      } else {
        checkboxesAll[0].checked = false;
      }
    }

    const listStatusCustomer = [
      {
        id: 4,
        name: t('closeSale'),
      },
      {
        id: 3,
        name: t('consulting'),
      },
      {
        id: 1,
        name: t('newestCustomer'),
      },
      {
        id: 2,
        name: t('approach'),
      },
      
    ];

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('showCustomer')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave
                onClick={this.onUpdateCustomer}
                text={t('save')}
              />
              <Link to="/customer">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row ml-0 mr-0 mb-3">
            <div className="col-md-12 pl-0 pr-0">
              <CustomerTag />
            </div>
          </div>
          <div className="row top-table">
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('fullname')}
                name="name"
                ref="name"
                label={`${t('fullname')}:`}
                errors={errors}
                type="text"
                defaultValue={data?.name}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('address')}
                name="address"
                ref="address"
                label={`${t('address')}:`}
                errors={errors}
                type="text"
                defaultValue={data?.address}
              />
            </div>
            <div className="col-md-6">
              <InputPhoneCustom
                name="phone"
                ref="phone"
                label={`${t('phone')}:`}
                type="text"
                className="form-control input-form"
                placeholder={t('phone')}
                errors={errors}
                defaultValue={data?.phone && phoneFormatCustom(data?.phone)}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder="Email"
                name="email"
                ref="email"
                label="Email:"
                errors={errors}
                type="text"
                defaultValue={data?.email}
              />
            </div>
            <div className="col-md-6">
              <Datepicker
                name="birthday"
                ref="birthday"
                label={`${t('birthday')}:`}
                defaultValue={null}
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
                value={data?.birthday}
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={`${t('incomeYearly')}:`}
                name="income"
                ref="income"
                label={`${t('income')}:`}
                errors={errors}
                type="text"
                defaultValue={data?.income}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('job')}
                name="job"
                ref="job"
                label={`${t('job')}:`}
                errors={errors}
                type="text"
                defaultValue={data?.job}
              />
            </div>

            {position_id !== 2 ? (
              position_id !== 1 ? (
                <>
                  <div className="col-md-6">
                    <Select
                      className="form-control input-form"
                      name="distributor_id"
                      ref="distributor_id"
                      label={`${t('distributor')}:`}
                      include_blank={t('distributor')}
                      key_value="id"
                      key_label="name"
                      data={listDistributor}
                      errors={errors}
                      defaultValue={data?.distributor}
                      onChange={(e) => this.onChangeDVPP(e)}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="form-control input-form"
                      name="saler_id"
                      ref="saler_id"
                      label={`${t('saleName')}:`}
                      include_blank={t('saleName')}
                      key_value="id"
                      key_label="fullname"
                      data={listSale}
                      errors={errors}
                      defaultValue={data?.saler}
                    />
                  </div>
                </>
              ) : (
                <div className="col-md-6">
                  <Select
                    className="form-control input-form"
                    name="saler_id"
                    ref="saler_id"
                    label={`${t('saleName')}:`}
                    include_blank={t('saleName')}
                    key_value="id"
                    key_label="fullname"
                    data={listSale}
                    errors={errors}
                    defaultValue={data?.saler}
                  />
                </div>
              )
            ) : (
              ""
            )}
            <div className="col-md-6">
              <Select
                className="form-control input-form"
                name="status_id"
                ref="status_id"
                label={`${t('status')}:`}
                include_blank={t('status')}
                key_value="id"
                key_label="name"
                data={listStatusCustomer}
                errors={errors}
                defaultValue={{ id: data?.status, name: data?.status_lable }}
              />
            </div>
            {data?.creator_flg && (
              <div className="col-md-12">
                <div className="block-checkbox-custom-customer">
                  <CheckboxRowCustom
                    errors={[]}
                    id="public_flg"
                    ref="public_flg"
                    label={t('shareCustomerInfo')}
                    value="public_flg"
                    name="public_flg"
                    defaultChecked={check_public_flg}
                  />
                </div>
              </div>
            )}
            <div className="col-md-12">
              <TextArea
                name="description"
                ref="description"
                label={`${t('description')}:`}
                className="text-area-custom"
                errors={errors}
                rows="3"
                onChange={this.onChangeTextArea}
                defaultValue={data?.description}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(withTranslation('customer')(withRouter(CustomerEdit)));
