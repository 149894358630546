import React from "react";
import iconValue from "../../assets/images/icons/value.svg";
import iconApproach from "../../assets/images/icons/approach.svg";
import iconOnlineBusiness from "../../assets/images/icons/online-business.svg";
import iconBestPrice from "../../assets/images/icons/best_price.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BlockTableList = (props) => {
  const { CustomerStatistic, Customer } = props;
  const { t } = useTranslation('dashboard');

  return (
    <div className="block-table-list">
      <div className="block-title">
        <p className="title-list">{t('customer')}</p>
      </div>
      <div className="block-item">
        <div className="row ml-0 mr-0">
          <div className="col-3">
            <div className="item-detail item-detail-1">
              <p>{t('newCustomer')}</p>
              <span>
                {Customer?.customer_new} <img src={iconValue} alt="" />
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="item-detail item-detail-2">
              <p>{t('approach')}</p>
              <span>
                {Customer?.customer_approach} <img src={iconApproach} alt="" />
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="item-detail item-detail-3">
              <p>{t('consulting')}</p>
              <span>
                {Customer?.customer_consoulting}{" "}
                <img src={iconOnlineBusiness} alt="" />
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="item-detail item-detail-4">
              <p>{t('closingSale')}</p>
              <span>
                {Customer?.customer_closing_sale}{" "}
                <img src={iconBestPrice} alt="" />
              </span>
            </div>
          </div>
        </div>
        <p className="title-list title-list-table">{t('newCustomer')}</p>
      </div>
      <div className="block-list">
        <div className="table-dashboard-maintenance">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">{t('customerName')}</th>
                <th className="text-center">{t('following')}</th>
                <th>{t('status')}</th>
                <th className="text-center">{t('customerDetail')}</th>
              </tr>
            </thead>
            <tbody>
              {CustomerStatistic?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{item.full_name}</td>
                    <td className="text-center">{item.favorite}</td>
                    <td className="text-center">
                      <button
                        className={`btn-new-list btn-new-list-${item.status}`}
                      >
                        {item.status_lable}
                      </button>
                    </td>
                    <td className="text-center">{item.description||"--"}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-center">
          <Link to="/customer" className="view-more-detail">
            {t('viewMore')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlockTableList;
