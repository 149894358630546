import React, { Component } from 'react';

import { checkError, getError } from '../../helpers/error';
// import Alert from '../form/Alert';

const CheckboxRow = React.forwardRef((props, ref) => {
    let { errors, id, label, wrapperClass, ...input } = props;
    return (
        <div className="custom-control custom-control-checkboxRow custom-checkbox">
            <input type="checkbox" className="custom-control-input" {...input} id={id} ref={ref} />
            <label className="custom-control-label" htmlFor={id}>{label}</label>
        </div>
    )
});

export default CheckboxRow;

//${checkError(errors, input.name)}
