import React, { Component } from 'react'
import CustomerTag from './CustomerTag'
import Table from '../../component/common/Table'

class CustomerSale extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tableHeaderSale: [
        {
          label: 'STT',
          index: 'stt',
          option: {
            className: "text-center"
          },
          callback: null
        },
        {
          label: 'NGƯỜI DÙNG',
          index: "title",
        },
        {
          label: 'VAI TRÒ',
          index: "amount",

        },
        {
          label: 'HÀNH ĐỘNG',
          index: "percent",
        },
        {
          label: 'THỜI GIAN TRUY CẬP',
          index: "percent",
        }
      ]
    }
  }


  render() {

    let { tableHeaderSale } = this.state

    return (
      <>
        <div className="container-fluid container-shadow">
          <CustomerTag />
          <div className="row table-border">
            <div className="col-md-12 name-header-part">
              <Table fixed={true} tableHeaders={tableHeaderSale} tableData={[]} isLoading={false} />
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default CustomerSale