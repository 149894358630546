import { postDataWithParams, getDataByID, postData, putDataWithUrl, putData } from './../services/base_services';
import { GET_LIST_SALE_URL, CREATE_SALE_URL, DETAIL_SALE_URL, GET_PROJECT_SALE_URL, GET_CUSTOMER_SALE_URL, UPDATE_SALE_URL } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors } from '../helpers/error';

const saleRequest = status => {
  return {
    type: types.SALE_REQUEST,
    status
  }
}

// GET LIST SALE
const getSale = (data, paginate) => {
  return {
    type: types.GET_SALE_LIST,
    data,
    paginate
  }
}

export const getSaleRequest = (search, params) => dispatch => {
  dispatch(saleRequest(true));
  postDataWithParams(GET_LIST_SALE_URL, search, params).then(res => {
    dispatch(getSale(res.data, res.paginate));
    dispatch(saleRequest(false));
  }).catch(error => {
    dispatch(saleRequest(false));
    return Promise.reject(error)
  })
}

// CREATE
export const createSaleRequest = (data) => {
  return postData(CREATE_SALE_URL, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET
export const getDetailSaleRequest = (id) => {
  return getDataByID(DETAIL_SALE_URL, id).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET LIST PRODUCT SALE
export const getListProductSale = (data) => {
  return putDataWithUrl(GET_PROJECT_SALE_URL, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET LIST CUSTOMER SALE
export const getListCustomerSale = (data) => {
  return putDataWithUrl(GET_CUSTOMER_SALE_URL, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}

// UPDATE
export const updateSaleRequest = (id, data) => {
  return putData(UPDATE_SALE_URL, id, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}