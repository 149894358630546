import {
  postDataWithParams,
  getTakenData,
  postData,
  getDataByID,
  putData
} from "./../services/base_services";
import {
  GET_ROLES_URL,
  GET_PERMISSIONS_URL,
  NEW_PERMISSIONS_URL,
  DETAIL_PERMISSIONS_URL,
  UPDATE_PERMISSIONS_URL
} from "../constants/Config";
import * as types from "./../constants/actionType";
import { destructServerErrors, destructServerMessage } from "../helpers/error";

const rolesRequest = (status) => {
  return {
    type: types.ROLES_REQUEST,
    status,
  };
};

const permissionsRequest = (status) => {
  return {
    type: types.PERMISSIONS_REQUEST,
    status,
  };
};

const getRoles = (data, paginate) => {
  return {
    type: types.GET_ROLES_LIST,
    data,
    paginate,
  };
};

const getPermissions = (data) => {
  return {
    type: types.GET_PERMISSIONS_LIST,
    data,
  };
};

export const getRolesRequest = (search, params) => (dispatch) => {
  dispatch(rolesRequest(true));
  postDataWithParams(GET_ROLES_URL, search, params)
    .then((res) => {
      dispatch(getRoles(res.data, res.paginate));
      dispatch(rolesRequest(false));
    })
    .catch((error) => {
      dispatch(rolesRequest(false));
      return Promise.reject(error);
    });
};

export const getPermissionsRequest = () => (dispatch) => {
  dispatch(permissionsRequest(true));
  getTakenData(GET_PERMISSIONS_URL)
    .then((res) => {
      dispatch(getPermissions(res.data));
      dispatch(permissionsRequest(false));
    })
    .catch((error) => {
      dispatch(permissionsRequest(false));
      return Promise.reject(error);
    });
};

export const createPermissionsRequest = (data) => {
  return postData(NEW_PERMISSIONS_URL, data)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerErrors(err);
      return Promise.reject({ err: msg });
    });
};



export const resetState = () => {
  return {
    type: types.RESET_ROLES,
  };
};


// GET
export const getDetailRolesRequest = (id) => {
  return getDataByID(DETAIL_PERMISSIONS_URL, id).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}

// UPDATE
export const updateRolesRequest = (id, data) => {
  return putData(UPDATE_PERMISSIONS_URL, id, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}