import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import { createBlockRequest, getBlockRequest } from "../../actions/bds";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  DELETE_BLOCK_CATE_URL,
  NEW_BLOCK_CATE_URL,
  UPDATE_BLOCK_CATE_URL,
} from "../../constants/Config";
import { deleteById, postData, putData } from "../../services/base_services";
import { confirmDelete, showMessage } from "./../../helpers/table";
import {
  destructServerErrors,
  destructServerMessage,
} from "../../helpers/error";
import { PER_PAGE } from "../../constants/Variable";
import { withTranslation } from "react-i18next";

class ProductBDS extends Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      item: null,
      perPage: PER_PAGE,
      paginate: [],
      errors: [],
      tableHeaders: [
        {
          label: "Stt",
          index: "stt",
          option: {
            className: "text-center",
          },
        },
        {
          label: "name",
          index: "name",
        },
        {
          label: "functions",
          index: null,
          option: {
            className: "text-center fixed-collumn",
          },
          callback: (data) => {
            if (data.id) {
              return (
                <div className="btn-group">
                  <span
                    data-tip={t('realestateTypeEdit')}
                    className="link-action mr-2"
                  >
                    <img
                      src={editIcon}
                      className="btn-icon"
                      onClick={() => this.selectItem(data)}
                    />
                  </span>
                  <span data-tip={t('realestateTypeDelete')} className="link-action ml-2">
                    <img
                      src={deleteIcon}
                      className="btn-icon"
                      onClick={() => this.deleteBlock(data.id)}
                    />
                  </span>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.loadBlockList();
    setTimeout(() => {
      this.setState({
      });
    }, 3000);
  }

  selectItem = (data) => {
    this.setState({
      item: data,
    });
  };

  deleteBlock = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeBlockRequest(id);
      }
    });
  };

  removeBlockRequest = (id) => {
    deleteById(DELETE_BLOCK_CATE_URL, id)
      .then((res) => {
        showMessage(this.props.t('realestateTypeDeleteSuccess'), true);
        this.loadBlockList();
      })
      .catch((err) => {
        showMessage(this.props.t('responseFail'), false);
      });
  };

  loadBlockList = () => {
    let { page, perPage } = this.state;
    this.props.getBlockList(this.search, { page: page, per_page: perPage });
    this.setState({
      item: null,
    });
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getBlockList(this.search, {
        page: pageNumber,
        per_page: perPage,
      });
      this.setState({ page: pageNumber });
    }
  };

  closeEdit = () => {
    document.getElementById("form-product").reset();
    this.setState({
      item: null,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    const { item } = this.state;
    if (item === null) {
      postData(NEW_BLOCK_CATE_URL, formData)
        .then((res) => {
          showMessage(this.props.t('realestateTypeCreateSuccess'), true);
          this.loadBlockList();
          this.setState({
            errors: [],
          });
          document.getElementById("form-product").reset();
        })
        .catch((err) => {
          showMessage(this.props.t('responseFail'), false);
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        });
    } else {
      putData(UPDATE_BLOCK_CATE_URL, item.id, formData)
        .then((res) => {
          showMessage(this.props.t('realestateTypeEditSuccess'), true);
          this.loadBlockList();
          this.setState({
            errors: [],
          });
          document.getElementById("form-product").reset();
        })
        .catch((err) => {
          showMessage(this.props.t('responseFail'), false);
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        });
    }
  };

  render() {
    let { tableHeaders, item, errors } = this.state;
    const { t, bds, isLoading } = this.props;
    const tableData = bds && bds.data;
    const paginate = bds && bds.paginate;
    tableHeaders = tableHeaders.map(item => ({...item, label: t(item.label)}));

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col top-table-title">
              <p>{t('realestateType')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <form className="form-product">
                <p className="form-product-title text-center">
                  {t('realestateTypeList')}
                </p>

                <Table
                  fixed={true}
                  tableHeaders={tableHeaders}
                  tableData={tableData}
                  tablePaginate={paginate}
                  isLoading={isLoading}
                />

                <div className="d-flex">
                  <div className="table-pagenatie ml-auto">
                    <div className="paginate-wrapper">
                      <Paginate
                        paginate={paginate}
                        changePage={this.changePage}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-5">
              <form
                className="form-product"
                id="form-product"
                onSubmit={(e) => this.submitForm(e)}
              >
                <p className="form-product-title text-center">
                  {item === null ? t('newInformation') : t('updateInformation')}
                </p>
                <div className="form-product-body px-5">
                  <p className="form-product-body-name">
                    {t('realestateTypeName')}
                  </p>
                  <InputText
                    className="form-control input-form form-control-product "
                    placeholder={t('realestateTypeName')}
                    name="name"
                    ref="name"
                    errors={errors}
                    defaultValue={item && item.name}
                  />

                  <div className="button-confirm  text-center pt-5 pb-5">
                    <span className="" onClick={(e) => this.submitForm(e)}>
                      {item === null ? t('created') : t('updated')}
                    </span>
                  </div>
                  <div className="button-confirm  text-center  pb-5">
                    {item === null ? (
                      ""
                    ) : (
                      <span className="" onClick={() => this.closeEdit()}>
                        {t('cancel')}
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    bds: state.BDS,
    isLoading: state.BDS.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getBlockList: (data, params) => dispatch(getBlockRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('product')(withRouter(ProductBDS)));
