import React, { Component } from "react";
import CustomerTag from "./CustomerTag";
import Table from "../../component/common/Table";
import { withRouter } from "react-router-dom";
import Paginate from "../../component/Paginate/index";
import InputSearch from "../../component/common/InputSearch";
import { getListFavoriteCustomer } from "../../actions/customer";
import {PER_PAGE} from '../../constants/Variable'
import { withTranslation } from "react-i18next";
class CustomerFavorite extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.state = {
      data: [],
      paginate: {},
      page: 1,
      perPage: PER_PAGE,
      isLoading: true,
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;
    let { page, perPage } = this.state;
    getListFavoriteCustomer(id, this.search, { page: page, per_page: perPage })
      .then((res) => {
        this.setState({
          data: res.data.data,
          paginate: res.data.paginate,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      let { id } = this.props.match.params;
      getListFavoriteCustomer(id, this.search, {
        page: pageNumber,
        per_page: perPage,
      })
        .then((res) => {
          this.setState({
            data: res.data.data,
            paginate: res.data.paginate,
          });
        })
        .catch((err) => {
          console.error(err);
        });

      this.setState({ page: pageNumber });
    }
  };

  onChangeSearch = (e) => {
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";

    this.search = {
      ...this.search,
      [name]: value,
    };

    let { id } = this.props.match.params;
    getListFavoriteCustomer(id, this.search, {
      page: page,
      per_page: perPage,
    })
      .then((res) => {
        this.setState({
          data: res.data.data,
          paginate: res.data.paginate,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { t } = this.props;
    let { paginate, data, isLoading } = this.state;
    const tableHeaderFavorite = [
      {
        label: "STT",
        index: "stt",
        option: {
          className: "text-center",
        },
        callback: null,
      },
      {
        label: t('productCode'),
        index: "product_code",
        option: {
          className: "text-center",
        },
        callback: null,
      },
      {
        label: t('price'),
        index: "current_price",
        option: {
          className: "text-center",
        },
        callback: null,
      },
      {
        label: t('distributorName'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>{data && data.distributor && data.distributor.name}</span>
            </div>
          );
        },
      },
      {
        label: t('likeType'),
        index: "favorite",
        option: {
          className: "text-center",
        },
        callback: null,
      },
      {
        label: t('historyStatus'),
        index: "status",
        option: {
          className: "text-center",
        },
        callback: null,
      },
    ];
    return (
      <>
        <div className="container-fluid container-shadow">
          <CustomerTag />
          <div className="row">
            <div className="col-md-4 mt-4">
              <InputSearch onChange={this.onChangeSearch} placeholder={t('search')} />
            </div>
          </div>
          <div className="row table-border">
            <div className="col-md-12 name-header-part">
              <Table
                fixed={true}
                tableHeaders={tableHeaderFavorite}
                tableData={data}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('customer')(withRouter(CustomerFavorite));
