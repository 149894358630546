import * as types from './../constants/actionType';

const initialState = {
    data: [],
    paginate: {},
    isLoading: true,
    message: null,
    isRequest: false,
    status: false,
    errors: {},
}

const Floor = (state = initialState, action) => {
    switch (action.type) {
        case types.FLOOR_REQUEST:
            return {
                ...state,
                isLoading: action.status
            }
        case types.RESET_FLOOR_BDS:
            return {
                ...state,
                isRequest: false,
                status: false,
                errors: {},
                block: null

            };
        case types.GET_FLOOR_LIST:
            return {
                ...state,
                data: [...action.data.data],
                paginate: { ...action.data.paginate },
                isLoading: false
            }
        default:
            return { ...state };
    }
}

export default Floor;