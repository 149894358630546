import React, { Component } from 'react';
import Form from './Form';
import { connect } from 'react-redux';
import { signInUser } from '../../actions/index';
import { destructServerErrors, destructServerMessage } from '../../helpers/error';
import { withRouter } from 'react-router-dom';
import bgDBS from './../../assets/images/bg_bds.jpg'
import logoBDS from './../../assets/images/logo-header-v2.png';

class ForgetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      errors: {},
      message: null
    }
  }

  submitForm = (data) => {
  }

  render() {
    let { errors, message, isLoading } = this.state;

    return (
      <section className="login-page">
        <div className="container-login100">
          <div className="wrap-login100">
            <div className="login-form">

              <div className="form-logo">
                <img src={logoBDS} className="logo" />
              </div>

              <Form submitForm={this.submitForm} errors={errors} isLoading={isLoading} message={message}/>

              <div className="form-footer">
                <div className="footer-icon">
                  <i className="fa fa-headset"></i>
                  <span>Hỗ trợ</span>
                </div>
                <ul className="footer-info">
                  <li>xaydung@demo.com</li>
                  <li>0909 090 090</li>
                </ul>
                <div className="footer-brand text-center">
                  <a href="#">www.xaydungdemo.com</a>
                </div>
              </div>
            </div>
            <div className="login100-banner" style={{ "backgroundImage": `url(${bgDBS})` }}></div>
          </div>

        </div>
      </section>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {

  }
}

const MapDispatchToProp = (dispatch, props) => {
  return {
  }
}

export default connect(mapStateToProps, MapDispatchToProp)(withRouter(ForgetPassword));
