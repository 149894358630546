import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { NavLink, withRouter } from "react-router-dom";

class CustomerTag extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
  }

  render() {
    const { t } = this.props;

    return (
      <div className="row d-flex distributor-nav distributor-nav-custom">
        <div className="col-6">
          <NavLink
            to={`edit`}
            activeClassName="active"
            className="distributor-nav-item distributor-nav-item-custom"
          >
            {t('generalInfomation')}
          </NavLink>
        </div>
        <div className="col-6">
          <NavLink
            to={`favorite`}
            activeClassName="active"
            className="distributor-nav-item distributor-nav-item-custom"
          >
            {t('accessHistory')}
          </NavLink>
        </div>
      </div>
    );
  }
}

export default withTranslation('customer')(withRouter(CustomerTag));
