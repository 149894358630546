import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import { Link, withRouter, Redirect } from "react-router-dom";
import { getDetailUserRequest, updateUserRequest } from "../../actions/user";
import { getFormDataFromRef } from "../../helpers/form";
import InputPhoneCustom from "../../component/common/InputPhoneCustom";
import Datepicker from "../../component/common/Datepicker";
import Select from "../../component/common/Select";
import { phoneFormatCustom, showMessage } from "./../../helpers/table";
import { getTakenData } from "../../services/base_services";
import { LIST_ROLES, LIST_POSITION } from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
class AccountEdit extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
    this.state = {
      errors: {},
      data: {},
      listRoles: [],
      isLoading: true,
      listPosition: [],
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      getDetailUserRequest(id)
        .then((res) => {
          this.setState({ data: res.data.data });
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }

    let listRoles = await getTakenData(LIST_ROLES);
    let listPosition = await getTakenData(LIST_POSITION);
    this.setState({
      listPosition: listPosition.data.data,
      listRoles: listRoles.data.data,
      isLoading: false,
    });
  };

  onUpdateUser = (e) => {
    let { id } = this.props.match.params;
    e.preventDefault();
    let data = getFormDataFromRef(this.refs);
    let val = data.phone;
    data.phone = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`;
        } else if (s) {
          return `${f}${s}`;
        } else if (f) {
          return `${f}`;
        }
      });
    if (id) {
      updateUserRequest(id, data)
        .then((res) => {
          showMessage(res.data.message, true);
          this.props.history.goBack();
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
  };

  render() {
    let { errors, data, listRoles, isLoading, listPosition } = this.state;
    // console.log(data);
    
    return isLoading ? (
      ""
    ) : (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>Chỉnh sửa người dùng</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.onUpdateUser} text="Lưu thông tin" />
              <Link to="/account">
                <ButtonSave text="Hủy" className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row top-table">
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Họ và tên"
                name="full_name"
                ref="full_name"
                label="Họ và tên:"
                errors={errors}
                type="text"
                defaultValue={data && data.fullname}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputPhoneCustom
                name="phone"
                ref="phone"
                label="Số điện thoại:"
                type="text"
                className="form-control input-form"
                placeholder="Số điện thoại"
                errors={errors}
                defaultValue={
                  data && data.phone && phoneFormatCustom(data && data.phone)
                }
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <Datepicker
                name="birthday"
                ref="birthday"
                label="Ngày sinh:"
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
                value={data && data.birthday}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Địa chỉ"
                name="address"
                ref="address"
                label="Địa chỉ:"
                errors={errors}
                type="text"
                defaultValue={data && data.address}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Email"
                name="email"
                ref="email"
                label="Email:"
                errors={errors}
                type="text"
                defaultValue={data && data.email}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <Select
                className="form-control input-form"
                name="role_id"
                ref="role_id"
                label="Quyền:"
                include_blank="Quyền"
                key_value="id"
                key_label="name"
                data={listRoles}
                errors={errors}
                defaultValue={data && data.role}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AccountEdit);
