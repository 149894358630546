import React, { Component } from 'react';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';

class TextArea extends Component {
  constructor(props, context) {
    super(props, context);
    props.onChange(props.default);
    this.state = {
      loading: false,
      data: props.default,
      error: {}
    }
  }

  onChangeHandle = (e, editor) => {
    const data = editor.getData();
    this.props.onChange(data)
  }

  render() {
    let { errors, label, name, defaultValue, className } = this.props;
    return (
      <div className={`${checkError(errors, name)} ${className}`}>
        { label && <label className="input-label">{label}</label> }
        <CKEditor
          editor={ ClassicEditor }
          onInit={ editor => {
          }}
          data={defaultValue ? defaultValue : ''}
          onChange={this.onChangeHandle}
        />
        <Alert messsage={getError(errors, name)} />
      </div>
    )
  }
}

export default TextArea;
