import React, { Component } from 'react';

class PerPage extends Component {
  chagePerPage = (e) => {
    let value = e.target.value;
    this.props.chagePerPage(value)
  }
  render() {
    let { per_page, tablePaginate } = this.props;

    if (tablePaginate.total == 0) {
      return '';
    }

    return (
      <div className="per-page">
        <select value={per_page} className="form-control select-perpage" onChange={(e) => this.chagePerPage(e)}>
          <option value="10">10 / Trang</option>
          <option value="20">20 / Trang</option>
          <option value="30">30 / Trang</option>
          <option value="40">40 / Trang</option>
        </select>
      </div>
    );
  }
}

export default PerPage;
