import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPermissionsRequest,
  createPermissionsRequest,
} from "../../actions/roles";
import ButtonSave from "../../component/common/ButtonSave";
import { Checkbox } from "../../component/common";
import { getFormDataFromRef } from "../../helpers/form";
import { showMessage } from "../../helpers/table";
import Select from "../../component/common/Select";
import { withTranslation } from "react-i18next";

class RolesNew extends Component {
  constructor(props) {
    super(props);
    this.permission = [];
    this.state = { errors: {} };
  }

  componentDidMount() {
    this.loadPermissions();
  }

  loadPermissions = () => {
    this.props.getPermissions();
  };

  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      permission_ids: this.permission,
    };
    createPermissionsRequest(formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ errors: err.err });
      });
  };

  handleInputChange = (e) => {
    const target = e.target;
    let value = parseInt(target.value);
    if (target.checked) {
      this.permission.push(value);
    } else {
      this.permission.splice(this.permission.indexOf(value), 1);
    }
  };

  checkAll = (e) => {
    const target = e.target;
    let checkboxes = document.getElementsByName("permission[]");
    if (target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        this.permission.push(parseInt(checkboxes[i].value));
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      this.permission = [];
    }
  };

  render() {
    let { t, permissions } = this.props;
    let { errors } = this.state;
    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('createRole')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/roles">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <InputText
                className="form-control input-form form-small"
                placeholder={t('groupName')}
                name="name"
                ref="name"
                label={`${t('groupName')}:`}
                errors={errors}
              />
              <label className="input-label mg-top-10">
                {t('detailRole')}:
              </label>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <Select
                className="form-control input-form form-small"
                name="permisson_temp"
                ref="permisson_temp"
                label={`${t('templateRole')}:`}
                include_blank={t('templateRole')}
                key_value="id"
                key_label="name"
                data={[]}
                errors={errors}
              />
            </div>
            <div className="col-md-12 d-flex flex-wrap">
              <Checkbox
                errors={[]}
                label={t('all')}
                name="name[]"
                id="all"
                multiple={true}
                defaultChecked={false}
                onChange={this.checkAll}
              />
            </div>

            {permissions?.map((permission) => (
              <div className="col-md-3 d-flex flex-wrap flex-col mg-top-10">
                <h6 className="mg-20">{`${permission.name}:`}</h6>
                <div className="flex-col">
                  {permission.permissions.map((feature) => (
                    <Checkbox
                      errors={[]}
                      id={`project_permission_id_${feature.id}`}
                      refs={`project_permission_id_${feature.id}`}
                      label={feature.name}
                      value={feature.id}
                      onChange={this.handleInputChange}
                      name="permission[]"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    permissions: state.Roles.permissions,
    isLoading: state.Roles.isLoading,
    ...state.Roles,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getPermissions: () => dispatch(getPermissionsRequest()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('role')(withRouter(RolesNew)));
