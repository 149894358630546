import * as types from './../constants/actionType';

const initialState = {
  authenticated: false,
  user: null,
  errors: {},
  isRequest: false,
  isLoading: true,
  data: [],
  paginate: {},
  status: false,
  userDetail:null
}

const user = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_REQUEST:
      return {
        ...state,
        isLoading: action.status
      }
    case types.RESET_USER:
      return {
        ...state,
        isRequest: false,
        status: false,
        errors: {},
        userDetail:null
      };
    case types.GET_USER_LIST:
      return {
        ...state,
        data: [...action.data.data],
        paginate: { ...action.data.paginate },
        isLoading: false
      };
    case types.CREATE_USER:
      if (action.status) {
        return {
          ...state,
          status: true,
          message: 'Create user successfully.',
          errors: {},
        };
      } else {
        return {
          ...state,
          status: false,
          message: 'Please check errors below.',
          errors: action.data,
        };
      }
    case types.GET_USER:
      if (action.status) {
        return {
          ...state,
          message: null,
          userDetail: action.data.data,
        };
      } else {
        return {
          ...state,
          status: false,
          message: action.data,
        };
      }
    case types.SET_USER_DATA:
      return {
        ...state,
        user: action.user
      };
    case types.SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated
      };
    case types.FORM_USER_REQUEST:
      return {
        ...state,
        isRequest: action.status
      }

    case types.EDIT_USER:
      if (action.status) {
        return {
          ...state,
          status: true,
          message: 'Update user successfully.',
          errors: {},
        };
      } else {
        return {
          ...state,
          status: false,
          message: 'Please check errors below.',
          errors: action.data,
        };
      }
      case types.CHANGE_PASSWORD:
        if (action.status) {
          return {
            ...state,
            status: true,
            message: 'Update password successfully.',
            errors: {},
          };
        } else {
          return {
            ...state,
            status: false,
            message: 'Please check errors below.',
            errors: action.data,
          };
        }
    default:
      return state
  }
}

export default user