import React, { Component } from 'react'
import InputText from '../../component/common/InputText'
import { Link, withRouter } from 'react-router-dom';
import { getDetailSalesPolicyRequest, updateSalesPolicyRequest } from '../../actions/salespolicy'
import { getFormDataFromRef } from '../../helpers/form'
import Select from '../../component/common/Select'
import { showMessage } from "./../../helpers/table";
import { getTakenData } from '../../services/base_services';
import { LIST_DISTRIBUTOR } from '../../constants/Config';
import Datepicker from '../../component/common/Datepicker';
import TextArea from '../../component/common/TextArea';
import Document from '../../component/common/Document';
import { connect } from 'react-redux';
import ButtonSave from "../../component/common/ButtonSave";
import { withTranslation } from 'react-i18next';

class SalesPolicyEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDistributor: [],
      errors: {},
      data: {},
      isLoading:true
    }
  }

  componentDidMount = async () => {
    let listDistributor = await getTakenData(LIST_DISTRIBUTOR);
    this.setState({
      listDistributor: listDistributor.data.data,
    });
    let { id } = this.props.match.params;
    if (id) {
      getDetailSalesPolicyRequest(id).then(res => {
        this.setState({ data: res.data.data, isLoading:false, });
      }).catch(err => {
        this.setState({ errors: err.err });
      })
    }
  }

  onEditSalesPolicy = (e) => {
    let { id } = this.props.match.params;
    e.preventDefault();
    let data = getFormDataFromRef(this.refs);
    data = { ...data, description: this.description, file: this.file, public_flg: this.state?.data?.public_flg };
    
    if (id) {
      updateSalesPolicyRequest(id, data).then(res => {
        showMessage(res.data.message, true);
        this.props.history.goBack()
      }).catch(err => {
        this.setState({ errors: err.err });
      });
    }
  }
  
  onChangeTextArea = (data) => {
    this.description = data;
  }

  onChangeDocument = (data) => {
    this.file = data;
  }

  render() {
    const { t } = this.props;
    let { errors, listDistributor, data, isLoading } = this.state
    
    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('editSalePolicy')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.onEditSalesPolicy} text={t('save')} />
              <Link to="/sales_policy">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row top-table">
            <div className="col-md-12 flex-wrap">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <InputText
                      className="form-control input-form"
                      placeholder={t('fileName')}
                      name="name"
                      ref="name"
                      label={`${t('fileName')}:`}
                      errors={errors}
                      type="text"
                      defaultValue={data && data.name}
                    />
                  </div>
                  <div className="col-md-8">
                    {
                      isLoading ? "" :
                      <Document
                        label={`${t('projectDocument')} (*):`}
                        name="file"
                        title="Project Document"
                        className="form-group form-group-custom-file"
                        onChange={this.onChangeDocument}
                        errors={errors}
                        defaultValue={data && data.file}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <Select
                      className="form-control input-form"
                      name="distributor_id"
                      ref="distributor_id"
                      label={`${t('distributor')}:`}
                      include_blank={t('distributor')}
                      key_value="id"
                      key_label="name"
                      data={listDistributor}
                      errors={errors}
                      defaultValue={data && data.distributor}
                    />
                  </div>
                  <div className="col-md-4">
                    <Datepicker
                      name="date_apply"
                      ref="date_apply"
                      label={`${t('start')}:`}
                      defaultValue={null}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={"dd-MM-yyyy"}
                      value={data && data.date_apply}
                    />
                  </div>
                  <div className="col-md-4">
                    <Datepicker
                      name="expriration_date"
                      ref="expriration_date"
                      label={`${t('end')}:`}
                      defaultValue={null}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={"dd-MM-yyyy"}
                      value={data && data.expriration_date}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <TextArea 
                  name="description"
                  label={`${t('description')}:`}
                  className="text-area-custom"
                  errors={errors}
                  onChange={this.onChangeTextArea}
                  defaultValue={data && data.description}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation('salesPolicy')(withRouter(SalesPolicyEdit));