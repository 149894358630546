import * as types from "./../constants/actionType";

const initialState = {
  data: [],
  paginate: {},
  isLoading: true,
  message: null,
  isRequest: false,
  status: false,
  errors: {},
  distributor: null,
};

const Distributor = (state = initialState, action) => {
  switch (action.type) {
    case types.DISTRIBUTOR_REQUEST:
      return {
        ...state,
        isLoading: action.status,
      };
    case types.RESET_DISTRIBUTOR:
      return {
        ...state,
        isRequest: false,
        status: false,
        errors: {},
        distributor: null,
      };
    case types.GET_DISTRIBUTOR_LIST:
      return {
        ...state,
        data: [...action.data.data],
        paginate: { ...action.data.paginate },
        isLoading: false,
      };
    case types.CREATE_DISTRIBUTOR:
      if (action.status) {
        return {
          ...state,
          status: true,
          message: "Create distributor successfully.",
          errors: {},
        };
      } else {
        return {
          ...state,
          status: false,
          message: "Please check errors below.",
          errors: action.data,
        };
      }
    case types.GET_DISTRIBUTOR:
      if (action.status) {
        return {
          ...state,
          message: null,
          distributor: action.data,
        };
      } else {
        return {
          ...state,
          status: false,
          message: action.data,
        };
      }
    case types.EDIT_DISTRIBUTOR:
      if (action.status) {
        return {
          ...state,
          status: true,
          message: "Update distributor successfully.",
          errors: {},
        };
      } else {
        return {
          ...state,
          status: false,
          message: "Please check errors below.",
          errors: action.data,
        };
      }
    default:
      return { ...state };
  }
};

export default Distributor;
