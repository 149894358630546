import React, { Component } from "react";
import GroupImageDetailProduct from "../../component/common/GroupImageDetailProduct";
import { getDataByID, putData, deleteById } from "../../services/base_services";
import {
  GET_PRODUCT_INFO,
  GET_LIST_FILE_PRODUCT_TEMPLATE,
  BASE_IMG,
  CREATE_FILE_PRODUCT,
  GET_LIST_FILE_PRODUCT_SELECT,
  DELETE_LIST_FILE_PRODUCT,
  GET_LIST_FILE_TEMPLATE_PRODUCT_SELECT
} from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  destructServerMessage,
  destructServerErrors,
} from "../../helpers/error";
import ProductTemplateManager from "./ProductTemplateManager";
import addIcon from "../../assets/images/icons/more.png";
import uploadIcon from "../../assets/images/icons/upload-img.png";
import NewCategoryTemplate from "./NewCategoryTemplate";
import { uploadDocument } from "../../helpers/form";
import { Menu } from "antd";
import { showMessage, confirmDelete, afterRemove } from "./../../helpers/table";
import CheckboxRow from "../../component/common/CheckboxRow";
import InputText from "../../component/common/InputText";
import Select from "../../component/common/Select";
import ButtonSmall from "../../component/common/ButtonSmall";
import openMenu from "./../../assets/images/icons/open_menu.svg";
import closeMenu from "./../../assets/images/icons/close_menu.svg";
import { getFormDataFromRef } from "../../helpers/form";
import { withTranslation } from "react-i18next";

const { SubMenu } = Menu;

class ProductTemplateEditFile extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRefSecond = React.createRef();
    this.id = this.props.match.params.id;
    this.state = {
      isLoading: true,
      status: false,
      errors: [],
      openModelNew: false,
      openKeys: [],
      dataListFIle: [],
      idCategory: null,
      path: [],
      name: "",
      parent: null,
      name_parent: "",
      rootSubmenuKeys: [],
      compare_flg: false,
      check_360_flg: false,
      isShowUpdate: false,
      fileListSelect: [],
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      this.loadFileSelectList(id);
      this.getListCategory(id);
    }
  };

  loadFileSelectList = async (id) => {
    await getDataByID(GET_LIST_FILE_TEMPLATE_PRODUCT_SELECT, id).then((res) => {
      this.setState({
        fileListSelect: res.data.data,
      });
    });
  };

  onChangeDocument = (data) => {
    this.setState({
      path: data,
    });
  };

  getListCategory = async (id) => {
    await getDataByID(GET_LIST_FILE_PRODUCT_TEMPLATE, id)
      .then((res) => {
        const arrTmp = res?.data?.data?.map((item) => {
          return item.id.toString();
        });
        this.setState({
          dataListFIle: res.data.data,
          isLoading: false,
          rootSubmenuKeys: arrTmp,
        });
        return Promise.resolve({ res });
      })
      .catch((error) => {
        let errs = destructServerMessage(error);
        return Promise.reject(error);
      });
  };

  addNew = (e) => {
    e.preventDefault();
    const { id } = this.props.match.params;
    const { path, name, parent } = this.state;
    let formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      path: path,
      name: formData.name ? formData.name : name,
      parent: formData.parent ? formData.parent : parent,
      template_product_id: this.id,
      compare_flg: this.myRef.current.checked ? 1 : 2,
      check_360_flg: this.myRefSecond.current.checked ? 1 : 0,
    };
    putData(CREATE_FILE_PRODUCT, this.state.idCategory, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          compare_flg: this.myRef.current.checked,
          check_360_flg: this.myRefSecond.current.checked,
          name: formData.name ? formData.name : name,
        });
        this.getListCategory(id);
        this.loadFileSelectList(id);
      })
      .catch((err) => {
        if (this.state.idCategory) {
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        } else {
          showMessage("Vui lòng chọn một danh mục cần sửa!", false);
        }
      });
  };

  openModelAdd = (id) => {
    this.setState({
      openModelNew: true,
      idCategory: id,
    });
  };

  newFolder = (status) => {
    this.setState({
      openModelNew: status,
    });
  };

  onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({
        openKeys: keys,
      });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  itemMenu = (listChild, id) => {
    var result = null;
    result = listChild?.map((item, index) => {
      return (
        <SubMenu
          key={item.id}
          onTitleClick={(e) =>
            this.getItem(
              e,
              item.id,
              item.path,
              item.name,
              item.parent,
              item.name_parent,
              item.compare_flg,
              item.check_360_flg
            )
          }
          title={item.name}
        >
          {this.itemMenu(item.children, item.id)}
          <Menu.Item>
            <span
              onClick={() => this.openModelAdd(item.id)}
              className="btn-add-category"
            >
              <img src={addIcon} alt="" />
              New category
            </span>
          </Menu.Item>
        </SubMenu>
      );
    });
    return result;
  };

  getAgain = () => {
    let { id } = this.props.match.params;
    this.getListCategory(id);
    this.loadFileSelectList(id);
  };

  getItem = (e, id, path, name, parent, name_parent, compare_flg, check_360_flg) => {
    let idCate = this.props.match.params.id;
    let arrTmp = [];
    if (idCate) {
      getDataByID(GET_LIST_FILE_TEMPLATE_PRODUCT_SELECT, idCate).then((res) => {
        this.setState({
          fileListSelect: res.data.data?.filter((item) => {return id != item.id}),
        });
      });
    }
    this.setState({
      idCategory: id,
      path,
      name,
      name_parent,
      parent,
      compare_flg: compare_flg === 1 ? true : false,
      check_360_flg: check_360_flg === 1 ? true : false,
      isShowUpdate: false,
      fileListSelect: arrTmp
    });
  };

  clickUpload = () => {
    document.querySelector("#upload-input").click();
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      uploadDocument(file)
        .then((res) => {
          let data = `${BASE_IMG}${res.data.file}`;
          let image = [data, ...this.state.path];
          this.setState({ path: image, errors: {} });
          this.props.onChange(image);
        })
        .catch((err) => {
          this.setState({ errors: err.error_upload });
        });
    }
  };

  showFormEdit = () => {
    this.setState({
      isShowUpdate: !this.state.isShowUpdate,
    });
  };

  onDelete = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeFileRequest(id);
      }
    });
  };

  removeFileRequest = (id) => {
    let idCate = this.props.match.params.id;
    deleteById(DELETE_LIST_FILE_PRODUCT, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.getListCategory(idCate);
        this.loadFileSelectList(idCate);
        this.setState({
          name: "",
          isShowUpdate: false,
          path: [],
        });
      })
      .catch((err) => {
        afterRemove("Có lỗi xảy ra.", false);
      });
  };

  render() {
    const {
      errors,
      status,
      isLoading,
      openModelNew,
      dataListFIle,
      idCategory,
      path,
      compare_flg,
      check_360_flg,
      name,
      parent,
      isShowUpdate,
      fileListSelect,
      name_parent,
    } = this.state;
    const { t } = this.props;
    
    let checkboxesAll = document.getElementsByName("compare_flg");
    if (checkboxesAll.length > 0) {
      if (compare_flg) {
        checkboxesAll[0].checked = true;
      } else {
        checkboxesAll[0].checked = false;
      }
    }

    let check_360_flgAll = document.getElementsByName("check_360_flg");
    if (check_360_flgAll.length > 0) {
      if (check_360_flg) {
        check_360_flgAll[0].checked = true;
      } else {
        check_360_flgAll[0].checked = false;
      }
    }

    if (status) {
      return (
        <Redirect
          to={{
            pathname: "/product",
          }}
        />
      );
    }

    return isLoading ? (
      ""
    ) : (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('productTemplateEdit')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/product/template">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          {openModelNew && (
            <NewCategoryTemplate
              idProduct={this.id}
              idCategory={idCategory}
              className="modal-small"
              newFolder={this.newFolder}
              open={openModelNew}
              getAgain={this.getAgain}
            />
          )}

          <ProductTemplateManager idProduct={this.id} />
          <div className="row detail-product-picture">
            <div className="col-4">
              <div className="block-left">
                <p className="title-menu">{t('productImageCategory')}</p>
                <Menu
                  mode="inline"
                  openKeys={this.state.openKeys}
                  onOpenChange={this.onOpenChange}
                >
                  {dataListFIle?.map((item, index) => {
                    return (
                      <SubMenu
                        key={item.id}
                        onTitleClick={(e) =>
                          this.getItem(
                            e,
                            item.id,
                            item.path,
                            item.name,
                            item.parent,
                            item.name_parent,
                            item.compare_flg,
                            item.check_360_flg
                          )
                        }
                        title={`${item.name}`}
                      >
                        {this.itemMenu(item.children, item.id)}
                        <Menu.Item>
                          <span
                            onClick={() => this.openModelAdd(item.id)}
                            className="btn-add-category"
                          >
                            <img src={addIcon} alt="" />
                            New category
                          </span>
                        </Menu.Item>
                      </SubMenu>
                    );
                  })}
                  <Menu.Item>
                    <span
                      onClick={() => this.openModelAdd(null)}
                      className="btn-add-category"
                    >
                      <img src={addIcon} alt="" />
                      New category
                    </span>
                  </Menu.Item>
                </Menu>
              </div>
            </div>
            <div className="col-8">
              <div className="block-right">
                <p className="title-menu" onClick={this.showFormEdit}>
                  {name && (
                    <>
                      {name}
                      {isShowUpdate ? (
                        <img className="ml-2" src={closeMenu} alt="" />
                      ) : (
                        <img className="ml-2" src={openMenu} alt="" />
                      )}
                    </>
                  )}
                </p>
                {isShowUpdate && (
                  <div className="block-update row mr-0 ml-0">
                    <div className="col-6">
                      <InputText
                        className="form-control input-form"
                        placeholder={t('imageCategoryName')}
                        name="name"
                        ref="name"
                        label={`${t('imageCategoryName')}:`}
                        errors={errors}
                        type="text"
                        defaultValue={name}
                      />
                    </div>
                    <div className="col-6">
                      <Select
                        label={`${t('imageParentCategory')}:`}
                        className="form-control input-form form-control-product mb-3 input-grey"
                        name="parent"
                        ref="parent"
                        errors={errors}
                        key_value="id"
                        key_label="name"
                        include_blank={t('imageParentCategory')}
                        data={fileListSelect}
                        defaultValue={
                          parent && name_parent
                            ? { id: parent, name: name_parent }
                            : null
                        }
                      />
                    </div>
                    <div className="col-12">
                      <ButtonSmall
                        onClick={() => this.onDelete(idCategory)}
                        label={t('delete')}
                      />
                    </div>
                  </div>
                )}
                <div className="mb-3 block-checkbox-custom">
                  <CheckboxRow
                    errors={[]}
                    id="compare_flg"
                    ref={this.myRef}
                    label={`${t('compareImage')}:`}
                    value="compare_flg"
                    name="compare_flg"
                    defaultChecked={compare_flg || false}
                  />
                </div>
                <div className="mb-3 block-checkbox-custom">
                  <CheckboxRow
                    errors={[]}
                    id="check_360_flg"
                    ref={this.myRefSecond}
                    label={`${t('image360')}:`}
                    value="check_360_flg"
                    name="check_360_flg"
                    defaultChecked={check_360_flg || false}
                  />
                </div>
                <div className="block-upload">
                  <img
                    className="img-icon-up"
                    onClick={this.clickUpload}
                    src={uploadIcon}
                    alt=""
                  />
                  <span className="description">Upload image (Under 4 MB)</span>
                  <input
                    type="file"
                    className="upload-input"
                    id="upload-input"
                    onChange={(e) => this.onChangeImage(e)}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                </div>
                <GroupImageDetailProduct
                  label={t('interiorImages')}
                  name="layout_images"
                  title={t('selectForDownload')}
                  errors={errors}
                  onChange={this.onChangeDocument}
                  order={2}
                  listImg={path}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('product')(withRouter(ProductTemplateEditFile));
