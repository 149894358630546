import React, { Component } from 'react';
import Loading from '../../component/common/LoadingTable';
import { NoResult } from '../../assets/images/icons';
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import EditFolder from './EditFolder';
import { deleteMediafolderRequest } from '../../actions/media';
import { confirmDelete, showMessage } from './../../helpers/table';
import { MEDIA_FOLDER } from '../../constants/Variable'
import { withTranslation } from 'react-i18next';

function collect(props) {
  return { name: props.name };
}

class RawTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editable: null,
      openEdit: false
    }
  }


  initTableRow = (data, index) => {
    let { role_member } = this.props;
    let idx = index;

    const onDoubleClickRow = id => {
      this.props.onClickRow(id);
    }

    return (
      <ContextMenuTrigger renderTag='tr' name={data.name} id={`row_${data.id}`} holdToDisplay={1000} key={index} collect={collect}>
        {this.props.tableHeaders.map(function (header, index) {
          let value = data;
          if (header.index) {
            value = data[header.index];
          }

          if (header.callback) {
            value = header.callback(value, role_member);
          }

          if (header.index && header.index == 'stt') {
            value = idx + 1;
          }

          if (header.show == false) {
            return;
          }

          return <td className="clickable text-center" key={index} {...header.option} onDoubleClick={() => onDoubleClickRow(data)} >{value}</td>
        })}
      </ContextMenuTrigger>
    );
  }

  // Rename folder function
  openEditName = (e, data) => {
    let editable = { id: data.id,name: data.name, };
    this.setState({ openEdit: true, editable });
  };

  completeRename = () => {
    this.setState({ openEdit: false, editable: null });
    this.props.onCompleteEditFolder();
  }

  closeRename = () => {
    this.setState({ openEdit: false, editable: null });
  }

  // Delete folder
  onDeleteFolder = (e, data) => {
    if(data && data.id){
      this.onDeleteFolderRequest(data.id);
    }
  }

  handleShare = (e, data) => {
    if(data && data.id){
      this.props.onOpenShare(data.id);
    }
  }

  handleClick = (item) => {
    this.props.onClickRow(item);
  }

  onDeleteFolderRequest = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeFolderRequest(id);
      }
    })
  }

  removeFolderRequest = (id) => {
    deleteMediafolderRequest(id).then(res => {
      showMessage(res.data.message, true);
      this.props.onCompleteEditFolder();
    }).catch(err => {
      showMessage(err.msg, false);
    })
  }


  render() {
    let { tableHeaders, tableData, isLoading, fixed, t } = this.props;
    let { editable, openEdit } = this.state;

    return (
      <div className={`scrollable table-wrapper ${fixed && 'table-fixed'}`}>
        <EditFolder
          className='modal-small'
          open={openEdit}
          data={editable}
          completeRename={this.completeRename}
          closeRename={this.closeRename}
        />
        <table className="table table-custom auto raw-table">
          <thead className="table-thead text-center">
            <tr>
              {
                tableHeaders.map(function (header, index) {
                  if (header.show == false) {
                    return;
                  }
                  return <th key={index} className={`table-header ${header.className || ''}`}>{header.label}</th>;
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              isLoading && <tr>
                <td className="no-result" colSpan={tableHeaders.length}>
                  <Loading count="10" />
                </td>
              </tr>
            }

            {
              tableData.length == 0 && !isLoading && <tr className="tr-noresult">
                <td colSpan={tableHeaders.length}>
                  <div className="no-results">
                    <img src={NoResult} alt="không tìm thấy kết quả." />
                    <h5 dangerouslySetInnerHTML={{__html: t('notRecord', "")}}></h5>
                  </div>
                </td>
              </tr>
            }

            {
              tableData.length > 0 && !isLoading && tableData.map(this.initTableRow)
            }
          </tbody>
        </table>

        {
          !isLoading && this.props.tableData.map((item, index) => {
            return(
              <ContextMenu id={`row_${item.id}`} key={index}>
                {item.model_type == MEDIA_FOLDER && <MenuItem onClick={this.openEditName} data={{ id: item.id }}><span className="lnr lnr-pencil mr-1"></span> {t('editName')}</MenuItem>}
                <MenuItem onClick={() => this.handleClick(item)} data={{ id: item.id }}><span className="lnr lnr-question-circle mr-1"></span> {t('detail')}</MenuItem>
                <MenuItem onClick={this.handleShare} data={{ id: item.id }}><span className="lnr lnr-tag mr-1"></span> {t('shareBtn')}</MenuItem>
                <hr />
                <MenuItem className='text-danger' onClick={this.onDeleteFolder} data={{ id: item.id }}><span className="lnr lnr-trash mr-1"></span> {t('deleteBtn')}</MenuItem>
              </ContextMenu>
            );
          })
        }

      </div>
    );
  }
}

export default withTranslation('document')(RawTable);
