import {
  postData,
  getDataByID,
  postDataWithParams,
  putDataWithUrl
} from "./../services/base_services";
import {
  GET_LIST_CART_URL,
  DETAIL_CART_URL,
  CHANGE_STATUS_CART_URL,
  UPDATE_LIST_DISTRIBUTION
} from "../constants/Config";
import { destructServerMessage, destructServerErrors } from "../helpers/error";
import { showMessage } from "../helpers/table";

// GET LIST CART
export const getListCart = (search, params) => {
  return postDataWithParams(GET_LIST_CART_URL, search, params)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.resolve({ err: msg });
    });
};

// GET DETAIL CART
export const getDetailCart = (id) => {
  return getDataByID(DETAIL_CART_URL, id).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}

// CHANGE STATUS CART
export const changeStatusCartRequest = (data) => {
  return postData(CHANGE_STATUS_CART_URL, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    if(err.response.status == 400) {
      showMessage(err.response.data.message, false);
    }
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}

// GET LIST CART
export const getListCartDVPP = (search, params) => {
  return postDataWithParams(GET_LIST_CART_URL, search, params)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.resolve({ err: msg });
    });
};

// UPDATE
export const updateRequest = (data) => {
  return putDataWithUrl(UPDATE_LIST_DISTRIBUTION, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}