import React from 'react';
import { useTranslation } from 'react-i18next';
import iconCart from '../../assets/images/icons/icon-cart.png';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const BlockCart = (props) => {
  const { CartStatistic } = props;

  const { t } = useTranslation('dashboard');

  return (
    <div className='block-cart'>
      <div className='row mr-0 ml-0'>
        <div className='col-3'>
          <div className='item-cart'>
            <div className='block-left'>
              <img src={iconCart} alt='' />
              <div className='content'>
                <p className='title'>{t('totalCount')}:</p>
                <p className='detail'>
                  {CartStatistic?.[CartStatistic?.length - 1] || 0}
                </p>
              </div>
            </div>
            <div className='block-right'>
              <CircularProgressbar
                value={100}
                text={`${100}%`}
                styles={buildStyles({
                  pathColor: '#00B382',
                  textColor: '#00B382',
                  trailColor: '#F4F4F4'
                })}
              />
            </div>
          </div>
        </div>
        <div className='col-3 '>
          <div className='item-cart'>
            <div className='block-left'>
              <img src={iconCart} alt='' />
              <div className='content'>
                <p className='title'>{t('holdCount')}:</p>
                <p className='detail'>{CartStatistic?.[2] || 0}</p>
              </div>
            </div>
            <div className='block-right'>
              <CircularProgressbar
                value={
                  Math.ceil(
                    (CartStatistic?.[2] /
                      CartStatistic?.[CartStatistic?.length - 1]) *
                      100
                  ) || 0
                }
                text={`${
                  Math.ceil(
                    (CartStatistic?.[2] /
                      CartStatistic?.[CartStatistic?.length - 1]) *
                      100
                  ) || 0
                }%`}
                styles={buildStyles({
                  pathColor: '#F53C56',
                  textColor: '#F53C56',
                  trailColor: '#F4F4F4'
                })}
              />
            </div>
          </div>
        </div>
        <div className='col-3 '>
          <div className='item-cart'>
            <div className='block-left'>
              <img src={iconCart} alt='' />
              <div className='content'>
                <p className='title'>{t('depositCount')}:</p>
                <p className='detail'>{CartStatistic?.[3] || 0}</p>
              </div>
            </div>
            <div className='block-right'>
              <CircularProgressbar
                value={
                  Math.ceil(
                    (CartStatistic?.[3] /
                      CartStatistic?.[CartStatistic?.length - 1]) *
                      100
                  ) || 0
                }
                text={`${
                  Math.ceil(
                    (CartStatistic?.[3] /
                      CartStatistic?.[CartStatistic?.length - 1]) *
                      100
                  ) || 0
                }%`}
                styles={buildStyles({
                  pathColor: '#00B382',
                  textColor: '#00B382',
                  trailColor: '#F4F4F4'
                })}
              />
            </div>
          </div>
        </div>
        <div className='col-3 '>
          <div className='item-cart'>
            <div className='block-left'>
              <img src={iconCart} alt='' />
              <div className='content'>
                <p className='title'>{t('paidCount')}:</p>
                <p className='detail'>{CartStatistic?.[4] || 0}</p>
              </div>
            </div>
            <div className='block-right'>
              <CircularProgressbar
                value={
                  Math.ceil(
                    (CartStatistic?.[4] /
                      CartStatistic?.[CartStatistic?.length - 1]) *
                      100
                  ) || 0
                }
                text={`${
                  Math.ceil(
                    (CartStatistic?.[4] /
                      CartStatistic?.[CartStatistic?.length - 1]) *
                      100
                  ) || 0
                }%`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockCart;
