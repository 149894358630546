import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import Login from './../container/Login';
import HomeRoutes from './home.route';
import GuestRoute from './GuestRoute';
import AuthRoute from './AuthRoute';
import { connect } from 'react-redux';
import ForgetPassword from './../container/ForgetPassword';
import { initUserFromToken } from './../actions';
import Loading from '../assets/images/loading.gif';

export const routes = [
  {
    path: '/*',
    component: (props) => <HomeRoutes {...props} />,
    exact: true,
  },
  {
    path: '/login',
    component: () => <Login />,
    exact: false,
    layout: 'login'
  }
];

class Routes extends Component {

  componentDidMount = () => {
    this.props.getUser();
  }

  render() {
    const { isLoading } = this.props;
    if(isLoading) return <div className="main-loading"><img src={Loading}/></div>;

    return (
      <React.Fragment>
        <Switch>
          <GuestRoute path="/login" component={Login} />
          <GuestRoute path="/forgot" component={ForgetPassword} />
          {
            routes.map(route => (
              <AuthRoute key={route.path} {...route} />
            ))
          }
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.User,
    isLoading: state.Loading,
  };
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUser: () => dispatch(initUserFromToken())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Routes)
