import React, { Component } from 'react'
import InputText from '../../component/common/InputText'
import InputPhone from '../../component/common/InputPhone'
import Datepicker from '../../component/common/Datepicker'
import { withRouter, StaticRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FormChangePass from "./FormChangePass";
import { getFormDataFromRef } from "./../../helpers/form";
import {
  updateUserProfileRequest
} from "./../../actions/index";
import { phoneFormatCustom } from "./../../helpers/table";
import InputPhoneCustom from "../../component/common/InputPhoneCustom"
import UploaderAvatar from '../../component/common/UploaderAvatar';
import { withTranslation } from 'react-i18next';

class Profile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isShowFormChangePass: false,
    };
  }

  showFormChangePass = () => {
    this.setState({
      isShowFormChangePass: true
    });
  }

  onClosePopup = () => {
    this.setState({
      isShowFormChangePass: false
    });
  };

  onSubmitForm = e => {
    e.preventDefault();
    let data = getFormDataFromRef(this.refs);
    data.avatar = this.avatar.value;
    let val = data.phone;
    data.phone = val
      .replace(/\D/g, '')
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`
        } else if (s) {
          return `${f}${s}`
        } else if (f) {
          return `${f}`
        }
      });
    this.props.updateUserProfile(data);
  };

  render() {
    let { isShowFormChangePass } = this.state
    let { t, user: { user, errors } } = this.props

    return (
      <>
        <div className="container-fluid container-shadow">
          <form onSubmit={this.onSubmitForm}>
            <div className="row top-table">
              <div className="col-md-6 top-table-title">
                <p>
                  {t('profile')}
              </p>
              </div>
              <div className="col-md-6">
                <div className="button-new float-right">
                  <span onClick={this.showFormChangePass}>
                    {t('changePass')}
                  </span>
                </div>

                <div className="button-new float-right">
                  <button className="btn-submit-profile">
                    <span>
                      {t('save')}
                    </span>
                  </button>
                </div>

              </div>
              <div className="col-md-12 flex-wrap">
                <div className="col-md-12">
                  <UploaderAvatar
                    ref={(c) => {
                      this.avatar = c;
                    }}
                    name="avatar"
                    errors={errors}
                    label={t('avatar')}
                    defaultValue={user && user.avatar}
                  />
                </div>
              </div>
              <div className="col-md-6 flex-wrap">
                <div className="col-md-12">
                  <InputText
                    className="form-control input-form"
                    placeholder={t('fullname')}
                    name="name"
                    ref="name"
                    label={`${t('fullname')}(*)`}
                    errors={errors}
                    type="text"
                    defaultValue={user && user.name}
                  />
                </div>
                <div className="col-md-12">
                  <InputPhoneCustom
                    name="phone"
                    ref="phone"
                    label={`${t('phone')}(*)`}
                    type="text"
                    className="form-control input-form"
                    placeholder={t('phone')}
                    errors={errors}
                    defaultValue={user && user.phone && phoneFormatCustom(user && user.phone)}
                  />
                </div>
                <div className="col-md-12">
                  <Datepicker
                    name="birthday"
                    ref="birthday"
                    label={t('birthday')}
                    errors={errors}
                    clearIcon={false}
                    className="form-control input-form"
                    format={"dd-MM-yyyy"}
                    defaultValue={user && user.birthday}
                  />
                </div>
              </div>
              <div className="col-md-6 flex-wrap">
                <div className="col-md-12">
                  <InputText
                    className="form-control input-form"
                    placeholder={t('address')}
                    name="address"
                    ref="address"
                    label={t('address')}
                    errors={errors}
                    type="text"
                    defaultValue={user && user.address}
                  />
                </div>
                <div className="col-md-12">
                  <InputText
                    className="form-control input-form"
                    placeholder="Email"
                    name="email"
                    ref="email"
                    label="Email(*)"
                    errors={errors}
                    type="text"
                    defaultValue={user && user.email}
                  />
                </div>
              </div>
            </div>
          </form>
          <div>
            {
              isShowFormChangePass &&
              (
                <FormChangePass onClosePopup={this.onClosePopup} />
              )
            }
          </div>
        </div>
      </>
    )
  }
}



const mapStateToProps = (state, props) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return {
    updateUserProfile: (data) => {
      dispatch(updateUserProfileRequest(data));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('profile')(withRouter(Profile)));
