import React, { Component } from 'react'
import InputText from '../../component/common/InputText'
import { Link, withRouter } from 'react-router-dom';
import { createSalesPolicyRequest } from '../../actions/salespolicy'
import { getFormDataFromRef } from '../../helpers/form'
import Select from '../../component/common/Select';
import { getTakenData } from '../../services/base_services';
import { LIST_DISTRIBUTOR } from '../../constants/Config'
import { showMessage } from '../../helpers/table';
import Datepicker from '../../component/common/Datepicker';
import TextArea from '../../component/common/TextArea';
import Document from '../../component/common/Document';
import ButtonSave from "../../component/common/ButtonSave";
import { withTranslation } from 'react-i18next';

class SalesPolicyNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDistributor: [],
      errors: {}
    }
  }

  onCreateSalesPolicy = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData = { ...formData, description: this.description, file: this.file, public_flg: 1 };
    createSalesPolicyRequest(formData).then(res => {
      showMessage(res.data.message, true);
      this.props.history.goBack()
    }).catch(err => {
      this.setState({ errors: err.err });
    })
  }

  componentDidMount = async () => {
    let listDistributor = await getTakenData(LIST_DISTRIBUTOR);
    this.setState({
      listDistributor: listDistributor.data.data,
    });
  }

  onChangeTextArea = (data) => {
    this.description = data;
  }

  onChangeDocument = (data) => {
    this.file = data;
  }

  render() {
    let { errors, listDistributor } = this.state;
    const { t } = this.props;

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('createSalePolicy')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.onCreateSalesPolicy} text={t('save')} />
              <Link to="/sales_policy">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row top-table">
            <div className="col-md-12 flex-wrap">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <InputText
                      className="form-control input-form"
                      placeholder={t('fileName')}
                      name="name"
                      ref="name"
                      label={`${t('fileName')}:`}
                      errors={errors}
                      type="text"
                    />
                  </div>
                  <div className="col-md-8">
                    <Document
                      label={`${t('projectDocument')} (*):`}
                      name="file"
                      title="Project Document"
                      className="form-group form-group-custom-file"
                      onChange={this.onChangeDocument}
                      errors={errors}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4">
                    <Select
                      className="form-control input-form"
                      name="distributor_id"
                      ref="distributor_id"
                      label={`${t('distributor')}:`}
                      include_blank={t('distributor')}
                      key_value="id"
                      key_label="name"
                      data={listDistributor}
                      errors={errors}
                    />
                  </div>
                  <div className="col-md-4">
                    <Datepicker
                      name="date_apply"
                      ref="date_apply"
                      label={`${t('start')}:`}
                      defaultValue={null}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={"dd-MM-yyyy"}
                    />
                  </div>
                  <div className="col-md-4">
                    <Datepicker
                      name="expriration_date"
                      ref="expriration_date"
                      label={`${t('end')}:`}
                      defaultValue={null}
                      errors={errors}
                      clearIcon={false}
                      className="form-control input-form"
                      format={"dd-MM-yyyy"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <TextArea 
                  name="description"
                  ref="description"
                  label={`${t('description')}:`}
                  className="text-area-custom"
                  errors={errors}
                  rows="5"
                  onChange={this.onChangeTextArea}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation('salesPolicy')(withRouter(SalesPolicyNew));
