import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import AuthRoute from "./AuthRoute";
import { connect } from "react-redux";
import Menu from "../component/menu/Menu";
import Hompage from "../container/Hompage";
import ForgetPasswordRoutes from "./forget.route";
import ProfileRoutes from "./profile.route";
import Header from "../component/block/Header";
import MediaRoutes from "./media.route";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import DistributorRoutes from "./distributor.route";
import ProductRoute from "./product.route";
import RolesRoutes from "./roles.route";
import AccountRoutes from "./account.route";
import CustomerRoutes from "./customer.route";
import SalesPolicyRoutes from "./salespolicy.route";
import SaleRoutes from "./sale.route";
import CartRoutes from "./cart.route";
import DashboardRoute from "./DashboardRoute";
import SettingProjectRoute from "./SettingProjectRoute";
import TrackingRoute from "./tracking.route";

export const routes = [
  // Home page
  {
    path: "/",
    component: () => <Hompage />,
    exact: true,
  },
  // Forgot password
  {
    path: "/forgot",
    component: () => <ForgetPasswordRoutes />,
  },
  // Profile
  {
    path: "/profile",
    component: () => <ProfileRoutes />,
  },
  // Media
  {
    path: "/media*",
    component: () => <MediaRoutes />,
    exact: true,
  },
  // Distributor
  {
    path: "/distributor",
    component: () => <DistributorRoutes />,
  },

  // Product
  {
    path: "/product",
    component: () => <ProductRoute />,
  },

  // Roles
  {
    path: "/roles",
    component: () => <RolesRoutes />,
  },
  // Account
  {
    path: "/account",
    component: () => <AccountRoutes />,
  },
  // Customer
  {
    path: "/customer",
    component: () => <CustomerRoutes />,
  },

  // Sales Policy
  {
    path: "/sales_policy",
    component: () => <SalesPolicyRoutes />,
  },

  // Sale
  {
    path: "/sale_list",
    component: () => <SaleRoutes />,
  },
  // cart
  {
    path: "/cart",
    component: () => <CartRoutes />,
  },
  // Dashboard
  {
    path: "/dashboard",
    component: () => <DashboardRoute />,
  },
  // Tracking
  {
    path: "/tracking",
    component: () => <TrackingRoute />
  },
  // Setting prọect
  {
    path: "/setting_project",
    component: () => <SettingProjectRoute />,
  },
];

class HomeRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <NotificationContainer />
          <Menu />
          <div className="page-wrapper" id="page-wrapper">
            <Header />
            <Switch>
              {routes.map((route) => (
                <AuthRoute key={route.path} {...route} />
              ))}
            </Switch>
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state, props) => {
  return {};
};
const mapDispatchToProps = (dispatch, props) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(HomeRoutes);
