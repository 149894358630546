import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import { Link, withRouter } from "react-router-dom";
import { createCustomerRequest } from "../../actions/customer";
import { getFormDataFromRef } from "../../helpers/form";
import InputPhoneCustom from "../../component/common/InputPhoneCustom";
import Select from "../../component/common/Select";
import { getTakenData, postDataWithParams } from "../../services/base_services";
import { LIST_DISTRIBUTOR, GET_LIST_SALE_URL } from "../../constants/Config";
import { showMessage } from "../../helpers/table";
import ButtonSave from "../../component/common/ButtonSave";
import Datepicker from "../../component/common/Datepicker";
import InputCurrency from "../../component/common/InputCurrency";
import TextArea from "../../component/common/TextArea";
import CheckboxRowCustom from "../../component/common/CheckboxRowCustom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
class CustomerNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDistributor: [],
      errors: {},
      listSale: [],
    };
  }

  onCreateCustomer = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    let var_birthday = formData.birthday;
    formData.birthday = var_birthday?.split("-").reverse().join("-");
    let val = formData.phone;
    formData.phone = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`;
        } else if (s) {
          return `${f}${s}`;
        } else if (f) {
          return `${f}`;
        }
      });
    formData = {
      ...formData,
      status: 1,
      public_flg: formData.public_flg ? 1 : 2,
      description: this.description,
    };
    createCustomerRequest(formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ errors: err.err });
      });
  };

  componentDidMount = async () => {
    let listDistributor = await getTakenData(LIST_DISTRIBUTOR);
    this.setState({
      listDistributor: listDistributor.data.data,
    });
    if(this.props.user.position_id === 1 ) {
      this.loadListSale();
    }
  };

  loadListSale = async () => {
    await postDataWithParams(GET_LIST_SALE_URL, {}, {})
      .then((res) => {
        this.setState({
          listSale: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  onChangeTextArea = (data) => {
    this.description = data;
  };

  onChangeDVPP = (e) => {
    postDataWithParams(GET_LIST_SALE_URL, {}, { distributor_id: e.target.value })
      .then((res) => {
        this.setState({
          listSale: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  render() {
    let { errors, listDistributor, listSale } = this.state;
    let { t, user } = this.props;
    let position_id = user?.position_id;

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('createCustomer')}</p>
            </div>
            <div className="col-md-6 d-flex pr-0">
              <ButtonSave
                onClick={this.onCreateCustomer}
                text={t('save')}
              />
              <Link to="/customer">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row top-table">
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('fullname')}
                name="name"
                ref="name"
                label={`${t('fullname')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('address')}
                name="address"
                ref="address"
                label={`${t('address')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputPhoneCustom
                name="phone"
                ref="phone"
                label={`${t('phone')}:`}
                type="text"
                className="form-control input-form"
                placeholder={t('phone')}
                errors={errors}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder="Email"
                name="email"
                ref="email"
                label="Email:"
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <Datepicker
                name="birthday"
                ref="birthday"
                label={`${t('birthday')}:`}
                defaultValue={null}
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={`${t('incomeYearly')}:`}
                name="income"
                ref="income"
                label={`${t('income')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('job')}
                name="job"
                ref="job"
                label={`${t('job')}:`}
                errors={errors}
                type="text"
              />
            </div>

            {position_id !== 2 ? (
              position_id !== 1 ? (
                <>
                  <div className="col-md-6">
                    <Select
                      className="form-control input-form"
                      name="distributor_id"
                      ref="distributor_id"
                      label={`${t('distributor')}:`}
                      include_blank={t('distributor')}
                      key_value="id"
                      key_label="name"
                      data={listDistributor}
                      errors={errors}
                      onChange={(e) => this.onChangeDVPP(e)}
                    />
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="form-control input-form"
                      name="saler_id"
                      ref="saler_id"
                      label={`${t('saleName')}:`}
                      include_blank={t('saleName')}
                      key_value="id"
                      key_label="fullname"
                      data={listSale}
                      errors={errors}
                    />
                  </div>
                </>
              ) : (
                <div className="col-md-6">
                  <Select
                    className="form-control input-form"
                    name="saler_id"
                    ref="saler_id"
                    label={`${t('saleName')}:`}
                    include_blank={t('saleName')}
                    key_value="id"
                    key_label="fullname"
                    data={listSale}
                    errors={errors}
                  />
                </div>
              )
            ) : (
              ""
            )}

            <div className="col-md-12">
              <div className="block-checkbox-custom-customer">
                <CheckboxRowCustom
                  errors={[]}
                  id="public_flg"
                  ref="public_flg"
                  label={t('shareCustomerInfo')}
                  value="public_flg"
                  name="public_flg"
                />
              </div>
            </div>
            <div className="col-md-12">
              <TextArea
                name="description"
                ref="description"
                label={`${t('description')}:`}
                className="text-area-custom"
                errors={errors}
                rows="3"
                onChange={this.onChangeTextArea}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user?.user,
  };
};

export default connect(mapStateToProps, null)(withTranslation('customer')(withRouter(CustomerNew)));
