import React, { Component } from "react";
import DashboardMaintenance from '../container/DashboardMaintenance/index'
import AuthRoute from './AuthRoute';
import { Switch, Route } from "react-router-dom";

const routes = [
    {
        path: '/dashboard',
        component: () => <DashboardMaintenance />,
        exact: true
    }
];


class DashboardRoute extends Component {
    render() {
        if (typeof (window) !== "undefined") {
            return (
                <React.Fragment>
                    <Switch>
                        {
                            routes.map(route => (
                                <AuthRoute key={route.path} {...route} />
                            ))
                        }
                    </Switch>
                </React.Fragment>
            )
        }
    }
}

export default DashboardRoute;
