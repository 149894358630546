import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { getSaleRequest } from "../../actions/sale";
import deleteIcon from "../../assets/images/cancel.svg";
import detailIcon from "../../assets/images/detail.svg";
import ButtonSmall from "../../component/common/ButtonSmall";
import SelectCustom from "../../component/common/SelectCustom";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import { DELETE_SALE_URL, LIST_DISTRIBUTOR } from "../../constants/Config";
import { PER_PAGE } from "../../constants/Variable";
import { confirmDelete, showMessage } from "../../helpers/table";
import { deleteById, getTakenData } from "../../services/base_services";
import Can from "../../services/Can";
import iconValue from "../../assets/images/icons/value.svg";
import iconApproach from "../../assets/images/icons/approach.svg";
import { withTranslation } from "react-i18next";

class SaleTable extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.state = {
      listDistributor: [],
      per_page: PER_PAGE,
      page: 1,
      paginate: []
    };
  }

  componentDidMount() {
    this.loadSelectDistributor();
    this.loadSale();
  }

  loadSale = () => {
    let { page, per_page } = this.state;
    this.props.getSale(this.search, { page: page, per_page: per_page });
  };

  loadSelectDistributor = () => {
    getTakenData(LIST_DISTRIBUTOR).then((res) =>
      this.setState({
        listDistributor: res.data.data,
      })
    );
  };

  onDetailSale = (id) => {
    this.props.history.push(`/sale_list/${id}/detail`);
  };

  onDeleteSale = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeSaleRequest(id);
      }
    });
  };

  removeSaleRequest = (id) => {
    deleteById(DELETE_SALE_URL, id)
      .then((res) => {
        showMessage("Xóa sale thành công.", true);
        this.loadSale();
      })
      .catch((err) => {
        showMessage("Có lỗi khi xóa!", false);
      });
  };

  onChangeSearch = (e) => {
    let { page, per_page } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";
    this.search = {
      ...this.search,
      [name]: value,
    };
    this.props.getSale(this.search, { page: page, per_page: per_page });
  };

  changePage = (pageNumber, e) => {
    let { page, per_page } = this.state;
    if (pageNumber != page) {
      this.props.getSale(this.search, { page: pageNumber, per_page: per_page });
      this.setState({ page: pageNumber });
    }
  };

  render() {
    let { t, sale, isLoading } = this.props;
    let tableData = sale && sale.data;
    let paginate = sale && sale.paginate;
    let { listDistributor } = this.state;
    const tableHeaders = [
      {
        label: "Stt",
        index: "stt",
        option: {
          className: "text-center",
        },
        callback: null,
      },
      {
        label: t('saleName'),
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>{data && data.fullname}</span>
            </div>
          );
        },
      },
      {
        label: t('saleDistributor'),
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>{data && data.distributor && data.distributor.name}</span>
            </div>
          );
        },
      },
      {
        label: t('revenue'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>{data && data.revenue}</span>
            </div>
          );
        },
      },
      {
        label: t('numberCustomer'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>{data && data.number_customer}</span>
            </div>
          );
        },
      },
      {
        label: t('cart'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>{data && data.number_house_sold}</span>
            </div>
          );
        },
      },
      {
        label: t('functions'),
        index: null,
        option: {
          className: "text-center fixed-collumn",
        },
        callback: (data) => {
          if (data.id) {
            return (
              <div className="btn-group">
                <Can I="sale.show" a="sales">
                  <span data-tip={t('saleDetail')} className="link-action mr-2">
                    <img
                      src={detailIcon}
                      className="btn-icon"
                      onClick={() => this.onDetailSale(data.id)}
                      alt=""
                    />
                  </span>
                </Can>
                <Can I="sale.delete" a="sales">
                  <span data-tip={t('saleDetail')} className="link-action ml-2">
                    <img
                      src={deleteIcon}
                      className="btn-icon"
                      onClick={() => this.onDeleteSale(data.id)}
                      alt=""
                    />
                  </span>
                </Can>
                <ReactTooltip
                  className="tooltip-button"
                  place="bottom"
                  effect="float"
                />
              </div>
            );
          }
        },
      },
    ];

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('saleManagement')}:</p>
            </div>
            <div className="col-md-12 top-table-title">
              <div className="block-information row mb-4">
                <div className="col-3">
                  <div className="item-detail item-detail-1">
                    <p>{t('saleRate')}</p>
                    <span>
                      {tableData?.[0]?.sales_rate}{" "}
                      <img src={iconValue} alt="" />
                    </span>
                  </div>
                </div>
                <div className="col-3">
                  <div className="item-detail item-detail-2">
                    <p>{t('totalSale')}</p>
                    <span>
                      {tableData?.[0]?.total_sale}
                      <img src={iconApproach} alt="" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 top-table-title">
              <p>{t('saleList')}:</p>
            </div>
            <div className="col-md-12 d-flex">
              <div className="mr-auto d-flex">
                <Can I="sale.add" a="sales">
                  <Link to="/sale_list/new" className="btn-add-custom">
                    <ButtonSmall label={t('new')} />
                  </Link>
                </Can>
                <div>
                  <SelectCustom
                    className="form-control input-lg select-custom-search"
                    name="distributor_id"
                    ref="distributor_id"
                    classNameCustom="form-group-custom dd"
                    include_blank={t('distributor')}
                    key_value="id"
                    key_label="name"
                    data={listDistributor}
                    errors={[]}
                    onChange={this.onChangeSearch}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <Table
                fixed={true}
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    sale: state.Sale,
    isLoading: state.Sale.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSale: (data, params) => dispatch(getSaleRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('sale')(withRouter(SaleTable)));
