import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from '../../helpers/form';
import { useTranslation } from "react-i18next";

const BlockTableListBottom = (props) => {
  const { SalesPolicyStatistic } = props;
  const { t } = useTranslation('dashboard');

  return (
    <div className="block-table-list block-table-list-bottom">
      <div className="block-title">
        <p className="title-list">{t('salePolicy')}</p>
      </div>
      <div className="block-list">
        <div className="table-dashboard-maintenance">
          <table className="table">
            <thead>
              <tr>
                <th className="text-left">{t('policyName')}</th>
                <th className="text-left">{t('distributorApply')}</th>
                <th>{t('dateApply')}</th>
                <th className="text-left">{t('files')}</th>
              </tr>
            </thead>
            <tbody>
              {SalesPolicyStatistic?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.distributor?.name}</td>
                    <td className="text-center">{formatDate(item?.date_apply)}</td>
                    <td>
                      <a href={item?.file?.path} download>
                        {item?.file?.filename}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-center">
          <Link to="/sales_policy" className="view-more-detail">
            {t('viewMore')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlockTableListBottom;
