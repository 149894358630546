import React, { Component } from "react";
import { Switch } from "react-router-dom";
import AccountTable from "./../container/Account/AccountTable";
import AccountNew from "./../container/Account/AccountNew";
import AccountEdit from "./../container/Account/AccountEdit";
import AuthRoute from "./AuthRoute";

const routes = [
  {
    path: "/account",
    component: () => <AccountTable />,
    exact: true,
    meta: {
      action: "user.list",
      subject: "users",
    },
  },

  {
    path: "/account/new",
    component: () => <AccountNew />,
    exact: true,
    meta: {
      action: "user.add",
      subject: "users",
    },
  },

  {
    path: "/account/:id/edit",
    component: () => <AccountEdit />,
    exact: true,
    meta: {
      action: "user.edit",
      subject: "users",
    },
  },
];

class AccountRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
            {routes.map((route) => (
              <AuthRoute key={route.path} {...route} />
            ))}
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default AccountRoutes;
