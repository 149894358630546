import React, { Component } from "react";
import Select from "../../component/common/Select";
import InputText from "../../component/common/InputText";
import CheckboxRow from "../../component/common/CheckboxRow";
import InputCurrency from "../../component/common/InputCurrency";
import {
  getTakenData,
  getDataByID,
  putData,
} from "../../services/base_services";
import {
  GET_BLOCK_LIST,
  DIREICTION_PRODUCT,
  GET_FLOOR_BUILDING,
  GET_BUILDING_BLOCK,
  UPDATE_PRODUCT_REQUEST,
  GET_TEMP_PRODUCT,
  GET_DISTRIBUTOR_SELECT_URL,
  GET_PRODUCT_INFO,
  GET_BUILDING_ALL_URL,
  GET_FLOOR_ALL_URL,
} from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, Redirect, withRouter } from "react-router-dom";
import { showMessage } from "../../helpers/table";
import {
  destructServerErrors,
  destructServerMessage,
} from "../../helpers/error";
import ProductManager from "./ProductManager";
import TextArea from "../../component/common/TextArea";
import DocumentCustom from "../../component/common/DocumentCustom";
import { connect } from "react-redux";
import CheckboxRowCustom from "../../component/common/CheckboxRowCustom";
import { withTranslation } from "react-i18next";

class ProductEditInformation extends Component {
  constructor(props) {
    super(props);
    this.attach_file = "";
    this.id = this.props.match.params.id;
    this.state = {
      isLoading: true,
      listDis: [],
      listTemp: [],
      listBlock: [],
      listBuilding: [],
      listFloor: [],
      status: false,
      listDerection: [],
      errors: [],
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      await getDataByID(GET_PRODUCT_INFO, id)
        .then((res) => {
          this.setState({
            data: res.data.data,
            isLoading: false,
          });
          return Promise.resolve({ res });
        })
        .catch((error) => {
          let errs = destructServerMessage(error);
          return Promise.reject(error);
        });
    }

    this.getList();

    if (this.props.user.settings[1].status === 2) {
      this.loadBuildingAll();
    }
    if (this.props.user.settings[2].status === 2) {
      this.loadFloorAll();
    }
  };

  loadBuildingAll = () => {
    getTakenData(GET_BUILDING_ALL_URL).then((res) =>
      this.setState({
        listBuilding: res.data.data,
      })
    );
  };

  loadFloorAll = () => {
    getTakenData(GET_FLOOR_ALL_URL).then((res) =>
      this.setState({
        listFloor: res.data.data,
      })
    );
  };

  getList = async () => {
    await getTakenData(GET_BLOCK_LIST).then((res) => {
      this.setState({
        listBlock: res.data.data,
        loading_list: false,
      });
    });
    await getTakenData(DIREICTION_PRODUCT).then((res) => {
      this.setState({
        listDerection: res.data.data,
      });
    });
    await getTakenData(GET_TEMP_PRODUCT).then((res) => {
      this.setState({
        listTemp: res.data.data,
      });
    });
    await getTakenData(GET_DISTRIBUTOR_SELECT_URL).then((res) => {
      this.setState({
        listDis: res.data.data,
      });
    });
    await getTakenData(DIREICTION_PRODUCT).then((res) => {
      this.setState({
        listDerection: res.data.data,
      });
    });
  };
  selectBlock = (e) => {
    getDataByID(GET_BUILDING_BLOCK, e.target.value)
      .then((res) => {
        this.setState({
          listBuilding: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  selectBuilding = (e) => {
    getDataByID(GET_FLOOR_BUILDING, e.target.value)
      .then((res) => {
        this.setState({
          listFloor: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      description: this.description || this.state.description,
      attach_file: this.attach_file || this.state.data.attach_file,
    };
    formData.in_corner ? (formData.in_corner = 1) : (formData.in_corner = 2);
    formData.balcony_flg
      ? (formData.balcony_flg = 1)
      : (formData.balcony_flg = 2);
    formData.public_flg ? (formData.public_flg = 1) : (formData.public_flg = 2);
    formData.car_park ? (formData.car_park = 1) : (formData.car_park = 2);
    let { id } = this.props.match.params;

    putData(UPDATE_PRODUCT_REQUEST, id, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true,
        });
      })
      .catch((err) => {
        let errs = destructServerErrors(err);
        this.setState({
          errors: errs,
        });
      });
  };

  onChangeTextArea = (data) => {
    this.description = data;
  };

  onChangeDocument = (data) => {
    this.attach_file = data?.path;
  };

  render() {
    const {
      listBlock,
      listBuilding,
      listFloor,
      errors,
      listTemp,
      listDis,
      status,
      data,
      listDerection,
      isLoading,
    } = this.state;
    const { t } = this.props;
    let check_public_flg = data && data.public_flg == 1 ? true : false;
    let tempData = data && data.template_product;
    let check_car_park = tempData?.car_park === 1 ? true : false;
    let check_in_corner = tempData?.in_corner === 1 ? true : false;
    let check_balcony_flg = tempData?.balcony_flg === 1 ? true : false;
    let { user } = this.props;
    let settings = user?.settings;

    if (status) {
      return (
        <Redirect
          to={{
            pathname: "/product",
          }}
        />
      );
    }

    return isLoading ? (
      ""
    ) : (
      <>
        <div className="container-fluid container-shadow pb-5">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('productUpdateTitle')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/product">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <ProductManager idProduct={this.id} />
          <div className="row">
            {settings?.[1]?.status === 1 && (
              <div className="col-md-3 input-grey">
                <Select
                  label={`${t('productBlockType')}:`}
                  className="form-control input-form form-control-product mb-3 input-grey"
                  name="block_id"
                  ref="block_id"
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  include_blank={t('productBlockType')}
                  data={listBlock}
                  onChange={this.selectBlock}
                  defaultValue={data && data.block}
                />
              </div>
            )}
            {settings?.[2]?.status === 1 && (
              <div className="col-md-3 input-grey">
                <Select
                  label={`${t('productBuildingType')}:`}
                  className="form-control input-form form-control-product mb-3 input-grey"
                  name="building_id"
                  ref="building_id"
                  errors={errors}
                  key_value="id"
                  key_label="name"
                  include_blank={t('productBuildingType')}
                  data={listBuilding}
                  onChange={this.selectBuilding}
                  defaultValue={data && data.building}
                />
              </div>
            )}
            <div className="col-md-3 input-grey">
              <Select
                label={`${t('productFloorType')}:`}
                className="form-control input-form form-control-product mb-3 input-grey"
                name="floor_id"
                ref="floor_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('productFloorType')}
                data={listFloor}
                defaultValue={data && data.floor}
              />
            </div>
          </div>
          <div className="row new-product-temp">
            <div className="col-md-12">
              <p className=" new-product-temp-title">{t('basicInfomation')}</p>
            </div>
            <div className="col-md-6">
              <Select
                label={`${t('productTemplate')}:`}
                className="form-control input-form form-control-product mb-3 input-grey"
                name="template_product_id"
                ref="template_product_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('productTemplate')}
                data={listTemp}
                defaultValue={data && data.template_product}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('apartmentTypeName')}
                name="product_code"
                ref="product_code"
                label={`${t('productCode')}:`}
                errors={errors}
                type="text"
                defaultValue={data && data.product_code}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('bedroomNum')}
                name="number_bedroom"
                ref="number_bedroom"
                label={`${t('bedroomNum')}:`}
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.number_bedroom}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('bathroomNum')}
                name="number_bathroom"
                ref="number_bathroom"
                label={`${t('bathroomNum')}:`}
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.number_bathroom}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('areaInside')}
                name="area_inside"
                ref="area_inside"
                label={`${t('areaInside')}:`}
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.area_inside}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('areaBalcony')}
                name="area_balcony"
                ref="area_balcony"
                label={`${t('areaBalcony')}:`}
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.area_balcony}
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={t('priceTemplate')}
                name="price"
                ref="price"
                label={`${t('priceTemplate')}:`}
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.price}
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={t('priceCurrent')}
                name="current_price"
                ref="current_price"
                label={`${t('priceCurrent')}:`}
                errors={errors}
                type="text"
                defaultValue={data && data.current_price}
              />
            </div>

            <div className="col-md-6">
              <Select
                className="form-control input-form form-control-product mb-3"
                name="direction_id"
                ref="direction_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('direction')}
                data={listDerection}
                label={`${t('direction')}:`}
                defaultValue={tempData && tempData.direction}
              />
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-6">
                  <CheckboxRowCustom
                    errors={[]}
                    id="car_park"
                    ref="car_park"
                    label={t('carPark')}
                    value="car_park"
                    name="car_park"
                    defaultChecked={check_car_park}
                  />
                </div>
                <div className="col-md-6">
                  <CheckboxRowCustom
                    errors={[]}
                    id="in_corner"
                    ref="in_corner"
                    label={t('inCorner')}
                    value="in_corner"
                    name="in_corner"
                    defaultChecked={check_in_corner}
                  />
                </div>
                <div className="col-md-6">
                  <CheckboxRowCustom
                    errors={[]}
                    id="balcony_flg"
                    ref="balcony_flg"
                    label={t('balconyFlg')}
                    value="balcony_flg"
                    name="balcony_flg"
                    defaultChecked={check_balcony_flg}
                  />
                </div>
                {/* <div className="col-md-6">
                  <CheckboxRowCustom
                    errors={[]}
                    id="public_flg"
                    ref="public_flg"
                    label="Public"
                    value="public_flg"
                    name="public_flg"
                    defaultChecked={check_public_flg}
                  />
                </div> */}
              </div>
            </div>
            <div className="col-md-12">
              <DocumentCustom
                label={`${t('attachFileDownloadLabel')}:`}
                name="attach_file"
                title={t('attachFileDownload')}
                className="form-group form-group-custom-file"
                onChange={this.onChangeDocument}
                errors={errors}
                defaultValue={data && data.attach_file}
              />
            </div>
            <div className="col-md-12">
              <TextArea
                name="description"
                label={`${t('description')}:`}
                className="text-area-custom"
                errors={errors}
                onChange={this.onChangeTextArea}
                defaultValue={data && data.description}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user?.user,
  };
};

export default connect(
  mapStateToProps,
  null
)(withTranslation('product')(withRouter(ProductEditInformation)));
