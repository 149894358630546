import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Breadcrumb = props => {
  const { t } = useTranslation('document');
  let { breadcrumb, hidden} = props;
  if(!breadcrumb) breadcrumb = [];

  if(hidden) return '';

  return (
    <div className="small-breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/media"><i className="fa fa-folder-open-o mr-1" aria-hidden="true"></i>{t('document')}</Link>
      </li>
      {
        breadcrumb && breadcrumb.map((item, index) => {
          return index < breadcrumb.length - 1
          ? <li className="breadcrumb-item" key={item.id}><Link to={`/media/${item.id}`}><i className="fa fa-folder-open-o mr-1" aria-hidden="true"></i>{item.name}</Link></li>
          : <li className="breadcrumb-item active" aria-current="page" key={item.id}>{item.name}</li>;
        })
      }

    </div>
  )
}

export default Breadcrumb;
