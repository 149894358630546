import React, { Component } from 'react';
import Popup from "reactjs-popup";
import { InputText } from '../../component/common';
import { renameFolderRequest } from '../../actions/media';
import { showMessage } from '../../helpers/table';
import { withTranslation } from 'react-i18next';

class EditFolder extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      open: props.open,
      errors: [],
      isRequest: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open != prevState.open) {
      if (nextProps.open) {
        document.body.classList.add("modal-open");
      }
      return { open: nextProps.open };
    }
    else return null;
  }

  openModal = (e) => {
    e.preventDefault();
    document.body.classList.add("modal-open");
    this.setState({ open: true, errors: [] });
  }

  closeModal = (e) => {
    if (window.confirm("You haven't finished your form yet. Do you want to leave without save?")) {
      document.body.classList.remove("modal-open");
      this.props.closeRename();
      this.setState({ open: false, errors: [] });
    }
  }

  onSubmitForm = e => {
    e.preventDefault();
    let formData = { name: this.refs.name_edit.value }
    let { data } = this.props;
    if(!data) return;

    this.setState({ isRequest : true });
    renameFolderRequest(data.id, formData).then(res => {
      showMessage(res.data.message, true);
      this.setState({ isRequest : false, open: false, errors: [] });
      this.props.completeRename();
      document.body.classList.remove("modal-open");
    }).catch(err => {
      this.setState({ isRequest : false, errors: err.errors || [] });
    });
  }

  render() {
    const { open, errors, isRequest } = this.state;
    const { className } = this.props;
    const { data, t } = this.props;

    return (
      <div className={`custom-popup ${className}`}>
        <Popup
          open={open}
          modal
          closeOnDocumentClick={false}
        >
          <div className="modal-custom">
            <div className="popup-header pt-3 pb-2 px-3">
              <h5 className="mb-0">{t('editName')}</h5>
            </div>
            <span className="lnr lnr-cross-circle close-btn" onClick={(e) => this.closeModal(e)}></span>
            <div className="form-edit text-left p-3">
              <form className="row" onSubmit={this.onSubmitForm}>
                <div className="col-lg-12">
                  <InputText
                    name="name"
                    ref="name_edit"
                    type="text"
                    className="form-control input-form"
                    placeholder={t('folderName')}
                    autoComplete='off'
                    errors={errors}
                    defaultValue={data && data.name}
                  />
                </div>
                <div className="col-12">
                  <div className="btn-footer float-right mt-1">
                    <button type="button" className="btn btn-custom btn-outline-secondary mr-2" disabled={isRequest} onClick={(e) => this.closeModal(e)}>
                      {t('cancel')}
                    </button>
                    <button className="btn btn-custom btn-success" disabled={isRequest}>
                      {t('update')} {isRequest && <i className="ml-1 fa fa-circle-o-notch fa-spin"></i>}
                    </button>
                  </div>
                </div>
              </form>

            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default withTranslation('document')(EditFolder);
