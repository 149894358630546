import React, { Fragment, useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import { Form, Select, Button, Tag } from 'antd';
import { transformErrors } from '../../helpers';
import { useDistributors } from '../../hooks/distribution';
import { CUSTOMER_NEW_STATUS, CUSTOMER_STATUS_COLORS } from '../../constants/Variable';
import { useDevideCustomer } from '../../hooks/customer';
import { NotificationManager } from 'react-notifications';

const DevideCustomerForm = ({ customers, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const [{distributors, distributorLoading}, handleDistributors] = useDistributors();

  const closeDevide = () => {
    form.resetFields();
    setOpen(false);
  }

  const successCallback = (res) => {
    closeDevide();
    onSuccess();
    NotificationManager.success("Phân phối khách hàng thành công.", "Thành công!");
  }

  const errorCallback = (errors) => {
      let messages = transformErrors(errors, 'errors');
      form.setFields(messages);
  }

  const [{formLoading}, handleSubmit] = useDevideCustomer(successCallback, errorCallback);
  
  const openDevide = () => {
    setOpen(true);
  }

  useEffect(() => {
    handleDistributors();
  }, []);

  const onFinish = (values) => {
    const customerIds = customers.map((o) => o.id);
    values = { ...values, customer_id: customerIds, status: CUSTOMER_NEW_STATUS };
    handleSubmit(values)
  }

  return (
    <Fragment>
      <button className="btn btn-success btn-sm" onClick={openDevide}>
        <span className="lnr lnr-briefcase mr-1"></span> Phân phối khách hàng
      </button>
      <Popup
        open={open}
        modal
        closeOnDocumentClick={false}
        className="custom-popup"
        onClose={closeDevide}
      >
        <div className="popup-header">
          <h3 className="popup-header-title">Phân phối khách hàng</h3>
          <span className="close-btn" onClick={closeDevide}>
            <span className="lnr lnr-cross"></span>
          </span>
        </div>
        <div className="body">
          <Form form={form} layout='horizontal' onFinish={onFinish} className="justify-content-center">
            <Form.Item label="Khách hàng đã chọn" labelCol={{ span: 24 }}>
                <div>
                  {
                    customers && customers.map((o) => <Tag className="mb-2" color={CUSTOMER_STATUS_COLORS[o.status]}>{o.name}</Tag>) 
                  }
                </div>
            </Form.Item>

            <Form.Item name="distributor_id" label="Nhà phân phối" labelCol={{ span: 24 }}>
              <Select
                showSearch
                style={{ width: '100%' }}
                loading={distributorLoading}
                autoFocus={false}
                placeholder="Chọn nhà phân phối"
              >
                {
                  distributors && distributors.map((o) => <Option value={o.id}>{o.name}</Option>)
                }
              </Select>
            </Form.Item>
            
            <div className="btn-groups justify-content-end mt-4">
              <Button className="mr-3" danger type="primary" htmlType="button" disabled={formLoading} onClick={closeDevide}>
                Huỷ
              </Button>
              <Button type="primary" htmlType="submit" loading={formLoading}>Xác nhận</Button>
            </div>
          </Form>
        </div>
        </Popup>
    </Fragment>
  );
}

export default DevideCustomerForm;