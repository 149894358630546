import React, { Component } from 'react';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';

const InputText = React.forwardRef((props, ref) => {
  let { errors, ...input } = props;
  return (
    <div className={checkError(errors, input.name)}>
      {input.label && <label className="input-label">{input.label}</label>}
      <input {...input} ref={ref} />
      <Alert messsage={getError(errors, input.name)} />
    </div>
  )
});

export default InputText;
