import React, { Component } from 'react'
import Select from '../../component/common/Select'
import InputText from '../../component/common/InputText'
import CheckboxRow from '../../component/common/CheckboxRow'
import InputCurrency from '../../component/common/InputCurrency'
import GroupImage from '../../component/common/GroupImage'
import { getTakenData, postDataWithParams, postData, getDataByID, putData } from '../../services/base_services'
import { GET_LIST_TEMP_PRODUCT, CREATE_TEMP_PRODUCT_FLOOR, GET_TEMP_PRODUCT_FLOOR,UPDATE_TEMP_PRODUCT_FLOOR } from '../../constants/Config'
import ButtonSave from "../../component/common/ButtonSave";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, Redirect, withRouter } from 'react-router-dom'
import { confirmDelete, afterRemove } from '../../helpers/table';
import { destructServerErrors, destructServerMessage } from '../../helpers/error'
import { showMessage } from '../../helpers/table';
import { isNumber } from 'lodash'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'



class ProductFloorTemplateEdit extends Component {

    constructor(props) {
        super(props)
        this.id = this.props.match.params.id
        this.state = {
            listProduct: [
                {
                    template_product_id: "",
                }
            ],
            status: false,
            listData: [],
            listDerection: [],
            errors: [],
            data: [],
            isLoading: true
        }

    }

    componentDidMount = () => {
        this.getList()
    }

    getList = async () => {
        let { id } = this.props.match.params;
        await getTakenData(GET_LIST_TEMP_PRODUCT).then(res => {
            this.setState({
                listData: res.data.data,
            })
        }
        );
        if (id) {
            await getDataByID(GET_TEMP_PRODUCT_FLOOR, id).then(res => {
                this.setState({
                    data: res.data.data,
                    isLoading: false,
                    listProduct: res.data.data.template_products
                })
                return Promise.resolve({ res });
            }).catch((error) => {
                let errs = destructServerMessage(error);
                return Promise.reject(error)
            });
        }

    }

    addNew = (e) => {
        let { id } = this.props.match.params;
        let { listProduct } = this.state
        e.preventDefault();
        var formData = getFormDataFromRef(this.refs);
        formData.template_product_id = []
        Object.values(formData).map((item, index) => {
            // console.log(parseInt(item))
            if (parseInt(item)) {
                formData.template_product_id = [...formData.template_product_id, item]
            }
        })
        formData.number_product = formData.template_product_id.length
        putData(CREATE_TEMP_PRODUCT_FLOOR, id, formData).then(res => {
            showMessage(res.data.message, true);
            this.setState({
                errors: [],
                status: true
            })
        }).catch(err => {
            let errs = destructServerErrors(err);
            this.setState({
                errors: errs
            })
        })
    };

    newProduct = () => {
        const a = {
            template_product_id: "",
        }
        this.setState({
            listProduct: [...this.state.listProduct, a]
        })
    }
    deleteProduct = () => {
        let { listProduct } = this.state
        let a = listProduct
        a.splice(-1, 1)
        this.setState({
            listProduct: a
        })
    }

    render() {
        const { t } = this.props;
        const { errors, status, listData, listProduct, isLoading, data } = this.state

        if (status) {
            return (
                <Redirect
                    to={{
                        pathname: "/product/floortemp",
                    }}
                />
            );
        }

        return (
            isLoading ? "" :
                <>
                    <div className="container-fluid container-shadow">
                        <div className="row top-table">
                            <div className="col-md-6 top-table-title">
                                <p>{t('floorTemplateEdit')}</p>
                            </div>
                            <div className="col-md-6 d-flex">
                                <ButtonSave onClick={this.addNew} text={t('save')} />
                                <Link to="/product/floortemp">
                                    <ButtonSave text={t('cancel')} className="btn-delete" />
                                </Link>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <InputText
                                    className="form-control input-form"
                                    placeholder={t('floorTemplateName')}
                                    name="name"
                                    ref="name"
                                    label={`${t('floorTemplateName')}:`}
                                    errors={errors}
                                    type="text"
                                    defaultValue={data && data.name}
                                />
                            </div>
                        </div>
                        <div className="row px-5" >
                            <div className="col-md-12">
                                <p>
                                    {t('selectFloorTemplateNote')}:
                                </p>
                            </div>
                            {listProduct.map((item, index) =>
                                (listProduct.length - 1) == index ?
                                    <>
                                        {console.log(item)}
                                        <div className="col-md-6">
                                            <Select
                                                className="form-control input-form form-control-product mb-3"
                                                name={`template_product_id-${index + 1}`}
                                                ref={`template_product_id-${index + 1}`}
                                                errors={errors}
                                                key_value="id"
                                                key_label="name"
                                                include_blank={t('apartmentTemplate')}
                                                data={listData}
                                                label={`${index + 1}. `}
                                                row={true}
                                                defaultValue={item}
                                            />
                                            <div className="product-button-delete" onClick={this.deleteProduct}>
                                                x
                                        </div>
                                        </div>
                                    </>
                                    :
                                    <div className="col-md-6">
                                        <Select
                                            className="form-control input-form form-control-product mb-3"
                                            name={`template_product_id-${index + 1}`}
                                            ref={`template_product_id-${index + 1}`}
                                            errors={errors}
                                            key_value="id"
                                            key_label="name"
                                            include_blank={t('apartmentTemplate')}
                                            data={listData}
                                            label={`${index + 1}. `}
                                            row={true}
                                            defaultValue={item}

                                        />
                                    </div>

                            )}

                            <div className="col-md-6">
                                <span className="product-button-new mb-3" onClick={this.newProduct}>
                                    {t('createApartmentTemplate')}
                                </span>
                            </div>
                        </div>
                    </div>
                </>
        )
    }


}


const mapStateToProps = (state, props) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('product')(withRouter(ProductFloorTemplateEdit)));
