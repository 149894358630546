import React, { Component } from 'react';

class Message extends Component {
  constructor(props, context) {
    super(props, context);
    this.timer = null;
    this.state = {
      status: true
    }
  }

  componentDidMount(){
    this.timer = setTimeout(() => this.setState({status: false}), 3000);
  }

  componentWillUnmount(){
    clearTimeout(this.timer);
  }
  render() {
    let { message } = this.props;
    let { status } = this.state;

    if(!message || !status){
      return '';
    }

    return (
      <div className="alert alert-success" role="alert">
        {message}
      </div>
    );
  }
}

export default Message;
