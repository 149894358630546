import React, { useEffect, useState } from "react";
import ButtonSave from "../../component/common/ButtonSave";
import { Checkbox } from "../../component/common";
import { showMessage } from "./../../helpers/table";
import { GET_SETTING_DASHBOARD, GET_USER_URL } from "../../constants/Config";
import { getTakenData, postData } from "../../services/base_services";
import _ from "lodash";
import { setDataUser } from "../../actions/index";
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";

const SettingProject = () => {
  const [detailSettingProject, setDetailSettingProject] = useState([])
  const [dataForm, setDataForm] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation('setting');

  useEffect(() => {
    getSettingProject();
  }, []);

  const getSettingProject = async () => {
    getTakenData(GET_SETTING_DASHBOARD).then((res) => {
      setDetailSettingProject(res?.data?.data)
      const tmpArr = res?.data?.data?.map(item => {
        return item?.settings
      })
      const tmpArrMain = _.flatten(tmpArr)?.map(itemMain => {
        return { status: itemMain.status, setting_id: itemMain.id }
      })
      setDataForm(tmpArrMain)
    });
  };

  const handleInputChange = (e, id, status) => {
    const target = e.target;
    const tmpArr = dataForm?.map((item, index) => {
      if (item.setting_id === id) {
        return { ...item, status: target.checked ? 1 : 2 }
      }
      return item
    })
    setDataForm(tmpArr)
  };

  const onSubmit = () => {
    postData(GET_SETTING_DASHBOARD, dataForm)
      .then((res) => {
        showMessage(t('updateSettingSuccess'), true);
        getTakenData(GET_USER_URL).then((res) =>
          dispatch(setDataUser(res.data.data))
        );
      })
      .catch((err) => {
        showMessage(t('updateSettingFail'), false);
      });
  };

  return (
    <div className="container-fluid container-shadow">
      <div className="row top-table">
        <div className="col-md-6 top-table-title">
          <p>{t('projectSetting')}</p>
        </div>
        <div className="col-md-6 d-flex">
          <ButtonSave onClick={onSubmit} text={t('save')} />
        </div>
        {
          detailSettingProject?.map((item, index) => {
            return (
              <>
                <div className="col-md-12 d-flex flex-wrap">
                  <label className="input-label mg-top-10">
                    {item?.name}{":"}
                  </label>
                </div>
                {
                  item?.settings?.map((itemIn, indexIn) => {
                    let isChecked = itemIn.status === 1 ? true : false
                    return (
                      <div className="col-md-4 d-flex flex-wrap">
                        <Checkbox onChange={(e) => handleInputChange(e, itemIn.id, itemIn.status)} id={`setting-${index}-${indexIn}`} defaultChecked={isChecked} label={itemIn.name} />
                      </div>
                    )
                  })
                }
              </>
            )
          })
        }
      </div>
    </div>
  );
};

export default SettingProject;
