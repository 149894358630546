import { postDataWithParams, getDataByID, postData,putData, getTakenData } from './../services/base_services';
import { USER_SELECT_URL, GET_LIST_USER_URL, NEW_USER_URL, DETAIL_USER_URL, UPDATE_USER_URL } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors } from '../helpers/error';

const userRequest = status => {
  return {
    type: types.USER_REQUEST,
    status
  }
}

// GET LIST USER
const getUser = (data, paginate) => {
  return {
    type: types.GET_USER_LIST,
    data,
    paginate
  }
}

export const getUserRequest = (search, params) => dispatch => {
  dispatch(userRequest(true));
  postDataWithParams(GET_LIST_USER_URL, search, params).then(res => {
    dispatch(getUser(res.data, res.paginate));
    dispatch(userRequest(false));
  }).catch(error => {
    dispatch(userRequest(false));
    return Promise.reject(error)
  })
}

export const resetState = () => {
  return {
    type: types.RESET_USER
  }
}
// CREATE
export const createUserRequest = (data) => {
  return postData(NEW_USER_URL, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// UPDATE
export const updateUserRequest = (id, data) => {
  return putData(UPDATE_USER_URL, id, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET
export const getDetailUserRequest = (id) => {
  return getDataByID(DETAIL_USER_URL, id).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET USER SELECT
export const getUserSelectRequest = () => {
  return getTakenData(USER_SELECT_URL).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
