import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import detailIcon from "../../assets/images/detail.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import PerPage from "../../component/Paginate/PerPage";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCustomerRequest } from "../../actions/customer";
import { DELETE_CUSTOMER_URL, LIST_DISTRIBUTOR, LIST_STATUS_CUSTOMER } from "../../constants/Config";
import { deleteById, getTakenData } from "../../services/base_services";
import { confirmDelete, afterRemove, showMessage } from "../../helpers/table";
import InputSearch from "../../component/common/InputSearch";
import SelectCustom from "../../component/common/SelectCustom";
import NewButton from "../../component/common/NewButton";
import Can from "../../services/Can";
import { PER_PAGE } from "../../constants/Variable";
import ButtonSmall from "../../component/common/ButtonSmall";
import ButtonSave from "../../component/common/ButtonSave";
import ImportExcel from './ImportExcel'
import { withTranslation } from "react-i18next";

class CustomerTable extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.message = null;
    this.state = {
      excel: false,
      listDistributor: [],
      perPage: PER_PAGE,
      paginate: [],
      distributorId: null,
      listStatusCustomer: [],
    };
  }

  onDeleteCustomer = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeCustomerRequest(id);
      }
    });
  };

  removeCustomerRequest = (id) => {
    deleteById(DELETE_CUSTOMER_URL, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.loadCustomer();
      })
      .catch((err) => {
        showMessage(err.data.message, false);
      });
  };

  componentDidMount() {
    this.loadSelectDistributor();
    this.loadSelectStatus();
    this.loadCustomer();
    this.setState({
    });
  }

  loadCustomer = () => {
    let { page, perPage } = this.state;
    this.props.getCustomer(this.search, { page: page, per_page: perPage });
  };

  loadSelectStatus = () => {
    getTakenData(LIST_STATUS_CUSTOMER).then((res) =>
      this.setState({
        listStatusCustomer: res.data.data,
      })
    );
  };

  loadSelectDistributor = () => {
    getTakenData(LIST_DISTRIBUTOR).then((res) =>
      this.setState({
        listDistributor: res.data.data,
      })
    );
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getCustomer(this.search, {
        page: pageNumber,
        per_page: perPage,
      });
      this.setState({ page: pageNumber });
    }
  };

  onChangeSearch = (e) => {
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";

    this.search = {
      ...this.search,
      [name]: value,
    };

    this.props.getCustomer(this.search, { page: page, per_page: perPage });
  };

  onChangeDVPP = (e) => {
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";
    this.search = {
      ...this.search,
      [name]: value,
    };
    this.setState({
      distributorId: value,
    });
    this.props.getCustomer(this.search, { page: page, per_page: perPage });
  };

  onDetailCustomer = (id) => {
    this.props.history.push(`/customer/${id}/detail`);
  };

  onEditCustomer = (id) => {
    this.props.history.push(`/customer/${id}/edit`);
  };

  onChangeStatus = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.search = {
      ...this.search,
      [name]: value,
    };
    this.loadCustomer();
  };

  openExcel = () => {
    this.setState({
      'excel': true
    });
  }

  closeExcel = () => {
    this.setState({
      'excel': false
    });
  }

  onExcelSuccess = () => {
    this.loadCustomer();
  }

  render() {
    let { t, customer, isLoading } = this.props;
    let tableData = customer && customer.data;
    let paginate = customer && customer.paginate;
    let { perPage, listDistributor, distributorId, excel, listStatusCustomer } = this.state;
    const tableHeaders = [
      {
        label: "Stt",
        index: "stt",
        option: {
          className: "text-center position-relative",
        },
        callback: null,
      },
      {
        label: t('customerName'),
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>{data && data.name}</span>
            </div>
          );
        },
      },
      {
        label: t('customerContact'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>{data && data.phone}</span>
            </div>
          );
        },
      },
      {
        label: "Email",
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>{data && data.email}</span>
            </div>
          );
        },
      },
      {
        label: t('customerSource'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div className="w-200-px">
              <span>{data && data.source}</span>
            </div>
          );
        },
      },
      {
        label: t('customerDistributor'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div className="w-200-px">
              <span>{data && data.distributor && data.distributor.name}</span>
            </div>
          );
        },
      },
      {
        label: t('customerStatus'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <span className={`block-status-table block-status-table-${data?.status}`}>{data?.status_lable}</span>
          );
        },
      },
      {
        label: t('functions'),
        index: null,
        option: {
          className: "text-center fixed-collumn",
        },
        callback: (data) => {
          if (data.id) {
            return (
              <div className="btn-group">
                <Can I="customer.show" a="customers">
                  <span
                    data-tip={t('showCustomer')}
                    className="link-action mr-2"
                  >
                    <img
                      src={detailIcon}
                      className="btn-icon"
                      onClick={() => this.onEditCustomer(data.id)}
                      alt=""
                    />
                  </span>
                </Can>
                <Can I="customer.delete" a="customers">
                  <span
                    data-tip={t('deleteCustomer')}
                    className="link-action ml-2"
                  >
                    <img
                      src={deleteIcon}
                      className="btn-icon"
                      onClick={() => this.onDeleteCustomer(data.id)}
                      alt=""
                    />
                  </span>
                </Can>
                <ReactTooltip
                  className="tooltip-button"
                  place="bottom"
                  effect="float"
                />
              </div>
            );
          }
        },
      },
    ];

    return (
      <>
        <ImportExcel open={excel} onClose={this.closeExcel} onSuccess={this.onExcelSuccess}/>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-12">
              <h3 className="title-cart-top">{t('customerList')}</h3>
              <div className="button-title d-flex">
                   <Can I="customer.list" a="customers">
                      <Link to="/customer/distributions" className="btn btn-normal mr-2">
                        {t('customerDistribution')}
                      </Link>
                   </Can>
                   <ButtonSmall label={t('importExcel')} className="active" onClick={this.openExcel}/>
              </div>
            </div>
            <div className="col-md-12 d-flex">
              <div className="ml-auto d-flex">
                {distributorId && (
                  <>
                    <Link
                      to={`customer/distribution/${distributorId}`}
                      className="btn-add-custom mt-0"
                    >
                      <ButtonSave text={t('customerDistribution')} />
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-12 mt-4 d-flex">
              <div className="mr-auto d-flex">
                <Can I="customer.add" a="customers">
                  <Link to="/customer/new" className="btn-add-custom">
                    <ButtonSmall label={t('newCustomer')} />
                  </Link>
                </Can>
                <Can I="customer.list-search" a="customers">
                  <InputSearch onChange={this.onChangeSearch} placeholder={t('search')} />
                </Can>
                <div>
                <SelectCustom
                  className="form-control input-lg select-custom-search"
                  name="distributor_id"
                  ref="distributor_id"
                  classNameCustom="form-group-custom"
                  include_blank={t('distributor')}
                  key_value="id"
                  key_label="name"
                  data={listDistributor}
                  errors={[]}
                  onChange={this.onChangeDVPP}
                />
                </div>
                <div>
                <SelectCustom
                  className="form-control input-lg select-custom-search"
                  name="status"
                  ref="status"
                  include_blank={t('status')}
                  key_value="id"
                  key_label="name"
                  data={listStatusCustomer}
                  errors={[]}
                  onChange={(e) => this.onChangeStatus(e)}
                />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <Table
                fixed={true}
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    customer: state.Customer,
    isLoading: state.Customer.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getCustomer: (data, params) => dispatch(getCustomerRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('customer')(withRouter(CustomerTable)));
