import React, { Component } from 'react';
import RawTable from './RawTable';
import { getListMediaRequest } from '../../actions/media';
import { Folder } from '../../assets/images/icons';
import NewFolder from './NewFolder';
import UploadFile from './UploadFile';
import { sizeOf } from '../../helpers/form'
import { MEDIA_FILE } from '../../constants/Variable';
import FileIcon, { defaultStyles } from 'react-file-icon';
import Breadcrumb from './Breadcrumb';
import Skeleton from 'react-loading-skeleton';
import Shared from './Shared';
import { withTranslation } from 'react-i18next';

class Media extends Component {
  constructor(props, context) {
    super(props, context);
    this.search = {};
    this.id = props.match.params.id;
    this.state = {
      id:  props.match.params.id,
      data: [],
      folder: null,
      isLoading: true,
      openFilter: false,
      newFolder: false,
      uploadFile: false,
      share_id: null,
      FilesUploading: []
    };
  }

  componentDidMount(){
    this.loadMedia(this.search, this.state.id);
  }

  // Prevent rerender many time
  static getDerivedStateFromProps = (props, state) => {
    if(props.match.params.id != state.id){
      return { id: props.match.params.id }
    }else
    return null
  }

  componentDidUpdate(props, state){
    if(this.state.id != state.id ){
      this.loadMedia({}, this.state.id);
    }
  }
  // Prevent rerender many time

  loadMedia = (search, id) => {
    this.setState({ isLoading : true });
    getListMediaRequest(search, id).then(res => {
      this.setState({ data: res.data.data, folder: res.data.folder, isLoading : false });
    }).catch(err => {
      console.error(err);
      this.setState({ isLoading : false });
    })
  }


  openFilter = () => {
    let { openFilter } = this.state;
    this.setState({ openFilter: !openFilter });
  }

  // open new folder form
  newFolder = (e, status) => {
    e.preventDefault();
    this.setState({ newFolder : status})
  }

  onCompleteFolder = () => {
    this.loadMedia(this.search, this.state.id);
    this.setState({ newFolder : false});
  }

  // double click row
  popupCenter (url, title, w, h) {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const left = (window.screen.width/2)-(w/2) + dualScreenLeft;
    const top = 10;
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  }

  onClickRow = data => {
    if(data){
      if(data.model_type == MEDIA_FILE){
        this.popupCenter(data.path,'popup',800,500);
      }else{
        this.props.history.push(`/media/${data.id}`);
      }
    }
    return false;
  }

  // Edit folder
  onCompleteEditFolder = () => {
    this.loadMedia(this.search, this.state.id);
  }

  // Upload file
  onOpenUploadFile = (e) => {
    e.preventDefault();
    document.getElementById('upload_files').click();
  }

  onUploadFiles = e => {
    let { files } = e.target;
    if(files.length > 0){
      this.setState({ uploadFile : true, FilesUploading: [...this.state.FilesUploading, ...Array.from(files)]}, () => {
        e.value = "";
      });
    }
  }

  onCloseUploadFile = () => {
    document.getElementById('upload_files').value= null;
    this.setState({ uploadFile : false, FilesUploading: [] });
  }

  uploadSuccess = item => {
    this.setState({
      data: [item, ...this.state.data]
    });
  }

  // Shared file or folder
  openShare = (id) => {
    this.setState({ share_id : id})
  }

  render() {
    const { t } = this.props;
    const { data, isLoading, openFilter, newFolder, uploadFile, FilesUploading, folder, share_id } = this.state;
    const tableHeaders = [
      {
        label: t('name'),
        index: null,
        callback: data => {
          return <div className="item-name">
            {
              data.model_type == MEDIA_FILE
              ? <div className="icon-file"><FileIcon extension={data.file_etx} {...defaultStyles[data.file_etx]}/></div>
              :<img
                  src={Folder}
                  alt="folder icon"
                  className="icon-folder mr-2"
                />
            }
            <span>{data.name}</span>
          </div>
        }
      },
      {
        label: t('createdAt'),
        index: 'created_at',
        callback: null
      },
      {
        label: t('createdBy'),
        index: 'created_by',
        callback: data => data && data.name
      },
      {
        label: t('size'),
        index: 'file_size',
        callback: data => data ? sizeOf(data) : '---'
      }
    ];
    return (
      <div className="main">
          <Shared open={share_id != null} share_id={share_id} openShare={this.openShare}/>
          <NewFolder className='modal-small' open={newFolder} parent_id={this.state.id} newFolder={this.newFolder} onCompleteFolder={this.onCompleteFolder}/>
          <UploadFile closeUpdate={this.onCloseUploadFile} open={uploadFile} files={FilesUploading} onClose={this.onCloseUploadFile} parent_id={this.state.id} uploadSuccess={this.uploadSuccess}/>

          <div className="page-header">

            {/* <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/"><i className="lnr lnr-home mr-2"></i>Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">Quản lí thư viện</li>
              </ol>
            </nav> */}

            <div className="page-right">
              <div className="dropdown btn-dropdown">
                  <button type="button" className="btn btn-outline btn-new dropdown-toggle" data-toggle="dropdown">
                    <span>{t('create')}</span>
                  </button>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="#" onClick={(e) => this.newFolder(e, true)}>
                      <span className="lnr lnr-plus-circle mr-1"></span> {t('createFolder')}
                    </a>
                    {/* <a className="dropdown-item" href="#"><span className="lnr lnr-upload mr-1"></span> Tải folder lên</a> */}
                    <a className="dropdown-item" href="#" onClick={(e) => this.onOpenUploadFile(e)}>
                      <span className="lnr lnr-upload mr-1"></span> {t('uploadFile')}
                    </a>
                    <input type="file" name="files[]" multiple id="upload_files" className="d-none" onChange={this.onUploadFiles}/>
                  </div>
              </div>

              {/* <div className="search-form">
                <div className="input-wrapper">
                    <span className="lnr lnr-magnifier"></span>
                    <input name="search_text" className="form-control input-form" placeholder="Search..."/>
                </div>
              </div> */}
            </div>
          </div>

          <div className="page-content mt-1">
              <div className="card card-custom">
                  <div className={`card-header ${this.state.id ? 'has_breadcrumb' : ''}`}>
                      <div className="header-breadcrumb">
                        <h4>{t('documentManagement')}</h4>
                        {isLoading ? <Skeleton height={25} width={200}/>: <Breadcrumb hidden={!this.state.id} breadcrumb={folder && folder.breadcrumb}/>}
                      </div>

                      {/* <div className="btn-wrapper">
                          <button className="btn btn-outline btn-icon mr-2">
                            <span className="lnr lnr-cog"></span>
                          </button>
                          <button className="btn btn-outline btn-icon btn-danger mr-2">
                            <span className="lnr lnr-trash"></span>
                          </button>
                          <button className="btn btn-outline btn-icon mr-2">
                            <span className="lnr lnr-users"></span>
                          </button>
                          <button className="btn btn-outline btn-icon" onClick={() => this.openFilter()}>
                            <span className="lnr lnr-funnel"></span>
                          </button>
                      </div> */}
                  </div>
                  <div className="card-body">
                    <div className="row">
                        <div className={`${openFilter ? 'col-lg-8' : 'col-lg-12'}`}>
                          <RawTable
                            tableHeaders={tableHeaders}
                            tableData={data}
                            isLoading={isLoading}
                            onClickRow={this.onClickRow}
                            onOpenShare={this.openShare}
                            onCompleteEditFolder={this.onCompleteEditFolder}
                          />
                        </div>
                        {
                          openFilter && <div className="col-lg-4">Đây là fileter</div>
                        }
                    </div>

                  </div>
              </div>
          </div>

      </div>
    );
  }
}

export default withTranslation('document')(Media);
