import React, { Component } from "react";
import Select from "../../component/common/Select";
import InputText from "../../component/common/InputText";
import CheckboxRow from "../../component/common/CheckboxRow";
import InputCurrency from "../../component/common/InputCurrency";
import GroupImage from "../../component/common/GroupImage";
import {
  getTakenData,
  postDataWithParams,
  postData,
  getDataByParams,
  getDataByID,
  putData,
} from "../../services/base_services";
import {
  GET_BLOCK_LIST,
  DIREICTION_PRODUCT,
  GET_FLOOR_BUILDING,
  GET_BUILDING_BLOCK,
  CREATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_REQUEST,
  GET_TEMP_PRODUCT,
  GET_DISTRIBUTOR_SELECT_URL,
  GET_PRODUCT_INFO,
} from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, Redirect, withRouter } from "react-router-dom";
import { confirmDelete, afterRemove } from "./../../helpers/table";
import { showMessage } from "../../helpers/table";
import {
  destructServerErrors,
  destructServerMessage,
} from "../../helpers/error";
import { withTranslation } from "react-i18next";

class ProductEdit extends Component {
  constructor(props) {
    super(props);
    this.documents1 = [];
    this.documents2 = [];

    this.id = this.props.match.params.id;

    this.state = {
      isLoading: true,
      listDis: [],
      listTemp: [],
      listBlock: [],
      listBuilding: [],
      listFloor: [],
      status: false,
      listDerection: [],
      errors: [],
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      await getDataByID(GET_PRODUCT_INFO, id)
        .then((res) => {
          this.setState({
            data: res.data.data,
            isLoading: false,
          });
          return Promise.resolve({ res });
        })
        .catch((error) => {
          let errs = destructServerMessage(error);
          return Promise.reject(error);
        });
    }

    this.getList();
  };

  onChangeDocument1 = (data) => {
    this.documents1 = data;
  };
  onChangeDocument2 = (data) => {
    this.documents2 = data;
  };

  getList = async () => {
    await getTakenData(GET_BLOCK_LIST).then((res) => {
      this.setState({
        listBlock: res.data.data,
        loading_list: false,
      });
    });
    await getTakenData(DIREICTION_PRODUCT).then((res) => {
      this.setState({
        listDerection: res.data.data,
      });
    });
    await getTakenData(GET_TEMP_PRODUCT).then((res) => {
      this.setState({
        listTemp: res.data.data,
      });
    });
    await getTakenData(GET_DISTRIBUTOR_SELECT_URL).then((res) => {
      this.setState({
        listDis: res.data.data,
      });
    });
    await getTakenData(DIREICTION_PRODUCT).then((res) => {
      this.setState({
        listDerection: res.data.data,
      });
    });
  };
  selectBlock = (e) => {
    getDataByID(GET_BUILDING_BLOCK, e.target.value)
      .then((res) => {
        this.setState({
          listBuilding: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  selectBuilding = (e) => {
    getDataByID(GET_FLOOR_BUILDING, e.target.value)
      .then((res) => {
        this.setState({
          listFloor: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      interior_images: this.documents1,
      layout_images: this.documents2,
    };
    formData.in_corner ? (formData.in_corner = 2) : (formData.in_corner = 1);
    formData.balcony_flg
      ? (formData.balcony_flg = 2)
      : (formData.balcony_flg = 1);
    let { id } = this.props.match.params;

    putData(UPDATE_PRODUCT_REQUEST, id, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true,
        });
      })
      .catch((err) => {
        let errs = destructServerErrors(err);
        this.setState({
          errors: errs,
        });
      });
  };

  render() {
    const {
      listBlock,
      listBuilding,
      listFloor,
      errors,
      listTemp,
      listDis,
      status,
      data,
      listDerection,
      isLoading,
    } = this.state;
    const { t } = this.props;
    
    let isCheck =
      data && data.template_product && data.template_product.in_corner == 2
        ? true
        : false;
    let isCheck2 =
      data && data.template_product && data.template_product.balcony_flg == 2
        ? true
        : false;
    let tempData = data && data.template_product;

    if (status) {
      return (
        <Redirect
          to={{
            pathname: "/product",
          }}
        />
      );
    }
    return isLoading ? (
      ""
    ) : (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('productUpdateTitle')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/product">
                <ButtonSave text={t('cancel')} />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 input-grey">
              <Select
                label={t('productBlockType')}
                className="form-control input-form form-control-product mb-3 input-grey"
                name="block_id"
                ref="block_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('productBlockType')}
                data={listBlock}
                onChange={this.selectBlock}
                defaultValue={data && data.block}
              />
            </div>
            <div className="col-md-3 input-grey">
              <Select
                label={t('productBuildingType')}
                className="form-control input-form form-control-product mb-3 input-grey"
                name="building_id"
                ref="building_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('productBuildingType')}
                data={listBuilding}
                onChange={this.selectBuilding}
                defaultValue={data && data.building}
              />
            </div>
            <div className="col-md-3 input-grey">
              <Select
                label={t('productFloorType')}
                className="form-control input-form form-control-product mb-3 input-grey"
                name="floor_id"
                ref="floor_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('productFloorType')}
                data={listFloor}
                defaultValue={data && data.floor}
              />
            </div>
          </div>
          <div className="row new-product-temp">
            <div className="col-md-12">
              <p className=" new-product-temp-title">{t('basicInfomation')}</p>
            </div>
            <div className="col-md-6 pl-md-5">
              <Select
                label="Sản phẩm mẫu"
                className="form-control input-form form-control-product mb-3 input-grey"
                name="template_product_id"
                ref="template_product_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank="Sản phẩm mẫu"
                data={listTemp}
                defaultValue={data && data.template_product}
              />
            </div>
            <div className="col-md-6 pr-md-5">
              <InputText
                className="form-control input-form"
                placeholder="Tên loại phòng"
                name="product_code"
                ref="product_code"
                label="Mã phòng : "
                errors={errors}
                type="text"
                defaultValue={data && data.product_code}
              />
            </div>
            <div className="col-md-6 pl-md-5">
              <InputText
                className="form-control input-form"
                placeholder="Phòng ngủ"
                name="number_bedroom"
                ref="number_bedroom"
                label="Phòng ngủ : "
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.number_bedroom}
              />
            </div>
            <div className="col-md-6 pr-md-5">
              <InputText
                className="form-control input-form"
                placeholder="Phòng tắm  "
                name="number_bathroom"
                ref="number_bathroom"
                label="Phòng tắm : "
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.number_bathroom}
              />
            </div>
            <div className="col-md-6 pl-md-5">
              <InputText
                className="form-control input-form"
                placeholder="DT trong"
                name="area_inside"
                ref="area_inside"
                label="DT trong : "
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.area_inside}
              />
            </div>
            <div className="col-md-6 pr-md-5">
              <InputText
                className="form-control input-form"
                placeholder="DT ngoài"
                name="area_balcony"
                ref="area_balcony"
                label="DT ngoài : "
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.area_balcony}
              />
            </div>
            <div className="col-md-6 pl-md-5">
              <InputCurrency
                className="form-control input-form"
                placeholder="Giá mẫu :"
                name="price"
                ref="price"
                label="Giá mẫu : "
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.price}
              />
            </div>
            <div className="col-md-6 pr-md-5">
              <InputCurrency
                className="form-control input-form"
                placeholder="Giá hiện tại :"
                name="current_price"
                ref="current_price"
                label="Giá hiện tại : "
                errors={errors}
                type="text"
                defaultValue={data && data.current_price}
              />
            </div>

            <div className="col-md-6 pl-md-5">
              <Select
                className="form-control input-form form-control-product mb-3"
                name="direction_id"
                ref="direction_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank="Hướng"
                data={listDerection}
                label="Hướng :"
                defaultValue={tempData && tempData.direction}
              />
            </div>
            <div className="col-md-6 pr-md-5">
              <InputCurrency
                className="form-control input-form"
                placeholder="Bãi đổ xe :"
                name="car_park"
                ref="car_park"
                label="Bãi đổ xe : "
                errors={errors}
                type="text"
                defaultValue={tempData && tempData.car_park}
              />
            </div>
            <div className="col-md-6 d-flex pl-md-5">
              <CheckboxRow
                errors={[]}
                id="in_corner"
                ref="in_corner"
                label="Ở Góc"
                value="in_corner"
                name="in_corner"
                defaultChecked={isCheck}
              />
            </div>
            <div className="col-md-6 d-flex pr-md-5">
              <CheckboxRow
                errors={[]}
                id="balcony_flg"
                ref="balcony_flg"
                label="Ban công"
                value="balcony_flg"
                name="balcony_flg"
                defaultChecked={isCheck2}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 pl-md-5">
              <GroupImage
                label="HÌNH ẢNH THIẾT KẾ"
                name="interior_images"
                title="Chọn ảnh để tải"
                errors={errors}
                onChange={this.onChangeDocument1}
                default={
                  data &&
                  data.template_product &&
                  data.template_product.interior_images
                }
              />
            </div>
            <div className="col-md-6 pr-md-5">
              <GroupImage
                label="HÌNH ẢNH NỘI THẤT"
                name="layout_images"
                title="Chọn ảnh để tải"
                errors={errors}
                onChange={this.onChangeDocument2}
                order={2}
                default={
                  data &&
                  data.template_product &&
                  data.template_product.layout_images
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('product')(withRouter(ProductEdit));
