import React, { Component } from "react";
import Popup from "reactjs-popup";
import InputText from './../../component/common/InputText'
import { getFormDataFromRef } from "./../../helpers/form";
import { showMessage } from "./../../helpers/table";
import {
  changePassWordRequest
} from "./../../actions/index";
import { withTranslation } from "react-i18next";
class FormChangePass extends Component {
    constructor(props, context) {
      super(props, context);
      this.state = {
        open: true,
        errors: {},
      };
    }

    closeModal = () => {
      this.setState({ open: false });
      this.props.onClosePopup();
    };
    
    onSubmitForm = e => {
      e.preventDefault();
      var data = getFormDataFromRef(this.refs);
      changePassWordRequest(data).then(res => {
        showMessage(res.data.message, true);
        this.setState({ open: false });
        this.props.onClosePopup();
      }).catch(err => {
        this.setState({ errors: err.err });
      })
    }

    render() {
        let { errors } = this.state;
        const { t } = this.props;
      
        return (
          <React.Fragment>
            <Popup
              open={this.state.open}
              modal
            >
              {
                <div className="modal-custom">
                  <div className="popup-header text-center">
                    {t('changePassword')}
                  </div>
                  <div className="popup-body">
                    <form className="form-change-pass" onSubmit={this.onSubmitForm}>
                      <div className="row">
                        <div className="col-md-12">
                          <InputText
                            name="current_password"
                            ref="current_password"
                            label={`${t('currentPassword')}(*)`}
                            type="password"
                            className="form-control input-form"
                            placeholder={t('currentPassword')}
                            errors={errors}
                          />
                        </div>
                        <div className="col-md-12">
                          <InputText
                            name="new_password"
                            ref="new_password"
                            label={`${t('newPassword')}(*)`}
                            type="password"
                            className="form-control input-form"
                            placeholder={t('newPassword')}
                            errors={errors}
                          />
                        </div>
                        <div className="col-md-12">
                          <InputText
                            name="new_confirm_password"
                            ref="new_confirm_password"
                            label={`${t('confirmNewPassword')}(*)`}
                            type="password"
                            className="form-control input-form"
                            placeholder={t('confirmNewPassword')}
                            errors={errors}
                          />
                        </div>
                        <div className="col-md-12 col-last-custom">
                          <div className="button-new float-right pr-0">
                            <span onClick={() => this.closeModal() }>
                              {t('close')}
                            </span>
                          </div>
                          <div className="button-new float-right">
                          <button className="btn-submit-profile">
                            <span>                           
                              {t('updatePassword')}
                            </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      
                    </form>
                  </div>
                </div>
              }
            </Popup>
          </React.Fragment>
        );
    }
}

export default withTranslation('profile')(FormChangePass);