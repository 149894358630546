import React, { Component } from "react";
import SelectCustom from "../../component/common/SelectCustom";
import { getTakenData, getDataByID } from "../../services/base_services";
import {
  GET_CATEGORY_PRODUCT_URL,
  GET_BLOCK_FOLLOW_CATEGORY_URL,
  GET_BUILDING_BLOCK,
  GET_FLOOR_BUILDING,
  LIST_DISTRIBUTOR,
  GET_PHASE_ALL_URL,
  GET_BLOCK_ALL_URL,
  GET_BUILDING_ALL_URL,
  GET_FLOOR_ALL_URL,
} from "../../constants/Config";
import { getListCartDVPP, updateRequest } from "../../actions/cart";
import { Checkbox } from "../../component/common";
import ButtonSave from "../../component/common/ButtonSave";
import Paginate from "../../component/Paginate/index";
import { showMessage } from "../../helpers/table";
import { Link, withRouter } from "react-router-dom";
import Alert from "../../component/common/Alert";
import { getError } from "../../helpers/error";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { withTranslation } from "react-i18next";

class TableAdd extends Component {
  constructor(props) {
    super(props);
    this.search = {};
    this.list_product_id = [];
    this.state = {
      FilterStatus: false,
      openBlock: false,
      dataCategory: [],
      dataBlock: [],
      dataBuilding: [],
      dataFloor: [],
      data: [],
      isLoading: false,
      product_id: null,
      listDvpp: [],
      paginate: [],
      page: 1,
      listPhase: [],
      stage_sale_id: null,
      errors: [],
    };
  }

  componentDidMount() {
    this.loadPhaseList();
    this.loadDataFilter();
    this.loadListCart(this.search, {
      page: this.state.page,
      per_page: 10,
      saler_id: 0,
    });
    this.loadSelectDVPP();
    if (this.props.user.settings[0].status === 2) {
      this.loadBlockAll();
    }
    if (this.props.user.settings[1].status === 2) {
      this.loadBuildingAll();
    }
    if (this.props.user.settings[2].status === 2) {
      this.loadFloorAll();
    }
  }

  loadBlockAll = () => {
    getTakenData(GET_BLOCK_ALL_URL).then((res) =>
      this.setState({
        dataBlock: res.data.data,
      })
    );
  };

  loadBuildingAll = () => {
    getTakenData(GET_BUILDING_ALL_URL).then((res) =>
      this.setState({
        dataBuilding: res.data.data,
      })
    );
  };

  loadFloorAll = () => {
    getTakenData(GET_FLOOR_ALL_URL).then((res) =>
      this.setState({
        dataFloor: res.data.data,
      })
    );
  };

  loadPhaseList = async () => {
    await getTakenData(GET_PHASE_ALL_URL).then((res) => {
      this.setState({
        listPhase: res.data.data,
      });
    });
  };

  loadSelectDVPP = () => {
    getTakenData(LIST_DISTRIBUTOR).then((res) =>
      this.setState({
        listDvpp: res.data.data,
      })
    );
  };

  loadDataFilter = () => {
    getTakenData(GET_CATEGORY_PRODUCT_URL).then((res) =>
      this.setState({
        dataCategory: res.data.data,
      })
    );
  };

  onChangeCategory = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;

    getDataByID(GET_BLOCK_FOLLOW_CATEGORY_URL, value).then((res) =>
      this.setState({
        dataBlock: res.data.data,
      })
    );
    this.setState({
      dataBlock: [],
      dataBuilding: [],
      dataFloor: [],
    });
    this.search = {
      ...this.search,
      [name]: value,
      block_id: null,
      building_id: null,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataBlock: [],
        dataBuilding: [],
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        block_id: null,
        building_id: null,
        floor_id: null,
      };
    }
    this.loadListCart(this.search, {
      page: this.state.page,
      per_page: 10,
      saler_id: 0,
    });
  };

  onChangeBlock = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;

    getDataByID(GET_BUILDING_BLOCK, value).then((res) =>
      this.setState({
        dataBuilding: res.data.data,
      })
    );
    this.setState({
      dataBuilding: [],
      dataFloor: [],
    });
    this.search = {
      ...this.search,
      [name]: value,
      building_id: null,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataBuilding: [],
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        building_id: null,
        floor_id: null,
      };
    }

    this.loadListCart(this.search, {
      page: this.state.page,
      per_page: 10,
      saler_id: 0,
    });
  };

  onChangeBuilding = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    getDataByID(GET_FLOOR_BUILDING, value).then((res) =>
      this.setState({
        dataFloor: res.data.data,
      })
    );

    this.setState({
      dataFloor: [],
    });

    this.search = {
      ...this.search,
      [name]: value,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        floor_id: null,
      };
    }

    this.loadListCart(this.search, {
      page: this.state.page,
      per_page: 10,
      saler_id: 0,
    });
  };

  onChangeFloor = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.search = {
      ...this.search,
      [name]: value,
    };

    this.loadListCart(this.search, {
      page: this.state.page,
      per_page: 10,
      saler_id: 0,
    });
  };

  loadListCart = (search, params) => {
    this.setState({
      isLoading: true,
    });
    getListCartDVPP(search, params)
      .then((res) => {
        this.setState({
          data: res.data.data,
          paginate: res.data.paginate,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isLoading: false,
        });
      });
  };

  changePage = (pageNumber, e) => {
    let checkboxes = document.getElementsByName("productid[]");
    let checkboxesAll = document.getElementsByName("name[]");
    checkboxesAll[0].checked = false;
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
    this.list_product_id = [];
    let { page } = this.state;
    if (pageNumber != page) {
      this.loadListCart(this.search, {
        page: pageNumber,
        per_page: 10,
        saler_id: 0,
      });
      this.setState({ page: pageNumber });
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    let value = parseInt(target.value);
    if (target.checked) {
      this.list_product_id.push(value);
    } else {
      this.list_product_id.splice(this.list_product_id.indexOf(value), 1);
    }
  };

  checkAll = (e) => {
    const target = e.target;
    let checkboxes = document.getElementsByName("productid[]");
    if (target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        this.list_product_id.push(parseInt(checkboxes[i].value));
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      this.list_product_id = [];
    }
  };

  onSave = () => {
    let { id } = this.props.match.params;
    const formData = {
      status: 2,
      product_id: this.list_product_id,
      distributor_id: id,
      stage_sale_id: this.state.stage_sale_id,
    };
    if (id) {
      updateRequest(formData)
        .then((res) => {
          showMessage(res.data.message, true);
          this.props.history.push("/cart");
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
  };

  onChangePhase = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.setState({
      stage_sale_id: value,
    });
  };

  render() {
    let {
      dataCategory,
      dataBlock,
      dataBuilding,
      dataFloor,
      data,
      paginate,
      listPhase,
      errors,
    } = this.state;
    let { t, user } = this.props;
    let settings = user?.settings;

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6">
              <h3 className="title-cart-top">{t('cartForDistributor')}</h3>
            </div>
            <div className="col-md-6 d-flex">
              <div className="ml-auto d-flex">
                <Link to="/cart">
                  <ButtonSave text={t('cancel')} />
                </Link>
                <ButtonSave onClick={this.onSave} text={t('save')} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <SelectCustom
                className="form-control"
                classNameCustom="form-control-custom-cart-title"
                name="stage_sale_id"
                ref="stage_sale_id"
                label={t('openingPeriod')}
                include_blank={t('openingPeriod')}
                key_value="id"
                key_label="name"
                data={listPhase}
                errors={errors}
                onChange={(e) => this.onChangePhase(e)}
              />
            </div>
            {settings?.[0]?.status === 1 && (
              <div className="col-md-2">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="category_block_id"
                  ref="category_block_id"
                  label={t('realestateType')}
                  include_blank={t('realestateType')}
                  key_value="id"
                  key_label="name"
                  data={dataCategory}
                  errors={[]}
                  onChange={(e) => this.onChangeCategory(e)}
                />
              </div>
            )}
            {settings?.[1]?.status === 1 && (
              <div className="col-md-2">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="block_id"
                  ref="block_id"
                  label="Block"
                  include_blank="Block"
                  key_value="id"
                  key_label="name"
                  data={dataBlock}
                  errors={[]}
                  onChange={(e) => this.onChangeBlock(e)}
                />
              </div>
            )}
            {settings?.[2]?.status === 1 && (
              <div className="col-md-2">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="building_id"
                  ref="building_id"
                  label={t('building')}
                  include_blank={t('building')}
                  key_value="id"
                  key_label="name"
                  data={dataBuilding}
                  errors={[]}
                  onChange={(e) => this.onChangeBuilding(e)}
                />
              </div>
            )}
            <div className="col-md-2">
              <SelectCustom
                className="form-control"
                classNameCustom="form-control-custom-cart-title"
                name="floor_id"
                ref="floor_id"
                label={t('floor')}
                include_blank={t('floor')}
                key_value="id"
                key_label="name"
                data={dataFloor}
                errors={[]}
                onChange={(e) => this.onChangeFloor(e)}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="table-custom-maintenance">
                <div className="form-group">
                  <Alert messsage={getError(errors, "product_id")} />
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          errors={[]}
                          label=""
                          name="name[]"
                          id="all"
                          multiple={true}
                          defaultChecked={false}
                          onChange={this.checkAll}
                        />
                      </th>
                      <th className="text-left">{t('productCode')}</th>
                      <th className="text-left">{t('productType')}</th>
                      <th className="text-left">{t('productDistributor')}</th>
                      <th>{t('productSellPrice')}</th>
                      <th>{t('productStatus')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => {
                      return (
                        <tr>
                          <td className="text-center">
                            <Checkbox
                              errors={[]}
                              id={`project_id_${item.id}`}
                              refs={`project_id_${item.id}`}
                              label=""
                              value={item.id}
                              onChange={this.handleInputChange}
                              name="productid[]"
                            />
                          </td>
                          <td>{item?.product_code || "--"}</td>
                          <td>{item?.template_product?.name || "--"}</td>
                          <td>{item?.distributor?.name || "--"}</td>
                          <td className="text-center">
                            {item.current_price || "--"}
                          </td>
                          <td className="text-center">
                            <span
                              className={`block-status-cart block-status-cart-custom-table mr-0`}
                              data-tip={item.status_lable}
                            >
                              <span className="title-status">
                                {item.status_lable}
                              </span>
                              <span
                                style={{ backgroundColor: item.color }}
                                className="color-status"
                              ></span>
                            </span>
                          </td>
                          <ReactTooltip />
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user?.user,
  };
};

export default connect(mapStateToProps, null)(withTranslation('cart')(withRouter(TableAdd)));
