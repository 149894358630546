import React from "react";

const CheckboxRowCustom = React.forwardRef((props, ref) => {
  let { errors, id, label, wrapperClass, className, ...input } = props;
  return (
    <label
      className={`checkbox-custom-control checkbox-custom-control-${className}`}
    >
      {label}
      <input className="checkmark-input" {...input} type="checkbox" ref={ref} />
      <span className="checkmark-label"></span>
    </label>
  );
});

export default CheckboxRowCustom;
