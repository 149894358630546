import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import Table from "../../component/common/Table";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import { getFormDataFromRef } from "../../helpers/form";
import {
  GET_STATUS_CATE_URL,
  CREATE_STATUS_CATE_URL,
  UPDATE_STATUS_CATE_URL,
  DELETE_STATUS_CATE_URL,
} from "../../constants/Config";
import {
  deleteById,
  postData,
  putData,
  getTakenData,
} from "../../services/base_services";
import { confirmDelete, showMessage } from "./../../helpers/table";
import { destructServerErrors } from "../../helpers/error";
import { PER_PAGE } from "../../constants/Variable";
import ButtonSave from '../../component/common/ButtonSave'
import { ChromePicker } from 'react-color'
import { withTranslation } from "react-i18next";

class ProductStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      perPage: PER_PAGE,
      paginate: [],
      errors: [],
      background: "#fff",
      tableData: [],
      tableHeaders: [
        {
          label: "Stt",
          index: "stt",
          option: {
            className: "text-center",
          },
        },
        {
          label: "name",
          index: "name",
        },
        {
          label: "productStatusColor",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <span style={ {backgroundColor: data && data.color} } className="block-color-status"></span>
            );
          },
        },
        {
          label: "functions",
          index: null,
          option: {
            className: "text-center fixed-collumn",
          },
          callback: (data) => {
            if (data.id) {
              return (
                <div className="btn-group">
                  <span
                    data-tip={this.props.t('edit')}
                    className="link-action mr-2"
                  >
                    <img
                      src={editIcon}
                      className="btn-icon"
                      onClick={() => this.selectItem(data)}
                    />
                  </span>
                  <span data-tip={this.props.t('delete')} className="link-action ml-2">
                    <img
                      src={deleteIcon}
                      className="btn-icon"
                      onClick={() => this.deleteStatus(data.id)}
                    />
                  </span>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.loadStatusList();
    setTimeout(() => {
      this.setState({});
    }, 3000);
  }

  selectItem = (data) => {
    this.setState({
      item: data,
      background: data.color
    });
  };

  deleteStatus = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeStatusRequest(id);
      }
    });
  };

  removeStatusRequest = (id) => {
    deleteById(DELETE_STATUS_CATE_URL, id)
      .then((res) => {
        showMessage(this.props.t('productStatusDeleteSuccess'), true);
        this.loadStatusList();
      })
      .catch((err) => {
        showMessage(this.props.t('responseFail'), false);
      });
  };

  loadStatusList = async () => {
    await getTakenData(GET_STATUS_CATE_URL).then((res) => {
      this.setState({
        tableData: res.data.data,
      });
    });
  };

  closeEdit = () => {
    document.getElementById("form-product").reset();
    this.setState({
      item: null,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData.color = this.state.background;
    const { item } = this.state;
    if (item === null) {
      postData(CREATE_STATUS_CATE_URL, formData)
        .then((res) => {
          showMessage(this.props.t('productStatusCreateSuccess'), true);
          this.loadStatusList();
          this.setState({
            item: null,
            background: "#fff",
            errors: [],
          });
          document.getElementById("form-product").reset();
        })
        .catch((err) => {
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        });
    } else {
      putData(UPDATE_STATUS_CATE_URL, item.id, formData)
        .then((res) => {
          showMessage(this.props.t('productStatusUpdateSuccess'), true);
          this.loadStatusList();
          this.setState({
            item: null,
            background: "#fff",
            errors: [],
          });
          document.getElementById("form-product").reset();
        })
        .catch((err) => {
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        });
    }
  };

  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };

  render() {
    let { tableHeaders, item, errors, tableData } = this.state;
    const { t } = this.props;
    tableHeaders = tableHeaders.map(item => ({...item, label: t(item.label)}));

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col top-table-title">
              <p>{t('productStatusTitle')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <form className="form-product">
                <p className="form-product-title text-center">
                  {t('productStatusList')}
                </p>

                <Table
                  fixed={true}
                  tableHeaders={tableHeaders}
                  tableData={tableData}
                  tablePaginate={{}}
                  isLoading={false}
                />
              </form>
            </div>
            <div className="col-md-5">
              <form
                className="form-product"
                id="form-product"
                onSubmit={(e) => this.submitForm(e)}
              >
                <p className="form-product-title text-center">
                  {item === null ? t('newInformation') : t('updateInformation')}
                </p>
                <div className="form-product-body px-5">
                  <p className="form-product-body-name">{t('productStatusName')}:</p>
                  <InputText
                    className="form-control input-form form-control-product "
                    placeholder={t('productStatusName')}
                    name="name"
                    ref="name"
                    errors={errors}
                    defaultValue={item && item.name}
                  />
                  <p className="form-product-body-name">{t('productStatusColor')}:</p>
                  <ChromePicker
                    color={this.state.background}
                    onChangeComplete={this.handleChangeComplete}
                    width="100%"
                  />
                  <div className="button-confirm  text-center pt-5 pb-5">
                    <span className="" onClick={(e) => this.submitForm(e)}>
                      {item === null ? t('save') : t('updated')}
                    </span>
                  </div>
                  <div className="button-confirm  text-center  pb-5">
                    {item === null ? (
                      ""
                    ) : (
                      <span className="btn-delete-custom" onClick={() => this.closeEdit()}>
                        {t('cancel')}
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('product')(ProductStatus);
