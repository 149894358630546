import React, { Component } from "react";
import Popup from "reactjs-popup";
import MaskAcreage from "./../../assets/images/icons/MaskAcreage.png";
import MaskBathroom from "./../../assets/images/icons/MaskBathroom.png";
import MaskBedroom from "./../../assets/images/icons/MaskBedroom.png";
import MaskBlock from "./../../assets/images/icons/MaskBlock.png";
import MaskBuilding from "./../../assets/images/icons/MaskBuilding.png";
import MaskDirection from "./../../assets/images/icons/MaskDirection.png";
import MaskPrice from "./../../assets/images/icons/MaskPrice.png";
import { getDetailCart, changeStatusCartRequest } from "../../actions/cart";
import { moneyFormat } from "../../helpers/table";
import InputCustomer from "../../component/common/InputCustomer";
import { LIST_SEARCH_CUSTOMER_URL } from "../../constants/Config";
import { showMessage } from "../../helpers/table";
import Select from "../../component/common/Select";
import TextArea from "../../component/common/TextArea";
import { getFormDataFromRef } from "../../helpers/form";
import { withTranslation } from "react-i18next";

class DetailBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.open,
      data: {},
      errors: {},
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open != prevState.open) {
      if (nextProps.open) {
      }
      return { open: nextProps.open };
    } else return null;
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ open: true });
  };

  closeModal = (e) => {
    this.props.detailBlock(e, false);
    this.setState({ open: false });
  };

  componentDidMount = async () => {
    let id = this.props.product_id;
    if (id) {
      getDetailCart(id)
        .then((res) => {
          this.setState({ data: res.data.data });
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
  };

  onSubmitDetail = (e) => {
    e.preventDefault();
    let formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      customer_id: this.customer_id,
      product_id: this.props.product_id,
      description: this.description,
    };
    changeStatusCartRequest(formData)
      .then((res) => {
        this.closeModal(e);
        showMessage(this.props.t('updateCartStatusSuccess'), true);
        this.props.onCloseForm();
      })
      .catch((err) => {
        this.setState({ errors: err.err });
      });
  };

  onChangeCustomer = (customer_id) => {
    this.customer_id = customer_id;
  };

  onChangeTextArea = (data) => {
    this.description = data;
  };

  render() {
    const { open, data, errors } = this.state;
    const { t } = this.props;

    return (
      <div className="custom-popup custom-popup-cart">
        <Popup open={open} modal closeOnDocumentClick={false}>
          <div className="modal-custom-cart">
            <div className="popup-header">
              <p className="text-in-block text-in-block-title">
                {t('productDetail')}
              </p>
            </div>
            <div className="popup-body">
              <div className="row">
                <div className="col-6">
                  <p className="text-in-block">
                    <img src={MaskBlock} className="mr-2" />
                    Block: {data && data.block && data.block.name}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-in-block">
                    <img src={MaskBuilding} className="mr-2" />
                    Building: {data && data.building && data.building.name}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-in-block">
                    <img src={MaskAcreage} className="mr-2" />
                    {t('productArea')}:{" "}
                    {data &&
                      data.template_product &&
                      data.template_product.area_inside}{" "}
                    M²
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-in-block">
                    <img src={MaskPrice} className="mr-2" />
                    {t('productPrice')}:{" "}
                    {moneyFormat(
                      data &&
                        data.template_product &&
                        data.template_product.price
                    )}{" "}
                    VNĐ
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-in-block">
                    <img src={MaskDirection} className="mr-2" />
                    {t('productDirection')}:{" "}
                    {data &&
                      data.template_product &&
                      data.template_product.direction &&
                      data.template_product.direction.name}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-in-block">
                    <img src={MaskBedroom} className="mr-2" />
                    {t('productBedroom')}:{" "}
                    {data &&
                      data.template_product &&
                      data.template_product.number_bedroom}
                  </p>
                </div>
                <div className="col-6">
                  <p className="text-in-block">
                    <img src={MaskBathroom} className="mr-2" />
                    {t('productBathroom')}:{" "}
                    {data &&
                      data.template_product &&
                      data.template_product.number_bathroom}
                  </p>
                </div>
              </div>
            </div>
            <div className="popup-footer">
              <form
                className="form-finance"
                onSubmit={(e) => this.onSubmitDetail(e)}
              >
                <div className="row">
                  <div className="col-md-6">
                    <Select
                      className="form-control input-form"
                      name="status"
                      ref="status"
                      label={`${t('productStatus')}:`}
                      include_blank={t('productStatus')}
                      key_value="id"
                      key_label="name"
                      data={this.props.listStatus}
                      errors={errors}
                      defaultValue={{
                        id: data?.status,
                        name: data?.status_lable,
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputCustomer
                      errors={errors}
                      label={`${t('customerSearch')}(*):`}
                      name="customer_id"
                      className="form-control input-form"
                      placeholder={t('customerSearch')}
                      source={LIST_SEARCH_CUSTOMER_URL}
                      onChange={this.onChangeCustomer}
                      default_value={data?.customer}
                    />
                  </div>
                  <div className="col-md-12">
                    <TextArea
                      name="description"
                      ref="description"
                      label={`${t('productDescription')}:`}
                      className="text-area-custom text-area-custom-cart"
                      errors={errors}
                      rows="3"
                      onChange={this.onChangeTextArea}
                      defaultValue={data?.order_description}
                    />
                  </div>
                </div>

                <div className="text-center">
                  <a
                    className="btn btn-cart"
                    onClick={(e) => this.closeModal(e)}
                  >
                    {t('cancel')}
                  </a>
                  <button
                    className="btn btn-cart"
                    onClick={(e) => this.onSubmitDetail(e)}
                  >
                    {t('submit')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default withTranslation('cart')(DetailBlock);
