import React from "react";
import { DonutMultiple, DonutElement, DonutLabel } from "react-donut-component";
import { Link } from "react-router-dom";
import { PieChart, Pie, Cell, Label } from "recharts";
import { moneyFormat } from "../../helpers/table";
import { useTranslation } from "react-i18next";

const BlockCharCircle = (props) => {
  const {
    FinanceStatistic,
    DistributorStatistic,
    SalerStatistic,
    position_id,
  } = props;
  const { t } = useTranslation('dashboard');

  const data = [
    { name: "Group A", value: FinanceStatistic?.number_house_sold || 0 },
    { name: "Group B", value: FinanceStatistic?.discount_price || 0 },
    { name: "Group C", value: FinanceStatistic?.deposit || 0 },
    { name: "Group D", value: FinanceStatistic?.receivables || 0 },
  ];

  const COLORS = ["#f34336", "#fed518", "#00b382", "#008ef9"];

  return (
    <div className="block-char-circle row mr-0 ml-0">
      <div className="col-6 block-char-circle-left block-table-list">
        <div className="block-title block-title-custom">
          <p className="title-list">{t('sales')}</p>
        </div>
        <div className="block-char-detail">
          <div className="row">
            <div className="col-6">
              <div className="block-left">
                <PieChart width={300} height={300}>
                  <Pie
                    data={data}
                    cx={120}
                    cy={120}
                    innerRadius={80}
                    outerRadius={100}
                    fill="red"
                    dataKey="value"
                  >
                    {data.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <Label
                      width="150px"
                      value={`${moneyFormat(
                        FinanceStatistic?.number_house_sold +
                          FinanceStatistic?.discount_price +
                          FinanceStatistic?.deposit +
                          FinanceStatistic?.receivables
                      )} VNĐ`}
                      position="center"
                      fill="#4d4f5c"
                      className="title-total"
                    ></Label>
                  </Pie>
                </PieChart>
              </div>
            </div>
            <div className="col-6">
              <div className="block-right">
                <div className="item-right">
                  <p className="title">
                    <span className="icon-circle icon-circle-1"></span>{t('totalSales')}
                  </p>
                  <p className="money">
                    {moneyFormat(FinanceStatistic?.number_house_sold) || 0} VNĐ
                  </p>
                </div>
                <div className="item-right">
                  <p className="title">
                    <span className="icon-circle icon-circle-2"></span>{t('discount')}
                  </p>
                  <p className="money">
                    {moneyFormat(FinanceStatistic?.discount_price) || 0} VNĐ
                  </p>
                </div>
                <div className="item-right">
                  <p className="title">
                    <span className="icon-circle icon-circle-3"></span>{t('deposit')}
                  </p>
                  <p className="money">
                    {moneyFormat(FinanceStatistic?.deposit) || 0} VNĐ
                  </p>
                </div>
                <div className="item-right">
                  <p className="title">
                    <span className="icon-circle icon-circle-4"></span>{t('totalReceivable')}
                  </p>
                  <p className="money">
                    {moneyFormat(FinanceStatistic?.receivables) || 0} VNĐ
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6 block-char-circle-right block-table-list">
        {position_id !== 2 ? (
          <>
            <div className="block-title block-title-custom">
              <p className="title-list">
                {position_id !== 1 ? t('distributionUnit') : t('sale')}
              </p>
            </div>
            <div className="block-list">
              <div className="table-dashboard-maintenance">
                {position_id !== 1 ? (
                  <table className="table table-char-circle">
                    <thead>
                      <tr>
                        <th className="text-left">{t('name')}</th>
                        <th>{t('soldQty')}</th>
                        <th>{t('totalApartment')}</th>
                        <th>{t('revenue')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {DistributorStatistic?.map((item, index) => {
                        return (
                          <tr>
                            <td>{item?.name}</td>
                            <td className="text-center">
                              {item?.number_house}
                            </td>
                            <td className="text-center">
                              {item?.total_house_sold}
                            </td>
                            <td className="text-center">
                              {moneyFormat(item.revenue)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <table className="table table-char-circle">
                    <thead>
                      <tr>
                        <th>{t('name')}</th>
                        <th>{t('customer')}</th>
                        <th>{t('totalApartment')}</th>
                        <th>{t('revenue')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {SalerStatistic?.map((item, index) => {
                        return (
                          <tr>
                            <td>{item?.full_name}</td>
                            <td className="text-center">
                              {item?.number_customer}
                            </td>
                            <td className="text-center">
                              {item?.number_product_sold}
                            </td>
                            <td className="text-center">
                              {moneyFormat(item.total_revenue)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="text-center">
                <Link to="/distributor" className="view-more-detail">
                  {t('viewMore')}
                </Link>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default BlockCharCircle;
