import { useCallback } from "react";
import { useTranslation } from "react-i18next"

export const useLanguage = () => {
  const { i18n } = useTranslation();
  const onChangeLanguage  = useCallback((locale) => {
    i18n.changeLanguage(locale);
    window.localStorage.setItem('locale', locale);
  }, [i18n]);


  return [i18n.language, i18n.languages, onChangeLanguage];
}