import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import PerPage from "../../component/Paginate/PerPage";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserRequest } from "../../actions/user";
import Message from "../../component/common/Message";
import { DELETE_USER_URL } from "../../constants/Config";
import { deleteById } from "../../services/base_services";
import { confirmDelete, afterRemove, showMessage } from "../../helpers/table";
import InputSearch from "../../component/common/InputSearch";
import NewButton from "../../component/common/NewButton";
import Can from "../../services/Can";
import { PER_PAGE } from "../../constants/Variable";
import ButtonSmall from "../../component/common/ButtonSmall";
class AccountTable extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.message = null;
    this.state = {
      perPage: PER_PAGE,
      show_message: true,
      paginate: [],
      tableHeaders: [
        {
          label: "Stt",
          index: "stt",
          option: {
            className: "text-center position-relative",
          },
          callback: null,
        },
        {
          label: "Tên người dùng",
          index: null,
          callback: (data) => {
            return (
              <div>
                <span>{data && data.fullname}</span>
              </div>
            );
          },
        },
        {
          label: "Số điện thoại",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.phone}</span>
              </div>
            );
          },
        },
        ,
        {
          label: "Email",
          index: null,
          callback: (data) => {
            return (
              <div>
                <span>{data && data.email}</span>
              </div>
            );
          },
        },
        ,
        {
          label: "Phân quyền",
          index: null,
          callback: (data) => {
            return (
              <div>
                <span>{data && data.role && data.role.name}</span>
              </div>
            );
          },
        },
        ,
        {
          label: "Trạng thái",
          index: null,
          callback: (data) => {
            return (
              <div>
                <span></span>
              </div>
            );
          },
        },
        {
          label: "Chức năng",
          index: null,
          option: {
            className: "text-center fixed-collumn",
          },
          callback: (data) => {
            if (data.id && data.is_admin === 0) {
              return (
                <div className="btn-group">
                  <Can I="user.edit" a="users">
                    <span
                      data-tip="Chỉnh sửa người dùng"
                      className="link-action mr-2"
                    >
                      <img
                        src={editIcon}
                        className="btn-icon"
                        onClick={() => this.editUser(data.id)}
                      />
                    </span>
                  </Can>
                  <Can I="user.delete" a="users">
                    <span
                      data-tip="Xóa người dùng"
                      className="link-action ml-2"
                    >
                      <img
                        src={deleteIcon}
                        className="btn-icon"
                        onClick={() => this.onDeleteUser(data.id)}
                      />
                    </span>
                  </Can>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
          },
        },
      ],
    };
  }

  editUser = (id) => {
    this.props.history.push(`/account/${id}/edit`);
  };

  onDeleteUser = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeUserRequest(id);
      }
    });
  };

  removeUserRequest = (id) => {
    deleteById(DELETE_USER_URL, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.loadUser();
      })
      .catch((err) => {
        showMessage(err.data.message, false);
      });
  };

  componentDidMount() {
    this.loadUser();
    this.setState({
      show_message: false,
    });
  }

  loadUser = () => {
    let { page, perPage } = this.state;
    this.props.getUser(this.search, { page: page, per_page: perPage });
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getUser(this.search, { page: pageNumber, per_page: perPage });
      this.setState({ page: pageNumber });
    }
  };

  UNSAFE_componentWillMount() {
    let { location, history } = this.props;

    if (location.state && location.state.message) {
      this.message = location.state.message;
      history.replace({
        pathname: location.pathname,
        state: {},
      });
    }
  }

  onChangeSearch = (e) => {
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";

    this.search = {
      ...this.search,
      [name]: value,
    };

    this.props.getUser(this.search, { page: page, per_page: perPage });
  };

  render() {
    let { user, isLoading } = this.props;
    let tableData = user && user.data;
    let paginate = user && user.paginate;
    let { tableHeaders, perPage } = this.state;

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-4 top-table-title">
              <p>Danh Sách Người Dùng</p>
            </div>
            <div className="col-md-8 d-flex">
              <div className="ml-auto d-flex">
                <InputSearch onChange={this.onChangeSearch} />
                <Can I="user.add" a="users">
                  <Link to="/account/new" className="btn-add-custom ml-2">
                    <ButtonSmall label="Thêm mới" />
                  </Link>
                </Can>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <Table
                fixed={true}
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
    isLoading: state.user.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getUser: (data, params) => dispatch(getUserRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountTable));
