import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import InputSearch from "../../component/common/InputSearch";
import SelectCustom from "../../component/common/SelectCustom";
import DetailBlock from "./DetailBlock";
import {
  getTakenData,
  getDataByID,
  postData,
} from "../../services/base_services";
import {
  GET_CATEGORY_PRODUCT_URL,
  GET_BLOCK_FOLLOW_CATEGORY_URL,
  GET_BUILDING_BLOCK,
  GET_FLOOR_BUILDING,
  LIST_DISTRIBUTOR,
  LIST_SALE,
  REVOKE_PRODUCT_URL,
  GET_BLOCK_ALL_URL,
  GET_BUILDING_ALL_URL,
  GET_FLOOR_ALL_URL,
  GET_STATUS_CATE_URL_CUSTOM,
  GET_PHASE_ALL_URL,
  DIREICTION_PRODUCT,
} from "../../constants/Config";
import { getListCart } from "../../actions/cart";
import loading from "./../../assets/images/loading2.gif";
import { Filter } from "react-feather";
import FilterCart from "./FilterCart";
import ButtonSave from "../../component/common/ButtonSave";
import { connect } from "react-redux";
import revokeIcon from "../../assets/images/icons/revoke-icon.svg";
import { confirmFinish, showMessage } from "../../helpers/table";
import ReactTooltip from "react-tooltip";
import Paginate from "../../component/Paginate/index";
import { withTranslation } from "react-i18next";

class CartTable extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.state = {
      FilterStatus: false,
      openBlock: false,
      dataCategory: [],
      dataBlock: [],
      dataBuilding: [],
      dataFloor: [],
      data: [],
      isLoading: false,
      product_id: null,
      listDvpp: [],
      listSale: [],
      distributorId: null,
      checkRevoke: false,
      listStatus: [],
      idStatus: null,
      listPhase: [],
      listDirection: [],
      page: 1,
      perPage: 60,
      paginate: [],
    };
  }

  componentDidMount() {
    this.loadDataFilter();
    this.loadListCart(this.search, {
      page: this.state.page,
      per_page: this.state.perPage,
    });
    this.loadSelectDVPP();
    this.loadSelectSale();
    this.loadStatusList();
    this.loadPhaseList();
    this.loadDirectionList();
    if (this.props.user.settings[0].status === 2) {
      this.loadBlockAll();
    }
    if (this.props.user.settings[1].status === 2) {
      this.loadBuildingAll();
    }
    if (this.props.user.settings[2].status === 2) {
      this.loadFloorAll();
    }
  }

  loadDirectionList = async () => {
    await getTakenData(DIREICTION_PRODUCT).then((res) => {
      this.setState({
        listDirection: res.data.data,
      });
    });
  };

  loadPhaseList = async () => {
    await getTakenData(GET_PHASE_ALL_URL).then((res) => {
      this.setState({
        listPhase: res.data.data,
      });
    });
  };

  loadStatusList = async () => {
    await getTakenData(GET_STATUS_CATE_URL_CUSTOM).then((res) => {
      this.setState({
        listStatus: res.data,
      });
    });
  };

  loadBlockAll = () => {
    getTakenData(GET_BLOCK_ALL_URL).then((res) =>
      this.setState({
        dataBlock: res.data.data,
      })
    );
  };

  loadBuildingAll = () => {
    getTakenData(GET_BUILDING_ALL_URL).then((res) =>
      this.setState({
        dataBuilding: res.data.data,
      })
    );
  };

  loadFloorAll = () => {
    getTakenData(GET_FLOOR_ALL_URL).then((res) =>
      this.setState({
        dataFloor: res.data.data,
      })
    );
  };

  loadSelectDVPP = () => {
    getTakenData(LIST_DISTRIBUTOR).then((res) =>
      this.setState({
        listDvpp: res.data.data,
      })
    );
  };

  loadSelectSale = () => {
    getTakenData(LIST_SALE).then((res) =>
      this.setState({
        listSale: res.data.data,
      })
    );
  };

  loadDataFilter = () => {
    getTakenData(GET_CATEGORY_PRODUCT_URL).then((res) =>
      this.setState({
        dataCategory: res.data.data,
      })
    );
  };

  // open detail blocks
  detailBlock = (e, status, id) => {
    e.preventDefault();
    this.setState({ openBlock: status, product_id: id });
  };

  onChangeSearch = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value ? target.value : "";
    this.search = {
      ...this.search,
      [name]: value,
    };

    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  onChangeCategory = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;

    getDataByID(GET_BLOCK_FOLLOW_CATEGORY_URL, value).then((res) =>
      this.setState({
        dataBlock: res.data.data,
      })
    );
    this.setState({
      dataBlock: [],
      dataBuilding: [],
      dataFloor: [],
    });
    this.search = {
      ...this.search,
      [name]: value,
      block_id: null,
      building_id: null,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataBlock: [],
        dataBuilding: [],
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        block_id: null,
        building_id: null,
        floor_id: null,
      };
    }
    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  onChangeBlock = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;

    getDataByID(GET_BUILDING_BLOCK, value).then((res) =>
      this.setState({
        dataBuilding: res.data.data,
      })
    );
    this.setState({
      dataBuilding: [],
      dataFloor: [],
    });
    this.search = {
      ...this.search,
      [name]: value,
      building_id: null,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataBuilding: [],
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        building_id: null,
        floor_id: null,
      };
    }

    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  onChangeBuilding = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    getDataByID(GET_FLOOR_BUILDING, value).then((res) =>
      this.setState({
        dataFloor: res.data.data,
      })
    );

    this.setState({
      dataFloor: [],
    });

    this.search = {
      ...this.search,
      [name]: value,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        floor_id: null,
      };
    }

    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  onChangeFloor = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.search = {
      ...this.search,
      [name]: value,
    };

    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  loadListCart = (search, params) => {
    this.setState({
      isLoading: true,
    });
    getListCart(search, params)
      .then((res) => {
        this.setState({
          data: res?.data?.data,
          isLoading: false,
          paginate: res?.data?.paginate,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isLoading: false,
        });
      });
  };

  onCloseForm = () => {
    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  showFilter = () => {
    let { FilterStatus } = this.state;
    this.setState({ FilterStatus: !FilterStatus });
  };

  onFilterListCart = (
    dataBedRoom,
    dataBathRoom,
    dataArea,
    dataPrice,
    dataDirection
  ) => {
    this.setState({
      dataBedRoom,
      dataBathRoom,
      dataArea: dataArea,
      dataPrice: dataPrice,
      dataDirection,
    });
    const filterParams = {
      area_inside: dataArea,
      number_bedroom: dataBedRoom.map((item) => parseInt(item)),
      number_bathroom: dataBathRoom.map((item) => parseInt(item)),
      price: dataPrice,
      direction_id: dataDirection,
    };
    this.loadListCart(filterParams, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  onChangeDistributor = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.search = {
      ...this.search,
      [name]: value,
    };
    this.setState({
      distributorId: value,
    });
    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  onRevoke = () => {
    this.setState({
      checkRevoke: !this.state.checkRevoke,
    });
  };

  onHandleRevoke = (e, id) => {
    e.stopPropagation();
    const data = {
      product_id: id,
      status: 1,
    };
    confirmFinish("Thu hồi sản phẩm").then((res) => {
      if (res.value == true) {
        this.revokeProduct(data);
      }
    });
  };

  revokeProduct = (data) => {
    postData(REVOKE_PRODUCT_URL, data)
      .then((res) => {
        showMessage(res.data.message, true);
        this.loadListCart(this.search, {
          page: this.state.page,
          per_page: this.state.perPage,
        });
      })
      .catch((err) => {
        showMessage(err.data.message, false);
      });
  };

  onFilterStatus = (id) => {
    if (this.state.idStatus === id) {
      this.search = {
        ...this.search,
        status: null,
      };
      this.setState({
        idStatus: null,
      });
    } else {
      this.search = {
        ...this.search,
        status: id,
      };
      this.setState({
        idStatus: id,
      });
    }
    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  onChangePhase = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.search = {
      ...this.search,
      [name]: value,
    };
    this.loadListCart(this.search, {
      page: 1,
      per_page: this.state.perPage,
    });
  };

  changePage = (pageNumber) => {
    this.loadListCart(this.search, {
      page: pageNumber,
      per_page: this.state.perPage,
    });
  };

  render() {
    let {
      openBlock,
      dataCategory,
      dataBlock,
      dataBuilding,
      dataFloor,
      data,
      isLoading,
      product_id,
      FilterStatus,
      listDvpp,
      distributorId,
      checkRevoke,
      listStatus,
      idStatus,
      listPhase,
      listDirection,
      paginate
    } = this.state;
    let { t, user } = this.props;
    let settings = user?.settings;
    let position_id = user?.position_id;

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6">
              <h3 className="title-cart-top">{t('masterMapManagement')}</h3>
            </div>
            <div className="col-md-6 d-flex">
              <div className="ml-auto d-flex">
                {distributorId && (position_id !== 2 || position_id !== 1) && (
                  <>
                    <ButtonSave
                      onClick={this.onRevoke}
                      text={`${checkRevoke ? t('cancelChange') : t('cartChange')}`}
                    />
                    <Link
                      to={`cart/add/${distributorId}`}
                      className="btn-add-custom mt-0"
                    >
                      <ButtonSave text={t('splitCart')} />
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-4">
            {openBlock ? (
              <DetailBlock
                open={openBlock}
                product_id={product_id}
                detailBlock={this.detailBlock}
                onCloseForm={this.onCloseForm}
                listStatus={listStatus}
              />
            ) : (
              ""
            )}
            <div className="col-md-4 search-form-cart">
              <InputSearch onChange={this.onChangeSearch} placeholder={t('search')} />
            </div>
            <div className="col-md-4">
              {(position_id !== 2 || position_id !== 1) && (
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart w-100"
                  name="distributor_id"
                  ref="distributor_id"
                  label=""
                  include_blank={t('allCart')}
                  key_value="id"
                  key_label="name"
                  data={listDvpp}
                  errors={[]}
                  onChange={(e) => this.onChangeDistributor(e)}
                />
              )}
            </div>
            <div className="col-md-2">
              <div className="cart-filters w-100">
                <div
                  className={`btn btn-filter d-flex align-items-center ${FilterStatus == true ? "active" : ""
                    }`}
                  onClick={this.showFilter}
                >
                  <Filter />
                  {t('filter')}
                </div>
                <FilterCart
                  data={data}
                  min={1}
                  max={100}
                  className={`${FilterStatus == true ? "show" : ""}`}
                  show={FilterStatus}
                  onHide={this.hideFilter}
                  onSubmitFilter={this.onFilterListCart}
                  listDirection={listDirection}
                />
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-4">
              <SelectCustom
                className="form-control"
                classNameCustom="form-control-custom-cart-title"
                name="stage_sale_id"
                ref="stage_sale_id"
                label={t('openingPeriod')}
                include_blank={t('openingPeriod')}
                key_value="id"
                key_label="name"
                data={listPhase}
                errors={[]}
                onChange={(e) => this.onChangePhase(e)}
              />
            </div>
            {settings?.[0]?.status === 1 && (
              <div className="col-md-2">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="category_block_id"
                  ref="category_block_id"
                  label={t('realestateType')}
                  include_blank={t('realestateType')}
                  key_value="id"
                  key_label="name"
                  data={dataCategory}
                  errors={[]}
                  onChange={(e) => this.onChangeCategory(e)}
                />
              </div>
            )}
            {settings?.[1]?.status === 1 && (
              <div className="col-md-2">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="block_id"
                  ref="block_id"
                  label={t('buildingBlock')}
                  include_blank={t('buildingBlock')}
                  key_value="id"
                  key_label="name"
                  data={dataBlock}
                  errors={[]}
                  onChange={(e) => this.onChangeBlock(e)}
                />
              </div>
            )}
            {settings?.[2]?.status === 1 && (
              <div className="col-md-2">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="building_id"
                  ref="building_id"
                  label={t('building')}
                  include_blank={t('building')}
                  key_value="id"
                  key_label="name"
                  data={dataBuilding}
                  errors={[]}
                  onChange={(e) => this.onChangeBuilding(e)}
                />
              </div>
            )}
            <div className="col-md-2">
              <SelectCustom
                className="form-control"
                classNameCustom="form-control-custom-cart-title"
                name="floor_id"
                ref="floor_id"
                label={t('floor')}
                include_blank={t('floor')}
                key_value="id"
                key_label="name"
                data={dataFloor}
                errors={[]}
                onChange={(e) => this.onChangeFloor(e)}
              />
            </div>
          </div>
          <hr />
          <div className="row mt-2">
            {listStatus?.map((item) => {
              return (
                <div className="col-md-2 mb-3">
                  <span
                    className={`block-status-cart block-status-cart-custom ${idStatus === item.id ? "block-status-cart-active" : ""
                      }`}
                    onClick={() => this.onFilterStatus(item.id)}
                    data-tip={`${item.name} (${item.amount})`}
                  >
                    <span className="title-status">
                      {item.name} ({item.amount})
                    </span>
                    <span
                      style={{ backgroundColor: item.color }}
                      className="color-status"
                    ></span>
                  </span>
                  <ReactTooltip />
                </div>
              );
            })}
          </div>
          <hr className="mt-0" />
          <div className="row mt-3">
            {isLoading ? (
              <div className="col-md-12 block-loading">
                <img src={loading} className="img-loading" />
              </div>
            ) : data != null ? (
              data.length > 0 ? (
                data.map((item, index) => {
                  return (
                    <div className="col-md-2" key={index}>
                      <p
                        style={{ backgroundColor: item.color }}
                        className={`title-cart title-cart-${item && item.sales_status != null
                          ? item.sales_status
                          : item.status
                          } title-cart-block`}
                        onClick={(e) => this.detailBlock(e, true, item.id)}
                      >
                        {item && item.product_code}
                        {checkRevoke && (
                          <span
                            className="iconRevoke"
                            onClick={(e) => this.onHandleRevoke(e, item.id)}
                          >
                            <img src={revokeIcon} />
                          </span>
                        )}
                      </p>
                    </div>
                  );
                })
              ) : (
                <p className="text-not-found">{t('noRecord')}</p>
              )
            ) : (
              <p className="text-not-found">{t('noRecord')}</p>
            )}
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    user: state.user?.user,
  };
};

export default connect(mapStateToProps, null)(withTranslation('cart')(withRouter(CartTable)));
