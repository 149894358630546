import React from 'react';
import UploadFileItem from './UploadFileItem';
import { useTranslation } from 'react-i18next';

const UploadFile = props => {
  const { t } = useTranslation('document');
  let { open, files, parent_id, closeUpdate } = props;
  if (!open) return '';

  return (
    <div className="file-upload animate__animated animate__fadeIn animate__faster">
      <div className="card">
        <div className="card-header">
          <span>{t('uploadFile')}</span>
          <span className="lnr lnr-cross icon-close" onClick={() => props.onClose()}></span>
        </div>
        <div className="card-body">
            {
              files.map((file,index) => {
                return <UploadFileItem closeUpdate={closeUpdate} file={file} key={index} parent_id={parent_id} uploadSuccess={props.uploadSuccess}/>
              })
            }
        </div>
      </div>
    </div>
  );
}

export default UploadFile;
