import { postDataWithParams, getDataByID, getTakenData, postData, putData } from './../services/base_services';
import { GET_FLOOR_URL } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors, destructServerMessage } from '../helpers/error'

const floorRequest = status => {
    return {
        type: types.FLOOR_REQUEST,
        status
    }
}

const getFloor = (data, paginate) => {
    return {
        type: types.GET_FLOOR_LIST,
        data,
        paginate
    }
}

export const getFlooringRequest = (search, params) => dispatch => {
    dispatch(floorRequest(true));
    postDataWithParams(GET_FLOOR_URL, search, params).then(res => {
        dispatch(getFloor(res.data, res.paginate));
        dispatch(floorRequest(false));
    }).catch(error => {
        dispatch(floorRequest(false));
        return Promise.reject(error)
    })
}

export const resetState = () => {
    return {
        type: types.RESET_FLOOR_BDS
    }
}



