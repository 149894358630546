import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import Select from "../../component/common/Select";
import { getFlooringRequest } from "../../actions/floor";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  GET_BUILDING_LIST,
  NEW_FLOOR_URL,
  UPDATE_FLOOR_URL,
  DELETE_FLOOR_URL,
} from "../../constants/Config";
import {
  deleteById,
  postData,
  putData,
  getTakenData,
} from "../../services/base_services";
import { confirmDelete, showMessage } from "./../../helpers/table";
import { destructServerErrors } from "../../helpers/error";
import { PER_PAGE } from "../../constants/Variable";
import { withTranslation } from "react-i18next";

class ProductFloor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: [],
      listBDS: [],
      loading_list: true,
      perPage: PER_PAGE,
      paginate: [],
      errors: [],
      tableHeaders: [
        {
          label: "Stt",
          index: "stt",
          option: {
            className: "text-center",
          },
        },
        {
          label: "name",
          option: {
            className: "text-center",
          },
          index: "name",
        },
        {
          label: "productBuildingType",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return <span>{data?.building?.name}</span>;
          },
        },
        {
          label: "functions",
          index: null,
          option: {
            className: "text-center fixed-collumn",
          },
          callback: (data) => {
            if (data.id) {
              const { t } = this.props;

              return (
                <div className="btn-group">
                  <span data-tip={t('actionEdit', {model: "Floor"})} className="link-action mr-2">
                    <img
                      src={editIcon}
                      className="btn-icon"
                      onClick={() => this.selectItem(data)}
                    />
                  </span>
                  <span data-tip={t('actionDelete', {model: "Floor"})} className="link-action ml-2">
                    <img
                      src={deleteIcon}
                      className="btn-icon"
                      onClick={() => this.deleteBlock(data.id)}
                    />
                  </span>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
          },
        },
      ],
    };
  }

  componentDidMount = () => {
    this.getList();
    this.loadBlockList();
  };

  deleteBlock = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeBlockRequest(id);
      }
    });
  };

  selectItem = (data) => {
    this.setState({
      item: data,
    });
  };

  removeBlockRequest = (id) => {
    deleteById(DELETE_FLOOR_URL, id)
      .then((res) => {
        showMessage(this.props.t('deleteSuccess', {model: "Floor"}), true);
        this.loadBlockList();
      })
      .catch((err) => {
        showMessage(this.props.t('responseFail'), false);
      });
  };

  loadBlockList = () => {
    let { page, perPage } = this.state;
    this.props.getFloorlist(this.search, { page: page, per_page: perPage });
    this.setState({
      item: null,
    });
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getFloorlist(this.search, {
        page: pageNumber,
        per_page: perPage,
      });
      this.setState({ page: pageNumber });
    }
  };

  getList = async () => {
    await getTakenData(GET_BUILDING_LIST).then((res) => {
      this.setState({
        listBDS: res.data.data,
        loading_list: false,
      });
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);

    const { item } = this.state;
    if (item === null) {
      postData(NEW_FLOOR_URL, formData)
        .then((res) => {
          showMessage(this.props.t('createSuccess', {model: "Floor"}), true);
          this.loadBlockList();
          this.setState({
            item: null,
            errors: [],
          });
          document.getElementById("form-product").reset();
        })
        .catch((err) => {
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        });
    } else {
      putData(UPDATE_FLOOR_URL, item.id, formData)
        .then((res) => {
          showMessage(this.props.t('updateSuccess', {model: "Floor"}), true);
          this.loadBlockList();
          this.setState({
            item: null,
            errors: [],
          });
          document.getElementById("form-product").reset();
        })
        .catch((err) => {
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        });
    }
  };

  closeEdit = () => {
    document.getElementById("form-product").reset();
    this.setState({
      item: null,
    });
  };

  render() {
    let { tableHeaders, listBDS, loading_list, item, errors } = this.state;
    const { t, floor, isLoading } = this.props;
    const tableData = floor && floor.data;
    const paginate = floor && floor.paginate;
    tableHeaders = tableHeaders.map(item => ({...item, label: t(item.label)}));

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col top-table-title">
              <p>{t('floorManagement')}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7">
              <form className="form-product">
                <p className="form-product-title text-center">
                  {t('floorList')}
                </p>

                <Table
                  fixed={true}
                  tableHeaders={tableHeaders}
                  tableData={tableData}
                  tablePaginate={paginate}
                  isLoading={isLoading}
                />

                <div className="d-flex">
                  <div className="table-pagenatie ml-auto">
                    <div className="paginate-wrapper">
                      <Paginate
                        paginate={paginate}
                        changePage={this.changePage}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-5 ">
              <form
                className="form-product"
                id="form-product"
                onSubmit={(e) => this.submitForm(e)}
              >
                <p className="form-product-title text-center">
                  {item === null ? t('newInformation') : t('updateInformation')}
                </p>
                <div className="form-product-body px-5">
                  <p className="form-product-body-name">{t('buildingType')}:</p>
                  {loading_list ? (
                    ""
                  ) : (
                    <Select
                      className="form-control input-form form-control-product mb-3"
                      name="building_id"
                      ref="building_id"
                      errors={errors}
                      key_value="id"
                      key_label="name"
                      include_blank={t('buildingType')}
                      data={listBDS}
                      defaultValue={
                        item && {
                          id: item?.building_id,
                          name: listBDS?.find((a) => a.id === item?.building_id)
                            ?.name,
                        }
                      }
                    />
                  )}
                  <p className="form-product-body-name">{t('floorName')}:</p>
                  <InputText
                    className="form-control input-form form-control-product"
                    placeholder={t('floorName')}
                    name="name"
                    ref="name"
                    errors={errors}
                    defaultValue={item && item.name}
                  />
                  <div className="button-confirm  text-center pt-5 pb-5">
                    <span className="" onClick={(e) => this.submitForm(e)}>
                      {item === null ? t('save') : t('updated')}
                    </span>
                  </div>
                  <div className="button-confirm  text-center  pb-5">
                    {item === null ? (
                      ""
                    ) : (
                      <span
                        className="btn-delete-custom"
                        onClick={() => this.closeEdit()}
                      >
                        {t('cancel')}
                      </span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    floor: state.Floor,
    isLoading: state.BDS.Building,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getFloorlist: (data, params) => dispatch(getFlooringRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('product')(withRouter(ProductFloor)));
