import React from 'react';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';

const InputFile = React.forwardRef((props, ref) => {
  let { errors, ...input } = props;
  return (
    <div className={checkError(errors, input.name)}>
      {input.label && <label className="input-label">{input.label}</label>}
      <input {...input} ref={ref} type="file"/>
      <Alert messsage={getError(errors, input.name)} />
    </div>
  )
});

export default InputFile;
