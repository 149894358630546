import React, { useState } from "react";
import { checkErrorMultikey } from "../../helpers/error";
import { uploadAvatar } from "../../helpers/form";
import { BASE_IMG } from "../../constants/Config";
import uploadIcon from "../../assets/images/icons/upload-img.png";

const FileImage = React.forwardRef((props, ref) => {
  let { errors, defaultValue, label, name, order, ...input } = props;
  let default_img = defaultValue;
  const [image, setImage] = useState(default_img);
  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState(errors);

  const onChangeImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      setLoading(true);
      uploadAvatar(file)
        .then((res) => {
          setImage(`${BASE_IMG}${res.file}`);
          setErrors(res.error_upload);
          setLoading(false);
          document.querySelector(`#upload-label-${order}`).style.display =
            "none";
        })
        .catch((err) => {
          setErrors(err.error_upload);
          setLoading(false);
        });
    }
  };

  const clickUpload = () => {
    document.querySelector(`#upload-input`).click();
  };

  return (
    <div
      className={`detail-product-picture block-upload-icon ${checkErrorMultikey(
        error,
        ["file", name]
      )}`}
    >
      {label && <p className="title-upload-icon">{label}</p>}
      <div className="block-upload-icon">
        {loading && <div id="uploading"></div>}
        <img
          className={`img-icon-up ${image && "hidden-icon"}`}
          onClick={clickUpload}
          src={uploadIcon}
          alt=""
        />
        <span className={`description ${image && "hidden-icon"}`}>
          Upload image (Under 4 MB)
        </span>
        <input
          type="file"
          className="upload-input"
          id="upload-input"
          onChange={(e) => onChangeImage(e)}
        />
        {image && (
          <img className="image-show" src={image} onClick={clickUpload} />
        )}
        <input type="hidden" ref={ref} value={image} />
      </div>
    </div>
  );
});

export default FileImage;
