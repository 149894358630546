import * as types from './../constants/actionType';

const initialState = {
  data: [],
  paginate: {},
  isLoading: true,
  message: null,
  isRequest: false,
  status: false,
  errors: {},
  salespolicy: null
}

const SalesPolicy = (state = initialState, action) => {
  switch (action.type) {
    case types.SALES_POLICY_REQUEST:
      return {
        ...state,
        isLoading: action.status
      }
    case types.GET_SALES_POLICY_LIST:
      return {
        ...state,
        data: [...action.data.data],
        paginate: { ...action.data.paginate },
        isLoading: false
      };
    case types.GET_DETAIL_SALES_POLICY:
      if (action.status) {
        return {
          ...state,
          message: null,
          salespolicy: action.data,
        };
      } else {
        return {
          ...state,
          status: false,
          message: action.data,
        };
      }
    case types.RESET_SALES_POLICY:
      return {
        ...state,
        isRequest: false,
        status: false,
        errors: {},
        salespolicy: null
      };
    case types.CREATE_SALES_POLICY:
      if (action.status) {
        return {
          ...state,
          status: true,
          message: 'Create sale policy successfully!',
          errors: {},
        };
      } else {
        return {
          ...state,
          status: false,
          message: 'Please check errors below.',
          errors: action.data,
        };
      }
    case types.UPDATE_SALES_POLICY:
      if (action.status) {
        return {
          ...state,
          status: true,
          message: 'Update sale policy successfully!',
          errors: {},
        };
      } else {
        return {
          ...state,
          status: false,
          message: 'Please check errors below.',
          errors: action.data,
        };
      }
    default:
      return { ...state };
  }
}

export default SalesPolicy;