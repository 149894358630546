/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-sparse-arrays */
import React, { Component } from 'react'
import InputSearch from "../../component/common/InputSearch";
import NewButton from "../../component/common/NewButton";
import { Link, withRouter } from 'react-router-dom';
import filterIcon from '../../assets/images/filter.png'
import ReactTooltip from 'react-tooltip'
import editIcon from '../../assets/images/edit.svg'
import deleteIcon from '../../assets/images/cancel.svg'
import Table from '../../component/common/Table';
import Paginate from "../../component/Paginate/index";
import PerPage from "../../component/Paginate/PerPage";
import { connect } from 'react-redux';
import { LIST_TEMP_PRODUCT, DELETE_TEMP_PRODUCT } from '../../constants/Config'
import { destructServerErrors, destructServerMessage } from '../../helpers/error';
import { postDataWithParams, getDataByID, getTakenData, postData, putData, deleteById } from '../../services/base_services';
import { moneyFormat } from '../../helpers/table'
import { confirmDelete, afterRemove, showMessage } from './../../helpers/table';
import { PER_PAGE } from "../../constants/Variable";
import ButtonSmall from "../../component/common/ButtonSmall";
import { withTranslation } from 'react-i18next';

class ProductTemplate extends Component {

    constructor(props) {

        super(props)

        this.search = ""
        this.state = {
            perPage: PER_PAGE,
            paginate: [],
            tableData: [],
            isLoading: true,
            tableHeaders: [
                {
                    label: 'Stt',
                    index: 'stt',
                    option: {
                        className: "text-center position-relative"
                    },
                    callback: null

                },
                {
                    label: 'productName',
                    index: null,
                    option: {
                        className: ""
                    },
                    callback: data => {
                        return (
                            <div>
                                <span>{data && data.name}</span>
                            </div>
                        )
                    }
                }, {
                    label: 'productAreaInside',
                    index: null,
                    option: {
                        className: "text-center"
                    },
                    callback: data => {
                        return (
                            <div>
                                <span>{`${data && data.area_inside ? `${data && data.area_inside}m2` : 'Unknow'}`}</span>
                            </div>
                        )
                    }
                },
                , {
                    label: 'productPrice',
                    index: null,
                    option: {
                        className: "text-center"
                    },
                    callback: data => {
                        return (
                            <div>
                                <span>{moneyFormat(data && data.price)}</span>
                            </div>
                        )
                    }
                },
                {
                    label: 'functions',
                    index: null,
                    option: {
                        className: 'text-center fixed-collumn'
                    },
                    callback: (data) => {
                        if (data.id) {
                            const { t } = this.props;
                            
                            return (
                                <div className="btn-group">
                                    <span data-tip={t('actionEdit', {model: t('productTemplate')})} className="link-action mr-2">
                                        <img src={editIcon} className="btn-icon"
                                            onClick={() => this.editProduct(data.id)}
                                        />
                                    </span>
                                    <span data-tip={t('actionDelete', {model: t('productTemplate')})} className="link-action ml-2">
                                        <img src={deleteIcon} className="btn-icon"
                                            onClick={() => this.onDeleteProduct(data.id)}
                                        />
                                    </span>
                                    <ReactTooltip className="tooltip-button" place="bottom" effect="float" />
                                </div>
                            );
                        }
                    }
                }
            ]

        }
    }
    editProduct = (id) => {
        this.props.history.push(`/product/template/edit/${id}/information`);
    }


    onDeleteProduct = (id) => {
        confirmDelete().then((res) => {
            if (res.value == true) {
                this.removeProductRequest(id);
            }
        })

    }

    removeProductRequest = (id) => {
        deleteById(DELETE_TEMP_PRODUCT, id).then(res => {
            showMessage(res.data.message, true);
            this.getListProduct();
        }).catch(err => {
            afterRemove(this.props.t('responseFail'), false);
        })
    }

    componentDidMount = async () => {
        this.getListProduct()
    }

    getListProduct = async () => {
        let { page, perPage, search } = this.state
        this.getListRequest({ page: page, per_page: perPage })
    }

    getListRequest = async (params) => {
        await postDataWithParams(LIST_TEMP_PRODUCT, this.search, params).then(res => {
            this.setState({
                tableData: res.data.data,
                paginate: res.data.paginate,
                isLoading: false
            })
        }).catch(error => {
            return Promise.reject(error)
        })

    }

    onChangeSearch = (e) => {
        clearTimeout(this.timer);
        let { page, perPage } = this.state;
        let target = e.target;
        let name = target.name;
        let value = target.value ? target.value : "";

        this.search = {
            ...this.search,
            [name]: value
        }

        this.timer = setTimeout(() => this.getListRequest({ page: page, per_page: perPage }), 500);
    }

    changePage = (pageNumber, e) => {
        let { page, perPage } = this.state;
        if (pageNumber != page) {
            this.getListRequest({ page: pageNumber, per_page: perPage });
            this.setState({ page: pageNumber });
        }
    }

    render() {
        const { t } = this.props;
        let { tableHeaders, per_page, paginate, tableData, isLoading } = this.state
        tableHeaders = tableHeaders.map(item => ({...item, label: t(item.label)}));

        return (
            <>
                <div className="container-fluid container-shadow">
                    <div className="row top-table">
                        <div className="col-md-4 top-table-title">
                            <p>
                                {t('productTemplateManagement')}
                            </p>
                        </div>
                        <div className="col-md-12 d-flex ">
                            <div className="mr-auto d-flex">
                                <InputSearch onChange={this.onChangeSearch} placeholder={t('searchPlaceholder')} />
                                <Link to="/product/template/new" className="btn-add-custom ml-2">
                                    <ButtonSmall label={t('created')} />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Table fixed={true} tableHeaders={tableHeaders} tableData={tableData} tablePaginate={paginate} isLoading={isLoading} />
                        </div>
                        <div className="table-pagenatie ml-auto">
                            <div className="paginate-wrapper">
                                <Paginate
                                    paginate={paginate}
                                    changePage={this.changePage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {

    }
}

const mapDispatchToProps = (dispatch, props) => {
    return {

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('product')(withRouter(ProductTemplate)));
