import React, { useEffect, useState } from "react";
import BlockCart from "./BlockCart";
import BlockCharCircle from "./BlockCharCircle";
import BlockTableList from "./BlockTableList";
import BlockTableListBottom from "./BlockTableListBottom";
import { getTakenData } from "../../services/base_services";
import { GET_DETAIL_DASHBOARD } from "../../constants/Config";
import { useSelector } from "react-redux";

const DashboardMaintenance = () => {
  const position_id = useSelector((state) => state.user?.user?.position_id);
  const [detailDashboard, setDetailDashboard] = useState({});
  useEffect(() => {
    getDetailDashboard();
  }, []);

  const getDetailDashboard = async () => {
    getTakenData(GET_DETAIL_DASHBOARD).then((res) =>
      setDetailDashboard(res?.data)
    );
  };

  return (
    <div className="container-shadow-dashboard">
      <BlockCart CartStatistic={detailDashboard?.cart_statistic} />
      <BlockCharCircle
        FinanceStatistic={detailDashboard?.finance_statistic}
        DistributorStatistic={detailDashboard?.distributor_statistic}
        SalerStatistic={detailDashboard?.saler_statistic}
        position_id={position_id}
      />
      <BlockTableList Customer={detailDashboard?.customer} CustomerStatistic={detailDashboard?.customer_statistic} />
      {position_id !== 2 ? (
        <BlockTableListBottom
          SalesPolicyStatistic={detailDashboard?.sales_policy_statistic}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DashboardMaintenance;
