import React, { Component } from "react";
import { checkError, getError, checkErrorSingle } from "../../helpers/error";
import { uploadFile, sizeOf } from "./../../helpers/form";
import Alert from "./Alert";
import { BASE_IMG } from '../../constants/Config'

class Document extends Component {
  constructor(props) {
    super(props);
    props.onChange(props.defaultName);
    this.state = {
      title: props.title,
      data: props.defaultValue,
      error: {},
      loading:false
    };
  }

  // Upload file
  onOpenUploadFile = (e) => {
    e.preventDefault();
    document.getElementById("upload_files").click();
  };

  // Upload
  onUploadFiles = (e) => {
    let file = e.target.files[0];
    if (file) {
      this.setState({
        title: file.name,
      });
      this.setLoading(true);
      uploadFile(file).then(res => {
        let image = res.data
        image.path = `${BASE_IMG}${image.path}`
        this.setState({ data: image, error: {} });
        this.props.onChange(image)
        this.setLoading(false);
      }).catch(err => {
        this.setState({ error: err.error_upload });
        this.setLoading(false);
      });
    }
  };

  setLoading = status => {
    this.setState({
      loading: status
    })
  }

  render() {
    let { title, error, data, loading } = this.state;
    let { label, errors, name, className } = this.props
    
    return (
      <div className={`${className} ${checkError(errors, name)} ${checkErrorSingle(error, 'file')}`}>
        <label className="input-label">{label}</label>
        <button
          className="form-control input-form input-form-custom-file"
          onClick={(e) => this.onOpenUploadFile(e)}
          disabled={loading}
        >
          <span>{data && data.filename ? data.filename : title} {(data && data.capacity) ? `(${(sizeOf(data && data.capacity))})` : ''}</span>
          {
            loading
              ? <i className="fa fa-spinner icon-folder" aria-hidden="true"></i>
              : <i className="fa fa-folder icon-folder" aria-hidden="true"></i>
          }
        </button>
        <input
          type="file"
          name="file"
          id="upload_files"
          className="upload_file"
          onChange={this.onUploadFiles}
        />
        <Alert messsage={getError(errors, name)} />
        <Alert messsage={getError(error, 'file')} />
      </div>
    );
  }
}

export default Document;
