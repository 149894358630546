import React, { Component } from 'react';
import { stt } from '../../helpers/table';
import Loading from './LoadingTable';
import ScrollContainer from 'react-indiana-drag-scroll'
class Table extends Component {
  constructor(props) {
    super(props);
  }

  initTableRow = (data, index) => {
    let { tablePaginate, role_member } = this.props;
    let idx = index;
    
    return (
      <tr key={index}>
        {this.props.tableHeaders.map(function (header, index) {
          let value = data;
          if (header.index) {
            value = data[header.index];
          }

          if (header.callback) {
            value = header.callback(value, role_member);
          }

          if (tablePaginate && header.index && header.index == 'stt') {
            value = stt(idx, tablePaginate.current_page, tablePaginate.per_page);
          }
          if(header.show == false) {
            return;
          }
          return <td key={index} {...header.option}>{value}</td>
        })}
      </tr>
    );
  }

  render() {
    let { tableHeaders, tableData, tablePaginate, isLoading, fixed } = this.props;
    return (
      <ScrollContainer className={`scrollable  ${fixed && 'table-fixed'}`} vertical={false} hideScrollbars={false}>
        <table className="table  table-custom auto">
          <thead className="table-thead">
            <tr>
              {
                tableHeaders.map(function (header, index) {
                  if(header.show == false) {
                    return;
                  }
                  return <th key={index} className={`text-center table-header ${header.className || ''}`}>{header.label}</th>;
                })
              }
            </tr>
          </thead>
          <tbody className="table-tbody">
            {
              isLoading && <tr>
                <td className="no-result" colSpan={tableHeaders.length}>
                  <Loading count="10" />
                </td>
              </tr>
            }

            {
              tableData.length == 0 && !isLoading && <tr>
                <td className="no-result" colSpan={tableHeaders.length}>
                  No records
              </td>
              </tr>
            }

            {
              tableData.length > 0 && !isLoading && tableData.map(this.initTableRow)
            }
          </tbody>
        </table>
      </ScrollContainer>
    );
  }
}

export default Table;
