import React, { useMemo } from "react";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import defaultImg from "../../assets/images/avatar.jpeg";
import { BASE_IMG } from "../../constants/Config";
import { signOutUser } from "./../../actions/index";
import { useTranslation } from "react-i18next";
import { Dropdown } from 'antd';
import { useLanguage } from "../../hooks/useLanguage";

const Header = (props) => {
  const [language, languages, onChangeLanguage] = useLanguage();
  const { t } = useTranslation('menu')
  const { user } = props.user;
  const history = useHistory();
  const flags = useMemo(() => languages.map((f) => (<div className="dropdown-item" onClick={() => onChangeLanguage(f)}>
    <img src={`/flags/${f}.svg`} alt={f} width={20} className="flg-img" />
  </div>)), [languages, onChangeLanguage]);

  const infoProfile = () => {
    props.history.push("/profile");
  };

  const logOut = (e) => {
    e.preventDefault();
    props.signOutUser().then(() => history.push('/login'));
  };

  return (
    <div className="container-fluid">
      <div className="row header ">
        <div className="header-page ml-auto my-auto d-flex flex-row">
          <div className="dropdown dropdown-flags mr-3">
            <div
              className="header-page d-flex dropdown-toggle"
              id="dropdownMenuFlag"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={`/flags/${language}.svg`} alt={language} className="flg-img" />
            </div>
            <div className="dropdown-menu dropdown-menu-flags" aria-labelledby="dropdownMenuFlag">{flags}</div>
          </div>

          <div className="dropdown">
            <div
              className="header-page d-flex dropdown-toggle"
              id="dropdownMenuProfile"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                src={
                  user && user.avatar
                    ? `${BASE_IMG}/${user.avatar}`
                    : defaultImg
                }
              />
              <div className="info-user">
                <p>{user && user.name}</p>
                <span>{user && user.email}</span>
              </div>
            </div>
            <div
              className="dropdown-menu"
              aria-labelledby="dropdownMenuProfile"
            >
              <a className="dropdown-item" onClick={infoProfile}>
                {t('profile')}
              </a>
              <a className="dropdown-item" onClick={(e) => logOut(e)}>
                {t('logout')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    signOutUser: () => dispatch(signOutUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
