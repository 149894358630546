import React, { Component } from 'react'
import SaleTag from './SaleTag'
import Table from '../../component/common/Table'

class SaleHistory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tableHeaderHistory: [
        {
          label: 'STT',
          index: 'stt',
          option: {
            className: "text-center"
          },
          callback: null
        },
        {
          label: 'PAGE',
          index: "",
        },
        {
          label: 'ACTION',
          index: "",

        },
        {
          label: 'LOG TIME',
          index: "",
        }
      ]
    }
  }


  render() {

    let { tableHeaderHistory } = this.state

    return (
      <>
        <div className="container-fluid container-shadow">
          <SaleTag />
          <div className="row table-border">
            <div className="col-md-12 name-header-part">
              <Table fixed={true} tableHeaders={tableHeaderHistory} tableData={[]} isLoading={false} />
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default SaleHistory