import React, { Component } from "react";
import { postDataWithParams } from "../../services/base_services";
import { GET_LIST_CUSTOMER_URL } from "../../constants/Config";
import { updateRequest } from "../../actions/distributor";
import { Checkbox } from "../../component/common";
import ButtonSave from "../../component/common/ButtonSave";
import Paginate from "../../component/Paginate/index";
import { showMessage } from "../../helpers/table";
import { Link, withRouter } from "react-router-dom";
import Alert from "../../component/common/Alert";
import { getError } from "../../helpers/error";
import InputSearch from "../../component/common/InputSearch";
import _, {debounce} from 'lodash';

class DistributionCustomer extends Component {
  constructor(props) {
    super(props);
    this.search = {};
    this.list_distribution_id = [];
    this.state = {
      FilterStatus: false,
      data: [],
      isLoading: false,
      distributor_id: null,
      paginate: [],
      page: 1,
      stage_sale_id: null,
      errors: [],
    };
  }

  componentDidMount() {
    this.loadListCart({
      page: this.state.page,
      per_page: 10,
      status: 0,
    });
  }

  loadListCart = async (params) => {
    await postDataWithParams(GET_LIST_CUSTOMER_URL, this.search, params)
      .then((res) => {
        this.setState({
          data: res.data.data,
          paginate: res.data.paginate,
          isLoading: false,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  changePage = (pageNumber, e) => {
    let checkboxes = document.getElementsByName("distributionId[]");
    let checkboxesAll = document.getElementsByName("name[]");
    checkboxesAll[0].checked = false;
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
    this.list_distribution_id = [];
    let { page } = this.state;
    if (pageNumber != page) {
      this.loadListCart({
        page: pageNumber,
        per_page: 10,
        status: 0,
      });

      this.setState({ page: pageNumber });
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    let value = parseInt(target.value);
    if (target.checked) {
      this.list_distribution_id.push(value);
    } else {
      this.list_distribution_id.splice(
        this.list_distribution_id.indexOf(value),
        1
      );
    }
  };

  checkAll = (e) => {
    const target = e.target;
    let checkboxes = document.getElementsByName("distributionId[]");
    if (target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        this.list_distribution_id.push(parseInt(checkboxes[i].value));
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      this.list_distribution_id = [];
    }
  };

  onSave = () => {
    let { id } = this.props.match.params;
    const formData = {
      status: 1,
      customer_id: this.list_distribution_id,
      distributor_id: id,
    };
    if (id) {
      updateRequest(formData)
        .then((res) => {
          showMessage(res.data.message, true);
          this.props.history.push("/customer");
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
  };

  onChangeSearch = (e) => {
    let { page } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";

    this.search = {
      ...this.search,
      [name]: value,
    };

    this.loadListCart({
      page: page,
      per_page: 10,
      status: 0,
    });
  };

  render() {
    let { data, paginate, errors } = this.state;

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6">
              <h3 className="title-cart-top">Danh sách khách hàng</h3>
            </div>
            <div className="col-md-6 d-flex">
              <div className="ml-auto d-flex">
                <Link to="/customer">
                  <ButtonSave text="Back" />
                </Link>
                <ButtonSave onClick={this.onSave} text="Save" />
              </div>
            </div>
          </div>
          <div className="row ml-0">
            <InputSearch onChange={this.onChangeSearch} />
          </div>
          <div className="row mt-3">
            <div className="col-12">
              <div className="table-custom-maintenance">
                <div className="form-group">
                  <Alert messsage={getError(errors, "distributor_id")} />
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>
                        <Checkbox
                          errors={[]}
                          label=""
                          name="name[]"
                          id="all"
                          multiple={true}
                          defaultChecked={false}
                          onChange={this.checkAll}
                        />
                      </th>
                      <th className="text-left">Tên khách</th>
                      <th className="text-center">Liên hệ</th>
                      <th className="text-left">Email</th>
                      <th className="text-left">Địa chỉ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => {
                      return (
                        <tr>
                          <td className="text-center">
                            <Checkbox
                              errors={[]}
                              id={`project_id_${item.id}`}
                              refs={`project_id_${item.id}`}
                              label=""
                              value={item.id}
                              onChange={this.handleInputChange}
                              name="distributionId[]"
                            />
                          </td>
                          <td>{item.fullname}</td>
                          <td className="text-center">{item.phone}</td>
                          <td>{item.email}</td>
                          <td>{item.address}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(DistributionCustomer);
