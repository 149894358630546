import React, { useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import Select from '../../component/common/Select';
import InputFile from '../../component/common/InputFile';
import Button from '../../component/common/Button';
import { SOURCES } from '../../constants/Variable';
import { useImport } from '../../hooks/user';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';

const ImportExcel = ({ open, onClose, onSuccess }) => {
    const { t } = useTranslation('customer');
    const [errors, setError] = useState([]);
    const form = useRef();
    const closeModal = () => {
        onClose();
        setError([]);
        form.current.reset();
    }
    const importSuccess = () => {
        NotificationManager.success('Import danh sách khách hàng thành công.', 'Thành công!');
        onSuccess();
        closeModal();
    }
    const importFailed = (errs) => {
        setError(errs.errors);
    }

    const [{ loading }, handleSubmit] = useImport(importSuccess, importFailed);

    const onImport = e => {
        e.preventDefault();
        let formData = new FormData(e.target);
        handleSubmit(formData);
    }

    return (
        <Popup open={open} closeOnDocumentClick={false} onClose={closeModal}>
            <div className="popup">
                <div className="popup-header">
                    <h3>{t('importExcel')}</h3>
                    <button className="close" onClick={closeModal}>
                        &times;
                    </button>
                </div>

                <div className="popup-body">
                    <form ref={form} onSubmit={onImport}>
                        <InputFile name="file" label="File excel:" errors={errors} className="form-control input-form" accept=".xls,.xlsx"/>
                        <div className="btn-groups">
                            <button className="btn btn-outline-danger btn-sm" type="button" onClick={closeModal} disabled={loading}>{t('cancel')}</button>
                            <Button className="btn btn-success btn-sm" loading={loading}>{t('submit')}</Button>
                        </div>
                    </form>
                </div>
            </div>
        </Popup>
    );
}

export default ImportExcel;