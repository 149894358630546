import { combineReducers } from "redux";
import user from "./user";
import Loading from "./loading";
import Distributor from "./Distributor";
import BDS from "./BDS";
import Block from "./Block";
import Building from "./Building";
import Floor from "./Floor";
import Customer from "./Customer";
import SalesPolicy from "./SalesPolicy";
import Roles from "./Roles";
import Sale from "./Sale";

const reducers = combineReducers({
  user,
  Loading,
  Distributor,
  BDS,
  Block,
  Building,
  Floor,
  Customer,
  SalesPolicy,
  Roles,
  Sale
});

export default reducers;
