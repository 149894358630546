import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import { Link, withRouter } from "react-router-dom";
import InputPhoneCustom from "../../component/common/InputPhoneCustom";
import Select from "../../component/common/Select";
import { getTakenData } from "../../services/base_services";
import { LIST_DISTRIBUTOR } from "../../constants/Config";
import { getFormDataFromRef } from "../../helpers/form";
import { createSaleRequest } from "../../actions/sale";
import { showMessage } from "../../helpers/table";
import ButtonSave from "../../component/common/ButtonSave";
import { withTranslation } from "react-i18next";
class SaleNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDistributor: [],
      errors: {},
    };
  }

  componentDidMount = async () => {
    let listDistributor = await getTakenData(LIST_DISTRIBUTOR);
    this.setState({
      listDistributor: listDistributor.data.data,
    });
  };

  onCreateSale = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    let val = formData.phone;
    formData.phone = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`;
        } else if (s) {
          return `${f}${s}`;
        } else if (f) {
          return `${f}`;
        }
      });
    formData = { ...formData };
    createSaleRequest(formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ errors: err.err });
      });
  };

  render() {
    const { t } = this.props;
    let { errors, listDistributor } = this.state;

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('newSale')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.onCreateSale} text={t('save')} />
              <Link to="/sale_list">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>

          <div className="row new-product-temp">
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder={t('fullname')}
                name="full_name"
                ref="full_name"
                label={`${t('fullname')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder={t('address')}
                name="address"
                ref="address"
                label={`${t('address')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputPhoneCustom
                name="phone"
                ref="phone"
                label={`${t('phone')}:`}
                type="text"
                className="form-control input-form"
                placeholder={t('phone')}
                errors={errors}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Email"
                name="email"
                ref="email"
                label="Email:"
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <Select
                className="form-control input-form"
                name="distributor_id"
                ref="distributor_id"
                label={`${t('distributor')}:`}
                include_blank={t('distributor')}
                key_value="id"
                key_label="name"
                data={listDistributor}
                errors={errors}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('sale')(withRouter(SaleNew));
