import { axiosInstance } from './base_services';

// Action when request error
const processError = (err) => {
  throw err?.response?.data;
};

// Action when request success
const responseBody = (res) => res.data;

const requester = {
  get: (url, params = {}, configs = {}) =>
    axiosInstance
      .get(url, { params: params, headers: configs })
      .then(responseBody)
      .catch(processError),
  post: (url, data) =>
    axiosInstance.post(url, data).then(responseBody).catch(processError),
  put: (url, data) =>
    axiosInstance.put(url, data).then(responseBody).catch(processError),
  delete: (url) =>
    axiosInstance.delete(url).then(responseBody).catch(processError),
  postWithFile: (url, data) => {
    return axiosInstance
      .post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(responseBody)
      .catch(processError);
  },
};

export default requester;
