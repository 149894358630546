import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

class DistributorInfo extends Component {


  constructor(props) {
    super(props)
    this.id = this.props.match.params.id
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="d-flex distributor-nav">
          <NavLink to={`chart`} activeClassName="active" className="distributor-nav-item">
            {t('generalInfo')}
          </NavLink>
          <NavLink to={`box`} activeClassName="active" className="distributor-nav-item">
            {t('chatbox')}
          </NavLink>
        </div>
      </>
    )
  }
}


export default withTranslation('distributor')(withRouter(DistributorInfo));
