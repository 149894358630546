import React, { Component } from 'react'
import Select from '../../component/common/Select'
import InputText from '../../component/common/InputText'
import CheckboxRow from '../../component/common/CheckboxRow'
import InputCurrency from '../../component/common/InputCurrency'
import GroupImage from '../../component/common/GroupImage'
import { getTakenData, postDataWithParams, postData, getDataByParams, getDataByID } from '../../services/base_services'
import { GET_BLOCK_LIST, DIREICTION_PRODUCT, GET_FLOOR_BUILDING, GET_BUILDING_BLOCK, CREATE_PRODUCT_REQUEST, GET_TEMP_PRODUCT, GET_DISTRIBUTOR_SELECT_URL, GET_BUILDING_ALL_URL, GET_FLOOR_ALL_URL } from '../../constants/Config'
import ButtonSave from "../../component/common/ButtonSave";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, Redirect, withRouter } from 'react-router-dom'
import { confirmDelete, afterRemove } from './../../helpers/table';
import { destructServerErrors } from '../../helpers/error'
import { showMessage } from '../../helpers/table';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'

class ProductNew extends Component {
    constructor(props) {
        super(props)
        this.state = {
            listDis: [],
            listTemp: [],
            listBlock: [],
            listBuilding: [],
            listFloor: [],
            status: false,
            listDerection: [],
            errors: []
        }
    }

    componentDidMount = () => {
        this.getList()
        if(this.props.user.settings[1].status === 2) {
            this.loadBuildingAll();
        } 
        if(this.props.user.settings[2].status === 2) {
            this.loadFloorAll();
        }
    }

    loadBuildingAll = () => {
        getTakenData(GET_BUILDING_ALL_URL).then((res) =>
            this.setState({
                listBuilding: res.data.data,
            })
        );
    }

    loadFloorAll = () => {
        getTakenData(GET_FLOOR_ALL_URL).then((res) =>
            this.setState({
                listFloor: res.data.data,
            })
        );
    }

    getList = async () => {
        await getTakenData(GET_BLOCK_LIST).then(res => {
            this.setState({
                listBlock: res.data.data,
                loading_list: false,
            })
        }
        );
        await getTakenData(DIREICTION_PRODUCT).then(res => {
            this.setState({
                listDerection: res.data.data,
            })
        }
        );
        await getTakenData(GET_TEMP_PRODUCT).then(res => {
            this.setState({
                listTemp: res.data.data,
            })
        }
        );
        await getTakenData(GET_DISTRIBUTOR_SELECT_URL).then(res => {
            this.setState({
                listDis: res.data.data,
            })
        }
        );

    }
    selectBlock = (e) => {
        getDataByID(GET_BUILDING_BLOCK, e.target.value).then(res => {
            this.setState({
                listBuilding: res.data.data
            })
        }).catch(error => {
            return Promise.reject(error)
        })
    }
    selectBuilding = (e) => {
        getDataByID(GET_FLOOR_BUILDING, e.target.value).then(res => {
            this.setState({
                listFloor: res.data.data
            })
        }).catch(error => {
            return Promise.reject(error)
        })
    }
    addNew = (e) => {
        e.preventDefault();
        var formData = getFormDataFromRef(this.refs);
        formData = { ...formData }
        postData(CREATE_PRODUCT_REQUEST, formData).then(res => {
            showMessage(res.data.message, true);
            this.setState({
                errors: [],
                status: true
            })
        }).catch(err => {
            let errs = destructServerErrors(err);
            this.setState({
                errors: errs
            })
        })
    };

    render() {

        const { listBlock, listBuilding, listFloor, errors, listTemp, listDis, status } = this.state
        let { t, user } = this.props;
        let settings = user?.settings;

        if (status) {
            return (
                <Redirect
                    to={{
                        pathname: "/product",
                    }}
                />
            );
        }
        return (
            <>
                <div className="container-fluid container-shadow">
                    <div className="row top-table">
                        <div className="col-md-6 top-table-title">
                            <p>
                                {t('productNew')}
                            </p>
                        </div>
                        <div className="col-md-6 d-flex">
                            <ButtonSave onClick={this.addNew} text={t('save')} />
                            <Link to="/product">
                                <ButtonSave text={t('cancel')} className="btn-delete" />
                            </Link>
                        </div>
                    </div>
                    <div className="row">
                        {settings?.[1]?.status === 1 && (
                        <div className="col-md-3 input-grey">
                            <Select
                                label={`${t('productBlockType')}:`}
                                className="form-control input-form form-control-product mb-3 input-grey"
                                name="block_id"
                                ref="block_id"
                                errors={errors}
                                key_value="id"
                                key_label="name"
                                include_blank={t('productBlockType')}
                                data={listBlock}
                                onChange={this.selectBlock}
                            />
                        </div>
                        )}
                        {settings?.[2]?.status === 1 && (
                        <div className="col-md-3 input-grey">
                            <Select
                                label={`${t('productBuildingType')}:`}
                                className="form-control input-form form-control-product mb-3 input-grey"
                                name="building_id"
                                ref="building_id"
                                errors={errors}
                                key_value="id"
                                key_label="name"
                                include_blank={`${t('productBuildingType')}`}
                                data={listBuilding}
                                onChange={this.selectBuilding}

                            />
                        </div>
                        )}
                        <div className="col-md-3 input-grey">
                            <Select
                                label={`${t('productFloorType')}:`}
                                className="form-control input-form form-control-product mb-3 input-grey"
                                name="floor_id"
                                ref="floor_id"
                                errors={errors}
                                key_value="id"
                                key_label="name"
                                include_blank={t('productFloorType')}
                                data={listFloor}
                            />
                        </div>
                    </div>
                    <div className="row new-product-temp">
                        <div className="col-md-12">
                            <p className=" new-product-temp-title">
                                {`${t('basicInfomation')}`}
                            </p>
                        </div>
                        <div className="col-md-6">
                            <InputText
                                className="form-control input-form"
                                placeholder={t('apartmentTypeName')}
                                name="product_code"
                                ref="product_code"
                                label={`${t('productCode')}:`}
                                errors={errors}
                                type="text"
                            />
                        </div>
                        <div className="col-md-6">
                            <Select
                                label={`${t('productTemplate')}:`}
                                className="form-control input-form form-control-product mb-3 input-grey"
                                name="template_product_id"
                                ref="template_product_id"
                                errors={errors}
                                key_value="id"
                                key_label="name"
                                include_blank={t('productTemplate')}
                                data={listTemp}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputCurrency
                                className="form-control input-form"
                                placeholder={t('productCurrentPrice')}
                                name="current_price"
                                ref="current_price"
                                label={`${t('productCurrentPrice')}:`}
                                errors={errors}
                                type="text"
                                defaultValue={0}
                            />
                        </div>
                        <div className="col-md-6">
                            <Select
                                label={`${t('productDistributor')}:`}
                                className="form-control input-form form-control-product mb-3 input-grey"
                                name="distributor_id"
                                ref="distributor_id"
                                errors={errors}
                                key_value="id"
                                key_label="name"
                                include_blank={t('productDistributor')}
                                data={listDis}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state, props) => {
    return {
      user: state.user.user,
    };
  };
  
  export default connect(mapStateToProps, null)(withTranslation('product')(withRouter(ProductNew)));