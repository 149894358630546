import React, { useEffect, useState, useRef } from "react";
import Popup from "reactjs-popup";
import { InputText } from "../../component/common";
import { postData } from "../../services/base_services";
import { CREATE_FILE_PRODUCT } from "../../constants/Config";
import { showMessage } from "./../../helpers/table";
import { useTranslation } from "react-i18next";

const NewCategoryTemplate = ({
  className,
  open,
  newFolder,
  idCategory,
  idProduct,
  getAgain,
}) => {
  const [openNew, setOpenNew] = useState(false);
  const [errors, setErrors] = useState([]);
  const name = useRef("");
  const { t } = useTranslation('product');

  useEffect(() => {
    setOpenNew(open);
  }, [open]);

  const closeModal = () => {
    setOpenNew(false);
    newFolder(false);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    let formData = {
      name: name.current.value,
      parent: idCategory,
      template_product_id: idProduct,
      path: [],
    };
    postData(CREATE_FILE_PRODUCT, formData)
      .then((res) => {
        showMessage(t('createCategorySuccess'), true);
        closeModal();
        getAgain();
      })
      .catch((err) => {
        setErrors(err.errors || []);
        showMessage(t('createCategoryFail'), false);
      });
  };

  return (
    <div className="main main-custom">
      <div className={`custom-popup ${className}`}>
        <Popup open={openNew} modal closeOnDocumentClick={false}>
          <div className="modal-custom">
            <div className="popup-header pt-3 pb-2 px-3">
              <h5 className="mb-0">{t('newCategory')}</h5>
            </div>
            <span
              className="lnr lnr-cross-circle close-btn"
              onClick={closeModal}
            ></span>
            <div className="form-edit text-left p-3">
              <form className="row" onSubmit={onSubmitForm}>
                <div className="col-lg-12">
                  <InputText
                    name="name"
                    type="text"
                    ref={name}
                    className="form-control input-form"
                    placeholder={t('categoryName')}
                    errors={errors}
                  />
                </div>
                <div className="col-12">
                  <div className="btn-footer float-right mt-1">
                    <button
                      type="button"
                      className="btn btn-custom btn-outline-secondary mr-2"
                      onClick={closeModal}
                    >
                      {t('cancel')}
                    </button>
                    <button className="btn btn-custom btn-success">{t('created')}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
};

export default NewCategoryTemplate;
