export const MEDIA_FILE = 1;
export const MEDIA_FOLDER = 0;
export const PER_PAGE = 10;
export const SOURCES = [
    {
        id: 1,
        label: "Landing Page"
    },
    {
        id: 2,
        label: "Facebook"
    },
    {
        id: 2,
        label: "Google Adv"
    }
];
export const CUSTOMER_STATUS_COLORS = {
  1: 'green',
  2: 'blue',
  3: 'cyan',
  4: 'orange',
  5: 'magenta'
}
export const CUSTOMER_ASSIGN_STATUS = 3;
export const CUSTOMER_NEW_STATUS = 1;