/* eslint-disable no-sparse-arrays */
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { getSalesPolicyRequest } from "../../actions/salespolicy";
import {
  DELETE_SALES_POLICY_URL,
  LIST_DISTRIBUTOR,
} from "../../constants/Config";
import { deleteById, getTakenData } from "../../services/base_services";
import {
  confirmDelete,
  afterRemove,
  showMessage,
  confirmFinish,
} from "../../helpers/table";
import SelectCustom from "../../component/common/SelectCustom";
import { formatDate } from "../../helpers/form";
import { PER_PAGE } from "../../constants/Variable";
import ButtonSmall from "../../component/common/ButtonSmall";
import CheckboxRowCustom from "../../component/common/CheckboxRowCustom";
import InputSearch from "../../component/common/InputSearch";
import { DatePicker } from "antd";
import { updateSalesPolicyRequest } from "../../actions/salespolicy";
import { withTranslation } from "react-i18next";

class SalesPolicyTable extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.message = null;
    this.state = {
      listDistributor: [],
      perPage: PER_PAGE,
      paginate: [],
    };
  }

  onDeleteSalesPolicy = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeSalesPolicyRequest(id);
      }
    });
  };

  removeSalesPolicyRequest = (id) => {
    deleteById(DELETE_SALES_POLICY_URL, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.loadSalesPolicy();
      })
      .catch((err) => {
        showMessage(err.data.message, false);
      });
  };

  componentDidMount() {
    this.loadSelectDistributor();
    this.loadSalesPolicy();
  }

  loadSalesPolicy = () => {
    let { page, perPage } = this.state;
    this.props.getSalesPolicy(this.search, { page: page, per_page: perPage });
  };

  loadSelectDistributor = () => {
    getTakenData(LIST_DISTRIBUTOR).then((res) =>
      this.setState({
        listDistributor: res.data.data,
      })
    );
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getSalesPolicy(this.search, {
        page: pageNumber,
        per_page: perPage,
      });
      this.setState({ page: pageNumber });
    }
  };

  onChangeSearch = (e) => {
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";
    this.search = {
      ...this.search,
      [name]: value,
    };
    this.props.getSalesPolicy(this.search, { page: page, per_page: perPage });
  };

  onChangeDate = (date, name) => {
    let { page, perPage } = this.state;
    let dateTmp = "";
    if (date) {
      dateTmp = date.format("YYYY-MM-DD");
    }
    this.search = {
      ...this.search,
      [name]: dateTmp,
    };
    this.props.getSalesPolicy(this.search, { page: page, per_page: perPage });
  };

  onCheckStatus = (e, item) => {
    const data = {
      date_apply: item?.date_apply,
      distributor_id: item?.distributor?.id,
      expriration_date: item?.expriration_date,
      name: item?.name,
      public_flg: e?.target?.checked ? 1 : 2,
    };
    if (item.id) {
      confirmFinish(this.props.t('updateStatus')).then((res) => {
        if (res.value == true) {
          this.changeStatus(item.id, data);
        }
      });
    }
  };

  changeStatus = (id, data) => {
    const { page, perPage } = this.state;
    updateSalesPolicyRequest(id, data)
      .then((res) => {
        showMessage(res.data.message, true);
        this.props.getSalesPolicy({}, { page: page, per_page: perPage });
      })
      .catch((err) => {
        this.setState({ errors: err.err });
      });
  };

  onEditSalesPolicy = (id) => {
    this.props.history.push(`/sales_policy/${id}/edit`);
  };

  render() {
    let { t, salespolicy, isLoading } = this.props;
    let tableData = salespolicy && salespolicy.data;
    let paginate = salespolicy && salespolicy.paginate;
    let { perPage, listDistributor } = this.state;
    const tableHeaders = [
      {
        label: "Stt",
        index: "stt",
        option: {
          className: "text-center",
        },
        callback: null,
      },
      {
        label: t('policyName'),
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>{data && data.name}</span>
            </div>
          );
        },
      },
      {
        label: t('distributorName'),
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>{data && data.distributor && data.distributor.name}</span>
            </div>
          );
        },
      },
      ,
      {
        label: t('createdAt'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>
                {data && data.created_at && formatDate(data.created_at)}
              </span>
            </div>
          );
        },
      },
      ,
      {
        label: t('applyAt'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>
                {data && data.date_apply && formatDate(data.date_apply)}
              </span>
            </div>
          );
        },
      },
      {
        label: t('status'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <CheckboxRowCustom
              className="custom"
              errors={[]}
              id="public_flg"
              ref="public_flg"
              label=""
              value={data.public_flg}
              name="public_flg"
              defaultChecked={data.public_flg === 1 ? true : false}
              onChange={(e) => this.onCheckStatus(e, data)}
            />
          );
        },
      },
      {
        label: t('functions'),
        index: null,
        option: {
          className: "text-center fixed-collumn",
        },
        callback: (data) => {
          if (data.id) {
            return (
              <div className="btn-group">
                <span
                  data-tip={t('editPolicy')}
                  className="link-action ml-2 mr-2"
                >
                  <img
                    src={editIcon}
                    className="btn-icon"
                    onClick={() => this.onEditSalesPolicy(data.id)}
                  />
                </span>
                <span data-tip={t('deletePolicy')} className="link-action ml-2">
                  <img
                    src={deleteIcon}
                    className="btn-icon"
                    onClick={() => this.onDeleteSalesPolicy(data.id)}
                  />
                </span>
                <ReactTooltip
                  className="tooltip-button"
                  place="bottom"
                  effect="float"
                />
              </div>
            );
          }
        },
      },
    ];
    const dateFormat = "YYYY/MM/DD";
    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-12 top-table-title">
              <p>{t('salePolicyManagement')}</p>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-3 search-form-cart">
              <InputSearch onChange={this.onChangeSearch} placeholder={t('search')} />
            </div>
            <div className="col-md-3">
              <SelectCustom
                className="form-control"
                classNameCustom="form-control-custom-cart-title"
                name="distributor_id"
                ref="distributor_id"
                label={t('distributor')}
                include_blank={t('distributor')}
                key_value="id"
                key_label="name"
                data={listDistributor}
                errors={[]}
                onChange={this.onChangeSearch}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                className="date-search-custom"
                placeholder={t('startAt')}
                name="date_apply"
                onChange={(date) => this.onChangeDate(date, "date_apply")}
                format={dateFormat}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                className="date-search-custom"
                placeholder={t('endAt')}
                name="expriration_date"
                onChange={(date) => this.onChangeDate(date, "expriration_date")}
              />
            </div>
            <div className="col-md-2">
              <Link to="/sales_policy/new" className="btn-add-custom">
                <ButtonSmall label={t('new')} />
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <Table
                fixed={true}
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    salespolicy: state.SalesPolicy,
    isLoading: state.SalesPolicy.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSalesPolicy: (data, params) =>
      dispatch(getSalesPolicyRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('salesPolicy')(withRouter(SalesPolicyTable)));
