import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'


class HomePage extends Component {

  constructor(props) {
    super(props)
    this.check = []
    this.state = {
    }
  }



  render() {

    return (
      <>
        <Redirect to="/dashboard" />
      </>
    )
  }
}


export default HomePage