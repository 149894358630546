import React, { useState } from 'react';
import { sizeOf } from '../../helpers/form';
import { destructServerErrors } from '../../helpers/error';
import { axiosInstance } from '../../services/base_services';
import { CREATE_MEDIA_FILE_URL } from '../../constants/Config';
import { UploadingFile, UploadError } from '../../assets/images/icons';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Alert from '../../component/common/Alert';
import { checkError, getError } from '../../helpers/error';

const UploadFileItem = props => {
  let { file, parent_id, closeUpdate } = props;
  const [process, setProcess] = useState(0);
  const [uploading, setUploading] = useState(true);
  const [error, setError] = useState([]);
  const [status, setStatus] = useState(true);

  const config = {
    headers: { 'content-type': 'multipart/form-data' },
    onUploadProgress: (evt) => {
      let percent = Math.round((evt.loaded * 100) / evt.total);
      setProcess(percent);
    }
  }

  React.useEffect(() => onUploadFile(), []);

  const onUploadFile = () => {
    setProcess(0);
    setUploading(true);
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      if (parent_id) formData.append("parent_id", parent_id);

      axiosInstance.post(CREATE_MEDIA_FILE_URL, formData, config).then(res => {
        if (res.data) {
          props.uploadSuccess(res.data.data);
          setUploading(false);
          setError([]);
          setStatus(true);
          closeUpdate();
        }
      }).catch(err => {
        err = destructServerErrors(err);
        setUploading(false);
        setError(err);
        setStatus(false);
      });
    }
  }

  if (!file) { return '' }

  return (
    <div className={`file-upload_item animate__animated animate__fadeIn animate__faster ${checkError(error, 'file')}`}>
      {uploading && <div className="uploading"></div>}
      <div className="item-info">
        <div className="item-preview">
          <img src={UploadingFile} alt={file.name} />
        </div>
        <div className="info">
          <p>{file.name}</p>
          <span>{sizeOf(file.size)}</span>
        </div>
        <div className="loading">
          { status ?  <CircularProgressbar value={process} text={`${process}%`} /> : <img src={UploadError} alt="upload error" className="error-upload"/>}

        </div>
      </div>

      <Alert messsage={getError(error, 'file')} />
    </div>
  );
}

export default UploadFileItem;
