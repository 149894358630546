import React, { Component } from "react";

class InputSearch extends Component {
  render() {
    return (
      <div className="search-form search-form-cart">
        <div className="input-wrapper">
          <span className="lnr lnr-magnifier"></span>
          <input
            name="search_text"
            className="form-control input-form"
            placeholder="Tìm kiếm..."
            {...this.props}
          />
        </div>
      </div>
    );
  }
}

export default InputSearch;
