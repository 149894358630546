import { postDataWithParams, getDataByID, postData,putData } from './../services/base_services';
import { GET_LIST_SALES_POLICY_URL, CREATE_SALES_POLICY_URL, UPDATE_SALES_POLICY_URL, DETAIL_SALES_POLICY_URL } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors, destructServerMessage } from '../helpers/error';

const salesPolicyRequest = status => {
  return {
    type: types.SALES_POLICY_REQUEST,
    status
  }
}

// GET LIST SALES POLICY
const getSalesPolicy = (data, paginate) => {
  return {
    type: types.GET_SALES_POLICY_LIST,
    data,
    paginate
  }
}

export const getSalesPolicyRequest = (search, params) => dispatch => {
  dispatch(salesPolicyRequest(true));
  postDataWithParams(GET_LIST_SALES_POLICY_URL, search, params).then(res => {
    dispatch(getSalesPolicy(res.data, res.paginate));
    dispatch(salesPolicyRequest(false));
  }).catch(error => {
    dispatch(salesPolicyRequest(false));
    return Promise.reject(error)
  })
}
// RESET
export const resetState = () => {
  return {
    type: types.RESET_SALES_POLICY
  }
}
// CREATE
export const createSalesPolicyRequest = (data) => {
  return postData(CREATE_SALES_POLICY_URL, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET
export const getDetailSalesPolicyRequest = (id) => {
  return getDataByID(DETAIL_SALES_POLICY_URL, id).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// UPDATE
export const updateSalesPolicyRequest = (id, data) => {
  return putData(UPDATE_SALES_POLICY_URL, id, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}