import { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const usePost = (actionCreator, successCallback, errorCallback) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [paginate, setPaginate] = useState({});
  const [errors, setErrors] = useState(null);

  const handleSubmit = useCallback(
    (params) => {
      setLoading(true);
      actionCreator(params)
        .then((res) => {
          setData(res.data);
          setPaginate(res.paginate)
          setLoading(false);
          successCallback && successCallback(res.data || res);
        })
        .catch((err) => {
          setErrors(err);
          setLoading(false);
          errorCallback && errorCallback(err);
        });
    },
    [actionCreator, successCallback, errorCallback]
  );

  return [{ data, loading, errors, paginate }, handleSubmit];
};

export const useFetch = (actionCreator, successCallback, errorCallback) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setLoading(true);
    actionCreator()
      .then((res) => {
        setData(res.data);
        setLoading(false);
        successCallback && successCallback(res.data);
      })
      .catch((err) => {
        setErrors(err);
        setLoading(false);
        errorCallback && errorCallback(err);
      });
  },
    [actionCreator, successCallback, errorCallback]
  );

  return [data, loading, errors];
};

export const useFetchWithParam = (actionCreator, params, successCallback, errorCallback) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    setLoading(true);
    actionCreator(params)
      .then((res) => {
        setData(res.data);
        setLoading(false);
        successCallback && successCallback(res.data);
      })
      .catch((err) => {
        setErrors(err);
        setLoading(false);
        errorCallback && errorCallback(err);
      });
  },
    [actionCreator, successCallback, errorCallback]
  );

  return [data, loading, errors];
};

const queryToObject = (entries) => {
  const result = {}
  for(const [key, value] of entries) {
    result[key] = value;
  }
  return result;
}

export const useQuery = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return queryToObject(query.entries());
}

export const usePagination = (paginate) => {
  if(!paginate) return {};

  return {
    total: paginate.total,
    pageSize: paginate.per_page,
    current: paginate.current_page
  }
}