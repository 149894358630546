import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import InputPhone from "../../component/common/InputPhone";
import FileAvatar from "../../component/common/FileImage";
import FileImageCustom from "../../component/common/FileImageCustom";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  createDistributorRequest,
  resetState,
} from "../../actions/distributor";
import { getFormDataFromRef } from "../../helpers/form";
import InputPassword from "../../component/common/InputPassword";
import Loader from "../../component/common/Loader";
import ButtonSave from "../../component/common/ButtonSave";
import { withTranslation } from "react-i18next";

class DistributorNew extends Component {
  constructor(props) {
    super(props);
  }

  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    this.props.createDistributor(formData);
  };

  componentWillUnmount() {
    this.props.resetState();
  }

  render() {
    let { distributor, isLoading, t } = this.props;
    let { errors, isRequest, message, status } = distributor;

    if (!isRequest && status) {
      return (
        <Redirect
          to={{
            pathname: "/distributor",
            state: {
              message: message,
            },
          }}
        />
      );
    }
    return (
      <>
        <div className="container-fluid container-shadow pb-5">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('createDistributor')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/distributor">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <p className="col-md-12 top-table-text">
                {t('distributorInfo')}
              </p>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('name')}
                  name="name"
                  ref="name"
                  label={`${t('name')}:`}
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('shortName')}
                  name="name_short"
                  ref="name_short"
                  label={`${t('shortName')}:`}
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputPhone
                  className="form-control input-form "
                  placeholder={t('phone')}
                  name="phone"
                  ref="phone"
                  label={`${t('phone')}:`}
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('address')}
                  name="address"
                  ref="address"
                  errors={errors}
                  label={`${t('address')}:`}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder="Email"
                  name="email"
                  ref="email"
                  errors={errors}
                  label="Email:"
                />
              </div>
              <div></div>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <p className="col-md-12 top-table-text">
                {t('representationInfo')}
              </p>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('representationName')}
                  name="representation_name"
                  ref="representation_name"
                  label={`${t('representationName')}:`}
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputPassword
                  className="form-control input-form "
                  placeholder={t('representationPassword')}
                  name="representation_password"
                  ref="representation_password"
                  errors={errors}
                  label={`${t('representationPassword')}:`}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputPhone
                  className="form-control input-form "
                  placeholder={t('representationPhone')}
                  name="representation_phone"
                  ref="representation_phone"
                  label={`${t('representationPhone')}:`}
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('representationAddress')}
                  name="representation_address"
                  ref="representation_address"
                  label={`${t('representationAddress')}:`}
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder="Email"
                  name="representation_email"
                  ref="representation_email"
                  errors={errors}
                  label="Email:"
                />
              </div>
              <div></div>
            </div>
          </div>
          <div className="row mr-0 ml-0 pb-4">
            <div className="col-md-12">
              <FileImageCustom
                ref="avatar"
                name="avatar"
                errors={errors}
                label={`${t('selectLogo')}:`}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    distributor: state.Distributor,
    isLoading: state.Distributor.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    createDistributor: (data) => {
      dispatch(createDistributorRequest(data));
    },
    resetState: () => dispatch(resetState()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('distributor')(withRouter(DistributorNew)));
