import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

class SaleTag extends Component {
  constructor(props) {
    super(props)
    this.id = this.props.match.params.id
  }

  render() {
    const { t } = this.props;

    return (
      <>
          <div className="row d-flex distributor-nav distributor-nav-custom">
            <div className="col-4">
            <NavLink to={`detail`} activeClassName="active" className="distributor-nav-item distributor-nav-item-custom">
              {t('saleInfo')}
            </NavLink>
            </div>
            <div className="col-4">
            <NavLink to={`apartment`} activeClassName="active" className="distributor-nav-item distributor-nav-item-custom">
              {t('apartmentSale')}
            </NavLink>
            </div>
            <div className="col-4">
            <NavLink to={`customers`} activeClassName="active" className="distributor-nav-item distributor-nav-item-custom">
              {t('customerSale')}
            </NavLink>
            </div>
          </div>

      </>
    )
  }
}


export default withTranslation('sale')(withRouter(SaleTag));
