import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/";
import { PER_PAGE } from "../../constants/Variable";
import iconInfo from "../../assets/images/info2x.png";
import "../../assets/styles/trackingUser/trackingUser.scss";
import { postData, getTakenData } from "../../services/base_services";
import {
  LIST_TRACKING_USER_URL,
  LIST_DISTRIBUTOR,
} from "../../constants/Config";
import InputSearch from "../../component/common/InputSearch";
import SelectCustom from "../../component/common/SelectCustom";
import { Filter } from "react-feather";
import FilterCart from "./FilterCart";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DATA_POST = {
  number_screen: null,
  interactive_time: null,
  to_date: null,
  from_date: null,
  per_page: PER_PAGE,
  page: 1,
};



const TrackingUser = () => {
  const { t } = useTranslation('follow');
  const [paginate, setPaginate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [listDvpp, setListDvpp] = useState([]);
  const [FilterStatus, setFilterStatus] = useState(false);
  const [data, setData] = useState([]);
  const position_id = useSelector((state) => state.user?.user?.position_id);
  const TABLE_HEADER = [
    {
      label: t('customerName'),
      index: "full_name",
    },
    {
      label: "Email",
      index: "email",
    },
    {
      label: t('dateAccess'),
      index: "date_access",
      option: {
        className: "text-center",
      },
    },
    {
      label: t('screenQuantity'),
      index: "number_screen",
      option: {
        className: "text-center",
      },
    },
    {
      label: t('interactionTime'),
      index: null,
      option: {
        className: "text-center",
      },
      callback: (data) => {
        return <p>{data["interactive_time"]} {t('timeUnit')}</p>;
      },
    },
    {
      label: t('functions'),
      index: null,
      option: {
        className: "text-center",
      },
      callback: (data, _) => {
        return (
          <div>
            <Link to={`/tracking/user/${data.id}`}>
              <img src={iconInfo} alt="icon" width="30px" />
            </Link>
          </div>
        );
      },
    },
  ];

  const changePage = (pageNumber, e) => {
    getTableData(pageNumber);
  };

  const getTableData = (page) => {
    setIsLoading(true);
    postData(LIST_TRACKING_USER_URL, { ...DATA_POST, page }).then((res) => {
      setTableData(res.data.data);
      setPaginate(res.data.paginate);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getTableData(1);
    loadSelectDVPP();
  }, []);

  const loadSelectDVPP = () => {
    getTakenData(LIST_DISTRIBUTOR).then((res) => setListDvpp(res.data.data));
  };

  const onChangeSearch = (e) => {};

  const onFilterListCart = (
    dataBedRoom,
    dataBathRoom,
    dataArea,
    dataPrice
  ) => {};

  const showFilter = () => {
    setFilterStatus(!FilterStatus);
  };

  const onChangeDistributor = (e) => {};

  return (
    <div className="container-fluid container-shadow">
      <div className="row top-table">
        <div className="col-md-4 top-table-title">
          <p>{t('customerFollow')}</p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-4 search-form-cart">
          <InputSearch onChange={onChangeSearch} placeholder={t('search')} />
        </div>
        {position_id !== 2 ? (
          position_id !== 1 ? (
            <>
              <div className="col-md-3">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="distributor_id"
                  label={`${t('distributor')}:`}
                  include_blank={t('distributor')}
                  key_value="id"
                  key_label="name"
                  data={listDvpp}
                  errors={[]}
                  onChange={(e) => onChangeDistributor(e)}
                />
              </div>
              <div className="col-md-3">
                <SelectCustom
                  className="form-control"
                  classNameCustom="form-control-custom-cart-title"
                  name="distributor_id"
                  label={`${t('saleName')}:`}
                  include_blank={t('saleName')}
                  key_value="id"
                  key_label="name"
                  data={listDvpp}
                  errors={[]}
                  onChange={(e) => onChangeDistributor(e)}
                />
              </div>
            </>
          ) : (
            <div className="col-md-3">
              <SelectCustom
                className="form-control"
                classNameCustom="form-control-custom-cart-title"
                name="distributor_id"
                label={`${t('saleName')}:`}
                include_blank={t('saleName')}
                key_value="id"
                key_label="name"
                data={listDvpp}
                errors={[]}
                onChange={(e) => onChangeDistributor(e)}
              />
            </div>
          )
        ) : (
          ""
        )}
        <div className="col-md-2">
          <div className="cart-filters w-100">
            <div
              className={`btn btn-filter d-flex align-items-center ${
                FilterStatus == true ? "active" : ""
              }`}
              onClick={showFilter}
            >
              <Filter />
              {t('filter')}
            </div>
            <FilterCart
              data={data}
              min={1}
              max={100}
              className={`${FilterStatus == true ? "show" : ""}`}
              show={FilterStatus}
              onSubmitFilter={onFilterListCart}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 ">
          <Table
            fixed={true}
            tableHeaders={TABLE_HEADER}
            tableData={tableData}
            tablePaginate={paginate}
            isLoading={isLoading}
          />
        </div>
        <div className="table-pagenatie ml-auto">
          <div className="paginate-wrapper">
            <Paginate paginate={paginate} changePage={changePage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrackingUser;
