import React, { Component } from "react";
import { Switch } from "react-router-dom";
import RolesTable from "../container/Roles/RolesTable";
import RolesNew from "../container/Roles/RolesNew";
import RolesEdit from "../container/Roles/RolesEdit";

import AuthRoute from "./AuthRoute";

const routes = [
  {
    path: "/roles",
    component: () => <RolesTable />,
    exact: true,
    meta: {
      action: "role.list",
      subject: "roles",
    },
  },
  {
    path: "/roles/new",
    component: () => <RolesNew />,
    exact: true,
    meta: {
      action: "role.add",
      subject: "roles",
    },
  },
  {
    path: "/roles/:id/edit",
    component: () => <RolesEdit />,
    exact: true,
    meta: {
      action: "role.edit",
      subject: "roles",
    },
  },
];

class RolesRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
            {routes.map((route) => (
              <AuthRoute key={route.path} {...route} />
            ))}
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default RolesRoutes;
