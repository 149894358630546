import React from "react";
import AuthRoute from "./AuthRoute";
import { Switch } from "react-router-dom";
import TrackingProduct from "../container/TrackingProduct";
import TrackingUser from "../container/TrackingUser";
import DetailTrackingUser from "../container/TrackingUser/DetailTrackingUser";
import Tracking from "../container/Tracking";

const routes = [
  {
    path: "/tracking",
    component: () => <Tracking />,
    exact: true,
  },
  {
    path: "/tracking/product",
    component: () => <TrackingProduct />,
    exact: true,
  },
  {
    path: "/tracking/user",
    component: () => <TrackingUser />,
    exact: true,
  },
  {
    path: "/tracking/user/:id",
    component: () => <DetailTrackingUser />,
    exact: true,
  },
];

const TrackingRoute = () => {
  return (
    <Switch>
      {routes.map((route) => (
        <AuthRoute key={route.path} {...route} />
      ))}
    </Switch>
  );
};

export default TrackingRoute;
