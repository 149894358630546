import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import InputPhone from "../../component/common/InputPhone";
import FileAvatar from "../../component/common/FileImage";
import { Link, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  resetState,
  getDetailDistributorRequest,
  updateDistributorRequest,
} from "../../actions/distributor";
import { getFormDataFromRef } from "../../helpers/form";
import Loader from "../../component/common/Loader";
import ButtonSave from "../../component/common/ButtonSave";
import FileImageCustom from "../../component/common/FileImageCustom";
import { withTranslation } from "react-i18next";

class DistributorEdit extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
    this.state = {
      loadPage: true,
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      await this.props.getDistributor(parseInt(id));
    }
    this.setState({
      loadPage: false,
    });
  };

  addNew = (e) => {
    let { distributor, isLoading } = this.props;
    let { id } = this.props.match.params;
    let data = distributor?.distributor;
    let user_id =
      data &&
      data.data &&
      data.data.representation &&
      data.data.representation.id;
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData = { ...formData, user_id: user_id };
    this.props.updateDistributor(id, formData);
  };

  componentWillUnmount() {
    this.props.resetState();
  }

  render() {
    let { distributor, isLoading, t } = this.props;
    let data = distributor?.distributor;
    let { errors, isRequest, message, status } = distributor;
    let { loadPage } = this.state;

    if (!isRequest && status) {
      return (
        <Redirect
          to={{
            pathname: "/distributor",
            state: {
              message: message,
            },
          }}
        />
      );
    }

    return (
      <>
        <div className="container-fluid container-shadow pb-5">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('editDistributor')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/distributor">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <p className="col-md-12 top-table-text">
                {t('distributorInfo')}
              </p>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('name')}
                  name="name"
                  ref="name"
                  label={`${t('name')}:`}
                  errors={errors}
                  defaultValue={data && data.data && data.data.name}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('shortName')}
                  name="name_short"
                  ref="name_short"
                  label={`${t('shortName')}:`}
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputPhone
                  className="form-control input-form "
                  placeholder={t('phone')}
                  name="phone"
                  ref="phone"
                  label={`${t('phone')}:`}
                  errors={errors}
                  defaultValue={data && data.data && data.data.phone}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('address')}
                  name="address"
                  ref="address"
                  errors={errors}
                  defaultValue={data && data.data && data.data.addrres}
                  label={`${t('address')}:`}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder="Email"
                  name="email"
                  ref="email"
                  defaultValue={data && data.data && data.data.email}
                  errors={errors}
                  label="Email:"
                />
              </div>

              <div></div>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <p className="col-md-12 top-table-text">
                {t('representationInfo')}
              </p>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('representationName')}
                  name="representation_name"
                  ref="representation_name"
                  label={`${t('representationName')}:`}
                  errors={errors}
                  defaultValue={
                    data &&
                    data.data &&
                    data.data.representation &&
                    data.data.representation.name
                  }
                />
              </div>
              <div className="col-md-12 py-1">
                <InputPhone
                  className="form-control input-form "
                  placeholder={t('representationPhone')}
                  name="representation_phone"
                  ref="representation_phone"
                  label={`${t('representationPhone')}:`}
                  errors={errors}
                  defaultValue={
                    data &&
                    data.data &&
                    data.data.representation &&
                    data.data.representation.phone
                  }
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder={t('representationAddress')}
                  name="representation_address"
                  ref="representation_address"
                  label={`${t('representationAddress')}:`}
                  defaultValue={
                    data &&
                    data.data &&
                    data.data.representation &&
                    data.data.representation.address
                  }
                  errors={errors}
                />
              </div>
              <div className="col-md-12 py-1">
                <InputText
                  className="form-control input-form "
                  placeholder="Email"
                  name="representation_email"
                  ref="representation_email"
                  errors={errors}
                  defaultValue={
                    data &&
                    data.data &&
                    data.data.representation &&
                    data.data.representation.email
                  }
                  label="Email:"
                />
              </div>
              <div className="col-md-12 py-1" style={{ opacity: 0 }}>
                <InputText
                  className="form-control input-form "
                  placeholder="Email"
                  name=""
                  ref=""
                  errors={errors}
                  label="Email:"
                />
              </div>
              <div className="col-md-12 py-1"></div>
              <div></div>
            </div>
          </div>
          <div className="row ml-0 mr-0 pb-4">
            <div className="col-md-12 d-flex">
              {isLoading ? (
                ""
              ) : (
                <FileImageCustom
                  ref="avatar"
                  name="avatar"
                  errors={errors}
                  label={`${t('selectLogo')}:`}
                  defaultValue={data && data.data && data.data.avatar}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    distributor: state.Distributor,
    isLoading: state.Distributor.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getDistributor: (id) => {
      dispatch(getDetailDistributorRequest(id));
    },
    resetState: () => dispatch(resetState()),
    updateDistributor: (id, data) =>
      dispatch(updateDistributorRequest(id, data)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('distributor')(withRouter(DistributorEdit)));
