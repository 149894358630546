import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Slider, Select, DatePicker } from "antd";
import { withTranslation } from "react-i18next";

class FilterCart extends Component {
  constructor(props) {
    super(props);
    this.message = null;
    this.search = "";
    this.state = {
      errors: {},
      value: "Choose the number of rooms",
      // dataBedRoom: [],
      // dataBathRoom: [],
      dataPrice: null,
      dataArea: null,
      dataFilter: [],
    };
  }

  handleChangeArea = (dataArea) => {
    this.setState({ dataArea });
  };
  handleChangePrice = (dataPrice) => {
    this.setState({ dataPrice });
  };
  // handleChangeBedRoom = (dataBedRoom) => {
  //   this.setState({
  //     dataBedRoom,
  //   });
  // };

  // handleChangeBathRoom = (dataBathRoom) => {
  //   this.setState({ dataBathRoom });
  // };

  handleClearAllFilter = () => {
    this.setState({
      // dataBedRoom: [],
      // dataBathRoom: [],
      dataPrice: "",
      dataArea: "",
    });
  };
  render() {
    const Option = Select;
    const { max, min, t } = this.props;
    const { dataArea, dataPrice } = this.state;
    // const { dataBedRoom, dataBathRoom, dataArea, dataPrice } = this.state;
    return (
      <div className={`cart_filter ${this.props.className}`}>
        <div className="cart_filter_title">{t('filterResults')}</div>
        <div className="cart_filter_select_group p-2 d-flex">
          {/* <div className="cart_filter--items d-flex">
            <label>BedRoom</label>
            <Select
              onChange={(e) => this.handleChangeBedRoom(e)}
              value={dataBedRoom}
              className=""
              mode="multiple"
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="All">All</Option>
            </Select>
          </div>
          <div className="cart_filter--items d-flex">
            <label>BathRooms</label>
            <Select
              onChange={(e) => this.handleChangeBathRoom(e)}
              value={dataBathRoom}
              mode="multiple"
              className=""
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="All">All</Option>
            </Select>
          </div> */}
          <div className="form-group">
            <label>{t('area')} (m2)</label>
            <div className="icon-wrapper">
              <span>{min}</span>
              <Slider
                {...this.props}
                onChange={this.handleChangeArea}
                value={dataArea}
              />
              <span>{max}</span>
            </div>
          </div>
          <div className="form-group">
            <label>{t('price')}</label>
            <div className="icon-wrapper">
              <span>{min}</span>
              <Slider
                {...this.props}
                onChange={this.handleChangePrice}
                value={dataPrice}
              />
              <span>{max}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <DatePicker
                className="date-search-custom"
                placeholder={t('fromDate')}
                name="date_apply"
              />
            </div>
            <div className="col-6">
              <DatePicker
                className="date-search-custom"
                placeholder={t('toDate')}
                name="date_apply"
              />
            </div>
          </div>
        </div>
        <div className="cart_filter--group-btn">
          <button
            onClick={this.handleClearAllFilter}
            className="btn-custom text-uppercase"
          >
            {t('clearAll')}
          </button>
          <button
            onClick={() =>
              this.props.onSubmitFilter(
                // dataBedRoom,
                // dataBathRoom,
                dataArea,
                dataPrice
              )
            }
            className="btn-custom text-uppercase"
          >
            {t('apply')}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation('follow')(withRouter(FilterCart));
