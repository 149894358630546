import React, { Component } from "react";
import { Switch } from "react-router-dom";
import DistributorTable from "./../container/Distributor/DistributorTable";
import DistributorNew from "./../container/Distributor/DistributorNew";
import DistributorEdit from "./../container/Distributor/DistributorEdit";
import DistributorChart from "../container/Distributor/DistributorChart";
import AuthRoute from "./AuthRoute";

const routes = [
  {
    path: "/distributor",
    component: () => <DistributorTable />,
    exact: true,
    meta: {
      action: "distributor.list",
      subject: "distributors",
    },
  },
  {
    path: "/distributor/new",
    component: () => <DistributorNew />,
    exact: true,
    meta: {
      action: "distributor.add",
      subject: "distributors",
    },
  },
  {
    path: "/distributor/:id/edit",
    component: () => <DistributorEdit />,
    exact: true,
    meta: {
      action: "distributor.edit",
      subject: "distributors",
    },
  },
  {
    path: "/distributor/:id/chart",
    component: () => <DistributorChart />,
    exact: true,
    meta: {
      action: "distributor.show",
      subject: "distributors",
    },
  },
];

class DistributorRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
            {routes.map((route) => (
              <AuthRoute key={route.path} {...route} />
            ))}
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default DistributorRoutes;
