import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getDetailRolesRequest, updateRolesRequest } from "../../actions/roles";
import ButtonSave from "../../component/common/ButtonSave";
import { Checkbox } from "../../component/common";
import { getFormDataFromRef } from "../../helpers/form";
import { showMessage } from "../../helpers/table";
import { getTakenData } from "../../services/base_services";
import { GET_PERMISSIONS_URL } from "../../constants/Config";
import Select from "../../component/common/Select";
import { withTranslation } from "react-i18next";

class RolesEdit extends Component {
  constructor(props) {
    super(props);
    this.permission = [];
    this.state = {
      listPermissions: [],
      errors: {},
      data: {},
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    let listPermissions = await getTakenData(GET_PERMISSIONS_URL);
    this.setState({
      listPermissions: listPermissions.data.data,
    });

    let { id } = this.props.match.params;

    if (id) {
      await getDetailRolesRequest(id)
        .then((res) => {
          this.setState({ data: res.data.data });
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
    this.setState({
      isLoading: false,
    });

    this.permission = this.state.data.permission_ids;
  };

  onUpdateRoles = (e) => {
    let { id } = this.props.match.params;
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      permission_ids: this.permission,
    };

    if (id) {
      updateRolesRequest(id, formData)
        .then((res) => {
          showMessage(res.data.message, true);

          this.props.history.goBack();
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
  };

  handleInputChange = (e) => {
    const target = e.target;
    let value = parseInt(target.value);
    if (target.checked) {
      this.permission.push(value);
    } else {
      this.permission.splice(this.permission.indexOf(value), 1);
    }
  };

  checkAll = (e) => {
    const target = e.target;
    let checkboxes = document.getElementsByName("permission[]");
    if (target.checked) {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        this.permission.push(parseInt(checkboxes[i].value));
      }
    } else {
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
      }
      this.permission = [];
    }
  };

  render() {
    let { listPermissions, errors, isLoading } = this.state;
    let { name, permission_ids } = this.state.data;
    const { t } = this.props;

    return isLoading ? (
      ""
    ) : (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('createRole')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.onUpdateRoles} text={t('save')} />
              <Link to="/roles">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <InputText
                className="form-control input-form form-small"
                placeholder={t('groupName')}
                name="name"
                ref="name"
                label={`${t('groupName')}:`}
                errors={errors}
                defaultValue={name}
              />
              <label className="input-label mg-top-10">
                {t('detailRole')}:
              </label>
            </div>
            <div className="col-md-6 d-flex flex-wrap">
              <Select
                className="form-control input-form form-small"
                name="permisson_temp"
                ref="permisson_temp"
                label={`${t('templateRole')}:`}
                include_blank={t('templateRole')}
                key_value="id"
                key_label="name"
                data={[]}
                errors={errors}
              />
            </div>
            <div className="col-md-12 d-flex flex-wrap">
              <Checkbox
                errors={errors}
                label={t('all')}
                name="name[]"
                id="all"
                multiple={true}
                defaultChecked={false}
                onChange={this.checkAll}
              />
            </div>

            {listPermissions?.map((permission) => (
              <div className="col-md-3 d-flex flex-wrap flex-col mg-top-10">
                <h6 className="mg-20">{`${permission.name}:`}</h6>
                <div className="flex-col">
                  {permission.permissions.map((feature) => {
                    let isChecked = permission_ids.indexOf(feature.id) > -1;
                    return (
                      <Checkbox
                        id={`project_permission_id_${feature.id}`}
                        ref={`project_permission_id_${feature.id}`}
                        label={feature.name}
                        value={feature.id}
                        multiple={true}
                        onChange={this.handleInputChange}
                        name="permission[]"
                        defaultChecked={isChecked}
                        errors={errors}
                      />
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch, props) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('role')(withRouter(RolesEdit)));
