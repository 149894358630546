import React, { Component } from "react";
import Select from "../../component/common/Select";
import InputText from "../../component/common/InputText";
import CheckboxRow from "../../component/common/CheckboxRow";
import InputCurrency from "../../component/common/InputCurrency";
import GroupImage from "../../component/common/GroupImage";
import {
  getTakenData,
  postDataWithParams,
  postData,
} from "../../services/base_services";
import {
  GET_BLOCK_CATE_LIST,
  GET_BLOCK_URL,
  GET_BUILDING_URL,
  DIREICTION_PRODUCT,
  CREATE_TEMP_PRODUCT,
} from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, Redirect } from "react-router-dom";
import { confirmDelete, afterRemove } from "./../../helpers/table";
import { destructServerErrors } from "../../helpers/error";
import { showMessage } from "../../helpers/table";
import CheckboxRowCustom from "../../component/common/CheckboxRowCustom";
import { withTranslation } from "react-i18next";

class ProductTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      listBDS: [],
      listBlock: [],
      listBuilding: [],
      listDerection: [],
      errors: [],
    };
  }

  componentDidMount = () => {
    this.getList();
  };

  getList = async () => {
    await getTakenData(GET_BLOCK_CATE_LIST).then((res) => {
      this.setState({
        listBDS: res.data.data,
        loading_list: false,
      });
    });
    await getTakenData(DIREICTION_PRODUCT).then((res) => {
      this.setState({
        listDerection: res.data.data,
      });
    });
  };
  selectBDS = (e) => {
    postDataWithParams(GET_BLOCK_URL, "", { category_block_id: e.target.value })
      .then((res) => {
        // console.log(res.data.data);
        this.setState({
          listBlock: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  selectBlock = (e) => {
    postDataWithParams(GET_BUILDING_URL, "", {
      category_block_id: e.target.value,
    })
      .then((res) => {
        this.setState({
          listBuilding: res.data.data,
        });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
    };
    formData.in_corner ? (formData.in_corner = 1) : (formData.in_corner = 2);
    formData.car_park ? (formData.car_park = 1) : (formData.car_park = 2);

    postData(CREATE_TEMP_PRODUCT, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          status: true,
        });
      })
      .catch((err) => {
        let errs = destructServerErrors(err);
        this.setState({
          errors: errs,
        });
      });
  };

  render() {
    const {
      listBDS,
      listBlock,
      listBuilding,
      listDerection,
      errors,
      status,
    } = this.state;
    const { t } = this.props;

    if (status) {
      return (
        <Redirect
          to={{
            pathname: "/product/template",
          }}
        />
      );
    }

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('productTemplateNew')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/product/template">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row new-product-temp pb-4">
            <div className="col-md-12">
              <p className=" new-product-temp-title">{t('basicInfomation')}</p>
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('apartmentTypeName')}
                name="name"
                ref="name"
                label={`${t('apartmentTypeName')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('bedroomNum')}
                name="number_bedroom"
                ref="number_bedroom"
                label={`${t('bedroomNum')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('bathroomNum')}
                name="number_bathroom"
                ref="number_bathroom"
                label={`${t('bathroomNum')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('areaInside')}
                name="area_inside"
                ref="area_inside"
                label={`${t('areaInside')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <Select
                className="form-control input-form form-control-product mb-3"
                name="direction_id"
                ref="direction_id"
                errors={errors}
                key_value="id"
                key_label="name"
                include_blank={t('direction')}
                data={listDerection}
                label={`${t('direction')}:`}
              />
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('areaBalcony')}
                name="area_balcony "
                ref="area_balcony"
                label={`${t('areaBalcony')}:`}
                errors={errors}
                type="text"
              />
            </div>

            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={t('originPrice')}
                name="price"
                ref="price"
                label={`${t('originPrice')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6"></div>
            <div className="col-md-6 d-flex">
              <CheckboxRowCustom
                errors={[]}
                id="car_park"
                ref="car_park"
                label={t('carPark')}
                value="car_park"
                name="car_park"
              />
            </div>
            <div className="col-md-6 d-flex">
              <CheckboxRowCustom
                errors={[]}
                id="in_corner"
                ref="in_corner"
                label={t('inCorner')}
                value="in_corner"
                name="in_corner"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('product')(ProductTemplate);
