import React from 'react';

const Button = ({loading, children, className, disabled, ...props}) =>{
    return (
        <button {...props} className={`${className} ${loading ? 'btn-loading' : ''}`} disabled={disabled || loading}>
            { loading && <i className="fa fa-circle-o-notch fa-spin"></i> }
            <span>{ children }</span>
        </button>
    );
}

export default Button;