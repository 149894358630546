import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/";
import { LIST_TRACKING_USER_DETAIL_URL } from "../../constants/Config";
import { postData } from "../../services/base_services";
import { useTranslation } from "react-i18next";

const DATA_POST = {
  user_id: 107,
  ip_address: null,
  to_date: null,
  from_date: null,
  per_page: 10,
};


const DetailTrackingUser = () => {
  const { t } = useTranslation('follow')
  const { id } = useParams();
  const [paginate, setPaginate] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const TABLE_HEADER = [
    {
      label: "Stt",
      index: "stt",
      option: {
        className: "text-center position-relative",
      },
      callback: null,
    },
    {
      label: t('screenName'),
      index: "name",
      option: {
        className: "text-center",
      },
    },
    {
      label: t('ipAddress'),
      index: "ip_address",
      option: {
        className: "text-center",
      },
    },
    {
      label: t('interactionAt'),
      index: "interaction_at",
      option: {
        className: "text-center",
      },
    },
  ];
  
  const changePage = (pageNumber, e) => {
    getTableData(pageNumber);
  };

  const getTableData = (page) => {
    setIsLoading(true);
    postData(LIST_TRACKING_USER_DETAIL_URL, {
      ...DATA_POST,
      page,
      user_id: id,
    }).then((res) => {
      setTableData(res.data.data);
      setPaginate(res.data.paginate);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getTableData(1);
  }, []);

  return (
    <div className="container-fluid container-shadow">
      <div className="row top-table">
        <div className="col-md-4 top-table-title">
          <p>{t('customerFollow')}</p>
        </div>
        <div className="col-md-8 position-relative"></div>
      </div>
      <div className="row">
        <div className="col-md-12 ">
          <Table
            fixed={true}
            tableHeaders={TABLE_HEADER}
            tableData={tableData}
            tablePaginate={paginate}
            isLoading={isLoading}
          />
        </div>
        <div className="table-pagenatie ml-auto">
          <div className="paginate-wrapper">
            <Paginate paginate={paginate} changePage={changePage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailTrackingUser;
