import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import { Link, withRouter } from "react-router-dom";
import { createUserRequest } from "../../actions/user";
import { getFormDataFromRef } from "../../helpers/form";
import InputPhoneCustom from "../../component/common/InputPhoneCustom";
import Datepicker from "../../component/common/Datepicker";
import Select from "../../component/common/Select";
import { showMessage } from "../../helpers/table";
import { getTakenData } from "../../services/base_services";
import { LIST_ROLES } from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";

class AccountNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      listRoles: [],
      isLoading: true,
    };
  }

  componentDidMount = async () => {
    let listRoles = await getTakenData(LIST_ROLES);
    this.setState({
      listRoles: listRoles.data.data,
      isLoading: false,
    });
  };

  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    let val = formData.phone;
    formData.phone = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`;
        } else if (s) {
          return `${f}${s}`;
        } else if (f) {
          return `${f}`;
        }
      });
    createUserRequest(formData)
      .then((res) => {
        showMessage('Tạo người dùng thành công', true);
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ errors: err.err });
      });
  };

  render() {
    let { errors, listRoles, isLoading } = this.state;

    return isLoading ? (
      ""
    ) : (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>Thêm mới người dùng</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text="Lưu thông tin" />
              <Link to="/account">
                <ButtonSave text="Hủy" className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row top-table">
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Họ và tên"
                name="full_name"
                ref="full_name"
                label="Họ và tên:"
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputPhoneCustom
                name="phone"
                ref="phone"
                label="Số điện thoại:"
                type="text"
                className="form-control input-form"
                placeholder="Số điện thoại"
                errors={errors}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <Datepicker
                name="birthday"
                ref="birthday"
                label="Ngày sinh:"
                defaultValue={null}
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Địa chỉ"
                name="address"
                ref="address"
                label="Địa chỉ:"
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Email"
                name="email"
                ref="email"
                label="Email:"
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <Select
                className="form-control input-form"
                name="role_id"
                ref="role_id"
                label="Quyền:"
                include_blank="Quyền"
                key_value="id"
                key_label="name"
                data={listRoles}
                errors={errors}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(AccountNew);
