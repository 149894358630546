import { postDataWithParams, getDataByID, postData, putData, putDataWithParams } from './../services/base_services';
import { GET_LIST_CUSTOMER_URL, DETAIL_CUSTOMER_URL, CREATE_CUSTOMER_URL, UPDATE_CUSTOMER_URL, GET_FAVORITE_CUSTOMER_URL } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors, destructServerMessage } from '../helpers/error';

const customerRequest = status => {
  return {
    type: types.CUSTOMER_REQUEST,
    status
  }
}

// GET LIST CUSTOMER
const getCustomer = (data, paginate) => {
  return {
    type: types.GET_CUSTOMER_LIST,
    data,
    paginate
  }
}

export const getCustomerRequest = (search, params) => dispatch => {
  dispatch(customerRequest(true));
  postDataWithParams(GET_LIST_CUSTOMER_URL, search, params).then(res => {
    dispatch(getCustomer(res.data, res.paginate));
    dispatch(customerRequest(false));
  }).catch(error => {
    dispatch(customerRequest(false));
    return Promise.reject(error)
  })
}
// RESET
export const resetState = () => {
  return {
    type: types.RESET_CUSTOMER
  }
}
// CREATE
export const createCustomerRequest = (data) => {
  return postData(CREATE_CUSTOMER_URL, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// UPDATE
export const updateCustomerRequest = (id, data) => {
  return putData(UPDATE_CUSTOMER_URL, id, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET
export const getDetailCustomerRequest = (id) => {
  return getDataByID(DETAIL_CUSTOMER_URL, id).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}
// GET LIST FAVORITE CUSTOMER
export const getListFavoriteCustomer = (id, search, params) => {
  return putDataWithParams(GET_FAVORITE_CUSTOMER_URL, id, search, params).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerMessage(err);
    return Promise.resolve({ err: msg });
  })
}