import React from "react";

const ButtonSmall = (props) => {
  const { className, label, ...attrs } = props;
  return (
    <button
      type="button"
      className={`btn__small ${className ? className : ""}`}
      {...attrs}
    >
      <span className="babel__small">{label ? label : "Xem thêm"}</span>
    </button>
  );
};

export default ButtonSmall;
