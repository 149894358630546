import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { hasError, getError } from '../../helpers/error';
import Message from './../../component/form/Message';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

export const Alert = (props) => {
  return props.messsage ? (
    <div className='invalid-feedback'> {props.messsage} </div>
  ) : (
    ''
  );
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {},
      message: null
    };
  }

  checkError = (errors, key) => {
    return hasError(errors, key) ? 'has-errors' : '';
  };

  onSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: this.refs.email.value
    };
    this.props.submitForm(data);
  };
  showMessage(message) {
    return message ? <Message>{message}</Message> : '';
  }

  render() {
    let { errors, isLoading, message, t } = this.props;
    return (
      <div className='form-body'>
        <form onSubmit={this.onSubmit}>
          {this.showMessage(message)}
          <div className={`form-group ${this.checkError(errors, 'email')}`}>
            <input
              type='text'
              name='email'
              placeholder='Email'
              className='form-control form-input'
              ref='email'
            />
            <Alert messsage={getError(errors, 'email')} />
          </div>
          <div className='form-button'>
            <button className='btn btn-custom' disabled={isLoading}>
              {t('forgotPass')}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

export default connect(null, null)(withTranslation('login')(withRouter(Form)));
