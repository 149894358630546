import User from './user';
import Customer from './customer';
import Sale from './sale';
import Distribution from './distribution';

export default {
    User,
    Customer,
    Sale,
    Distribution
}