import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import InputCurrency from "../../component/common/InputCurrency";
import { putData, getDataByID } from "../../services/base_services";
import { UPDATE_PHASE_URL, GET_DETAIL_PHASE_URL } from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  destructServerErrors,
  destructServerMessage,
} from "../../helpers/error";
import { showMessage } from "../../helpers/table";
import Datepicker from "../../component/common/Datepicker";
import TextArea from "../../component/common/TextArea";
import { withTranslation } from "react-i18next";

class ProductPhaseEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      errors: [],
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      await getDataByID(GET_DETAIL_PHASE_URL, id)
        .then((res) => {
          this.setState({
            data: res.data.data,
            isLoading: false,
          });
          return Promise.resolve({ res });
        })
        .catch((error) => {
          let errs = destructServerMessage(error);
          return Promise.reject(errs);
        });
    }
  };

  addNew = (e) => {
    let { id } = this.props.match.params;
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    let val_start = formData.start_date;
    let val_end = formData.end_date;
    formData.start_date = val_start?.split("-").reverse().join("-");
    formData.end_date = val_end?.split("-").reverse().join("-");
    formData = { ...formData, description: this.description };
    putData(UPDATE_PHASE_URL, id, formData)
      .then((res) => {
        showMessage(this.props.t('updateSuccess', {model: this.props.t('phase')}), true);
        this.setState({
          errors: [],
          status: true,
        });
      })
      .catch((err) => {
        let errs = destructServerErrors(err);
        this.setState({
          errors: errs,
        });
      });
  };

  onChangeTextArea = (data) => {
    this.description = data;
  };

  render() {
    const { errors, status, data } = this.state;
    const { t } = this.props;
    
    if (status) {
      return (
        <Redirect
          to={{
            pathname: "/product/phase",
          }}
        />
      );
    }

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('phaseEdit')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/product/phase">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row new-product-temp pb-4">
            <div className="col-md-12">
              <p className=" new-product-temp-title">{t('basicInfomation')}</p>
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('phaseName')}
                name="name"
                ref="name"
                label={`${t('phaseName')}:`}
                errors={errors}
                type="text"
                defaultValue={data?.name}
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={t('openSaleNumber')}
                name="number_product"
                ref="number_product"
                errors={errors}
                type="text"
                label={`${t('openSaleNumber')}:`}
                defaultValue={data?.number_product}
              />
            </div>
            <div className="col-md-6">
              <Datepicker
                defaultValue={data?.start_date}
                name="start_date"
                ref="start_date"
                label={`${t('startAt')}:`}
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
              />
            </div>
            <div className="col-md-6">
              <Datepicker
                defaultValue={data?.end_date}
                name="end_date"
                ref="end_date"
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
                label={`${t('endAt')}:`}
              />
            </div>
            <div className="col-md-12">
              <TextArea
                name="description"
                ref="description"
                label={`${t('note')}:`}
                className="text-area-custom"
                errors={errors}
                rows="3"
                onChange={this.onChangeTextArea}
                defaultValue={data?.description}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('product')(withRouter(ProductPhaseEdit));
