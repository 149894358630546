/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-sparse-arrays */
import React, { Component } from "react";
import filterIcon from "../../assets/images/filter.png";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import PerPage from "../../component/Paginate/PerPage";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getDistributorRequest } from "../../actions/distributor";
import Message from "../../component/common/Message";
import { DELETE_DISTRIBUTOR_URL } from "../../constants/Config";
import { deleteById } from "../../services/base_services";
import { confirmDelete, afterRemove, showMessage } from "./../../helpers/table";
import InputSearch from "../../component/common/InputSearch";
import NewButton from "../../component/common/NewButton";
import detailIcon from "../../assets/images/detail.svg";
import Can from "../../services/Can";
import { PER_PAGE } from "../../constants/Variable";
import ButtonSmall from "../../component/common/ButtonSmall";
import { withTranslation } from "react-i18next";

class DistributorTable extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.message = null;
    this.state = {
      perPage: PER_PAGE,
      paginate: [],
      tableHeaders: [
        {
          label: "Stt",
          index: "stt",
          option: {
            className: "text-center position-relative",
          },
          callback: null,
        },
        {
          label: "distributorName",
          index: null,
          option: {
            className: "",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.name}</span>
              </div>
            );
          },
        },
        {
          label: "contact",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.phone}</span>
              </div>
            );
          },
        },
        ,
        {
          label: "cartQuantity",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.total}</span>
              </div>
            );
          },
        },
        ,
        {
          label: "reservationQuantity",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.reservation}</span>
              </div>
            );
          },
        },
        ,
        {
          label: "depositQuantity",
          index: null,
          option: {
            className: "text-center",
          },
          callback: (data) => {
            return (
              <div>
                <span>{data && data.deposit}</span>
              </div>
            );
          },
        },
        {
          label: "functions",
          index: null,
          option: {
            className: "text-center fixed-collumn",
          },
          callback: (data) => {
            if (data.id) {
              const { t } = this.props;

              return (
                <div className="btn-group">
                  <Can I="distributor.show" a="distributors">
                    <span
                      data-tip={t('detailDistributor')}
                      className="link-action mr-2"
                    >
                      <img
                        src={detailIcon}
                        className="btn-icon"
                        onClick={() => this.detailDistributor(data.id)}
                      />
                    </span>
                  </Can>
                  <Can I="distributor.edit" a="distributors">
                    <span
                      data-tip={t('editDistributor')}
                      className="link-action ml-2 mr-2"
                    >
                      <img
                        src={editIcon}
                        className="btn-icon"
                        onClick={() => this.editDistributor(data.id)}
                      />
                    </span>
                  </Can>
                  <Can I="distributor.delete" a="distributors">
                    <span
                      data-tip={t('deleteDistributor')}
                      className="link-action ml-2"
                    >
                      <img
                        src={deleteIcon}
                        className="btn-icon"
                        onClick={() => this.onDeleteDistributor(data.id)}
                      />
                    </span>
                  </Can>
                  <ReactTooltip
                    className="tooltip-button"
                    place="bottom"
                    effect="float"
                  />
                </div>
              );
            }
          },
        },
      ],
    };
  }

  detailDistributor = (id) => {
    this.props.history.push(`/distributor/${id}/chart`);
  };

  editDistributor = (id) => {
    this.props.history.push(`/distributor/${id}/edit`);
  };

  onDeleteDistributor = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeDistributorRequest(id);
      }
    });
  };

  removeDistributorRequest = (id) => {
    deleteById(DELETE_DISTRIBUTOR_URL, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.loadDistributor();
      })
      .catch((err) => {
        afterRemove("Có lỗi xảy ra.", false);
      });
  };

  componentDidMount() {
    this.loadDistributor();
  }

  loadDistributor = () => {
    let { page, perPage } = this.state;
    this.props.getDistributor(this.search, { page: page, per_page: perPage });
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getDistributor(this.search, {
        page: pageNumber,
        per_page: perPage,
      });
      this.setState({ page: pageNumber });
    }
  };



  onChangeSearch = (e) => {
    clearTimeout(this.timer);
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";

    this.search = {
      ...this.search,
      [name]: value,
    };

    this.timer = setTimeout(
      () =>
        this.props.getDistributor(this.search, {
          page: page,
          per_page: perPage,
        }),
      500
    );
  };

  render() {
    let { distributor, isLoading, t } = this.props;
    let tableData = distributor && distributor.data;
    let paginate = distributor && distributor.paginate;
    let { tableHeaders, per_page } = this.state;
    tableHeaders = tableHeaders.map(item => ({...item, label: t(item.label)}));

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-4 top-table-title">
              <p>{t('distributorList')}</p>
            </div>
            <div className="col-md-12 d-flex ">
              <div className=" d-flex">
                <Can I="distributor.list-search" a="distributors">
                  <InputSearch onChange={this.onChangeSearch} placeholder={t('search')}/>
                </Can>
                <Can I="distributor.add" a="distributors">
                  <Link to="/distributor/new" className="btn-add-custom ml-2">
                    <ButtonSmall label={t('new')} />
                  </Link>
                </Can>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <Table
                fixed={true}
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    distributor: state.Distributor,
    isLoading: state.Distributor.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getDistributor: (data, params) =>
      dispatch(getDistributorRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('distributor')(withRouter(DistributorTable)));
