import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import Profile from './../container/Profile';
import AuthRoute from './AuthRoute';

const routes = [
  {
    path: '/profile',
    component: () => <Profile />,
    exact: true,
  },
  
];


class ProfileRoutes extends Component {
  render() {
    if (typeof (window) !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
                {
                  routes.map(route => (
                    <AuthRoute key={route.path} {...route} />
                  ))
                }
          </Switch>
        </React.Fragment>
      )
    }
  }
}

export default ProfileRoutes;
