import React, { Component } from 'react';
import { Switch } from 'react-router-dom'
import ForgetPassword from './../container/ForgetPassword';
import AuthRoute from './AuthRoute';

const routes = [
  {
    path: '/forgot',
    component: () => <ForgetPassword />,
    exact: true,
  },
  
];


class ForgetPasswordRoutes extends Component {
  render() {
    if (typeof (window) !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
                {
                  routes.map(route => (
                    <AuthRoute key={route.path} {...route} />
                  ))
                }
          </Switch>
        </React.Fragment>
      )
    }
  }
}

export default ForgetPasswordRoutes;
