import React, { Component } from 'react';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';

const InputPassword = React.forwardRef((props, ref) => {
  let { errors,row, ...input } = props;
  return (
    <div className={row ? `${checkError(errors, input.name)} d-flex flex-wrap` : checkError(errors, input.name)} >
      {input.label && <label className={row ? "input-label input-label-row" : "input-label"}>{input.label}</label>}
      <input type="password" {...input} ref={ref} />
      <Alert messsage={getError(errors, input.name)} row={row}/>
    </div>
  )
});

export default InputPassword;
