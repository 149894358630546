import React from "react";
import store from "./../store/store";
import { Route, Redirect } from "react-router-dom";
import Can from "../services/Can";

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const {
        user: { authenticated },
      } = store.getState();
      return authenticated ? (
        rest.meta ? (
          <Can I={rest.meta.action} a={rest.meta.subject}>
            <Component {...props} />
          </Can>
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

export default AuthRoute;
