import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Label, ResponsiveContainer } from 'recharts';


const DistributorCanvas = (props) => {
  const { t } = useTranslation('distributor');

  const data = [
    {
      "uv": 4000,
      "pv": 2400,
      "amt": 2400
    },
    {
      "uv": 3000,
      "pv": 1398,
      "amt": 2210
    },
    {
      "uv": 2000,
      "pv": 9800,
      "amt": 2290
    },
    {
      "uv": 2780,
      "pv": 3908,
      "amt": 2000
    },
    {
      "uv": 1890,
      "pv": 4800,
      "amt": 2181
    },
    {
      "uv": 2390,
      "pv": 3800,
      "amt": 2500
    },
    {
      "uv": 3490,
      "pv": 4300,
      "amt": 2100
    }
  ]

  return (
    <>

      <ResponsiveContainer>
        <LineChart data={data}
          margin={{ top: 40, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="none">
            <Label value={t('time')} offset={0} position="insideBottomRight" />
          </XAxis>
          <YAxis>
            <Label value={t('customerCount')}  position="top" offset={20} dx={50} />
          </YAxis>
          <Legend />
          <Line type="monotone" dataKey="pv" name={t('total')} stroke="#FC4300" />
          <Line type="monotone" dataKey="uv" name={t('totalNew')} stroke="#002643" />
        </LineChart>
      </ResponsiveContainer>


    </>
  )
}
export default DistributorCanvas