import React, { Component } from "react";
import filterIcon from "../../assets/images/filter.png";
import ReactTooltip from "react-tooltip";
import editIcon from "../../assets/images/edit.svg";
import deleteIcon from "../../assets/images/cancel.svg";
import Table from "../../component/common/Table";
import Paginate from "../../component/Paginate/index";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getRolesRequest } from "../../actions/roles";
import Message from "../../component/common/Message";
import { confirmDelete, showMessage } from "../../helpers/table";
import { deleteById } from "../../services/base_services";
import { DELETE_PERMISSIONS_URL } from "../../constants/Config";
import ButtonSmall from "../../component/common/ButtonSmall";
import InputSearch from "../../component/common/InputSearch";
import NewButton from "../../component/common/NewButton";
import Can from "../../services/Can";
import { PER_PAGE } from "../../constants/Variable";
import { withTranslation } from "react-i18next";

class RolesTable extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.message = null;
    this.state = {
      perPage: PER_PAGE,
      paginate: [],
    };
  }

  componentDidMount() {
    this.loadRoles();
  }

  loadRoles = () => {
    let { page, perPage } = this.state;
    this.props.getRoles(this.search, { page: page, per_page: perPage });
  };
  onEditRoles = (id) => {
    this.props.history.push(`/roles/${id}/edit`);
  };

  onDeleteRoles = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeRolesRequest(id);
      }
    });
  };

  removeRolesRequest = (id) => {
    deleteById(DELETE_PERMISSIONS_URL, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.loadRoles();
      })
      .catch((err) => {
        showMessage(err.data.message, false);
      });
  };

  onChangeSearch = (e) => {
    if (e.keyCode === 13) {
      let { page, perPage } = this.state;
      let target = e.target;
      let name = target.name;
      let value = target.value ? target.value : "";

      this.search = {
        ...this.search,
        [name]: value,
      };

      this.props.getRoles(this.search, { page: page, per_page: perPage });
    }
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getRoles(this.search, {
        page: pageNumber,
        per_page: perPage,
      });
      this.setState({ page: pageNumber });
    }
  };

  render() {
    let { t, roles, isLoading } = this.props;
    let tableData = roles && roles.data;
    let paginate = roles && roles.paginate;
    let { per_page } = this.state;
    const tableHeaders = [
      {
        label: "Stt",
        index: "stt",
        option: {
          className: "text-center position-relative",
        },
        callback: null,
      },
      {
        label: t('roleName'),
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>{data && data.name}</span>
            </div>
          );
        },
      },
      {
        label: t('quantity'),
        index: null,
        option: {
          className: "text-center",
        },
        callback: (data) => {
          return (
            <div>
              <span>{data && data.number}</span>
            </div>
          );
        },
      }
      ,
      {
        label: t('userName'),
        index: null,
        callback: (data) => {
          return (
            <div>
              <span>
                {data && data.users.map((user) => user.name + ",")}
              </span>
            </div>
          );
        },
      },
      {
        label: t('functions'),
        index: null,
        option: {
          className: "text-center fixed-collumn",
        },
        callback: (data) => {
          if (data.id) {
            return (
              <div className="btn-group">
                <Can I="role.edit" a="roles">
                  <span
                    data-tip={t('editRole')}
                    className="link-action mr-2"
                  >
                    <img
                      src={editIcon}
                      alt="img"
                      className="btn-icon"
                      onClick={() => this.onEditRoles(data.id)}
                    />
                  </span>
                </Can>
                <Can I="role.delete" a="roles">
                  <span
                    data-tip={t('deleteRole')}
                    className="link-action ml-2"
                  >
                    <img
                      src={deleteIcon}
                      alt="img"
                      className="btn-icon"
                      onClick={() => this.onDeleteRoles(data.id)}
                    />
                  </span>
                </Can>
                <ReactTooltip
                  className="tooltip-button"
                  place="bottom"
                  effect="float"
                />
              </div>
            );
          }
        },
      },
    ];

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-4 top-table-title">
              <p>{t('roleManagement')}</p>
            </div>
            <div className="col-md-8 d-flex">
              <div className="ml-auto d-flex">
                <InputSearch onKeyDown={this.onChangeSearch} placeholder={t('search')} />
                <Can I="role.add" a="roles">
                  <Link to="/roles/new" className="btn-add-custom ml-2">
                    <ButtonSmall label={t('new')} />
                  </Link>
                </Can>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Message message={this.message} />
            </div>
            <div className="col-md-12 ">
              <Table
                fixed={true}
                tableHeaders={tableHeaders}
                tableData={tableData}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    roles: state.Roles,
    isLoading: state.Roles.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getRoles: (data, params) => dispatch(getRolesRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('role')(withRouter(RolesTable)));
