import { postDataWithParams, getDataByID, getTakenData, postData, putData } from './../services/base_services';
import { GET_BLOCK_URL } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors, destructServerMessage } from '../helpers/error'

const blockRequest = status => {
    return {
        type: types.BLOCK_REQUEST,
        status
    }
}

const getBlock = (data, paginate) => {
    return {
        type: types.GET_BLOCK_LIST,
        data,
        paginate
    }
}

export const getBlockRequest = (search, params) => dispatch => {
    dispatch(blockRequest(true));
    postDataWithParams(GET_BLOCK_URL, search, params).then(res => {
        dispatch(getBlock(res.data, res.paginate));
        dispatch(blockRequest(false));
    }).catch(error => {
        dispatch(blockRequest(false));
        return Promise.reject(error)
    })
}

export const resetState = () => {
    return {
        type: types.RESET_BLOCK_BDS
    }
}



