import React, { useState, useEffect } from 'react';
import { checkError, getError, checkErrorMultikey } from '../../helpers/error';
import { uploadAvatar } from '../../helpers/form';
import Alert from './Alert';
import defaultIMG from '../../assets/images/image.svg'
import upload from '../../assets/images/upload.svg'
import { BASE_IMG } from '../../constants/Config'
const FileImage = React.forwardRef((props, ref) => {
  let { errors, defaultValue, label, name, order, ...input } = props;
  let default_img = defaultValue;


  const [image, setImage] = useState(default_img);
  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState(errors);


  const onChangeImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      setLoading(true);
      uploadAvatar(file).then(res => {
        setImage(`${BASE_IMG}${res.file}`);
        setErrors(res.error_upload)
        setLoading(false);
        document.querySelector(`#upload-label-${order}`).style.display = 'none'
      }).catch(err => {
        setErrors(err.error_upload)
        setLoading(false);
      });
    }
  }

  const clickUpload = () => {
    document.querySelector(`#upload-input-${order}`).click();
  }


  return (
    <div className={checkErrorMultikey(error, ['file', name])}>
      {label && <label className="input-label">{label}</label>}
      <div className="preview-img">
        {loading && <div id="uploading"></div>}
        <input type="file" className="upload-file" id={`upload-input-${order}`} onChange={(e) => onChangeImage(e)} />
        <img className="image" src={image} onClick={clickUpload} />
        <input type="hidden" ref={ref} value={image} />
        {
          defaultValue ? "" : <label className="upload-label" id={`upload-label-${order}`} onClick={clickUpload}>
            <img src={upload} /> Upload Image
        </label>
        }
      </div>

    </div>
  )
});

export default FileImage;