import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.scss';
import App from './app/App';
import store from './store/store';
import { I18nextProvider } from 'react-i18next';
import i18n from './services/i18n';

import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div className='loader'></div>}>
        <App />
      </Suspense>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
