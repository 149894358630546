import { usePost } from './index';
import services from '../services';

export const useCustomers = () => {
  const [{data, loading, paginate}, handleSubmit] = usePost(services.Customer.getCustomers);
  return [{customers: data, loading, paginate}, handleSubmit];
};

export const useDevideCustomer = (successCallback, failedCallback) => {
  const [{loading}, handleSubmit] = usePost(services.Customer.devideCustomerDistribution, successCallback, failedCallback);
  return [{formLoading: loading}, handleSubmit];
};