import React, { Component } from "react";
import GroupImageDetailProduct from "../../component/common/GroupImageDetailProduct";
import { getDataByID, putData, deleteById } from "../../services/base_services";
import {
  GET_PRODUCT_INFO,
  GET_LIST_FILE_PRODUCT_TEMPLATE,
  BASE_IMG,
  CREATE_FILE_PRODUCT,
  GET_LIST_FILE_PRODUCT_SELECT,
  DELETE_LIST_FILE_PRODUCT,
} from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
import { Link, Redirect, withRouter } from "react-router-dom";
import {
  destructServerMessage,
  destructServerErrors,
} from "../../helpers/error";
import ProductManager from "./ProductManager";
import addIcon from "../../assets/images/icons/more.png";
import uploadIcon from "../../assets/images/icons/upload-img.png";
import NewCategory from "./NewCategory";
import { uploadDocument } from "../../helpers/form";
import { Menu } from "antd";
import { showMessage, confirmDelete, afterRemove } from "./../../helpers/table";
import CheckboxRow from "../../component/common/CheckboxRow";
import InputText from "../../component/common/InputText";
import Select from "../../component/common/Select";
import ButtonSmall from "../../component/common/ButtonSmall";
import openMenu from "./../../assets/images/icons/open_menu.svg";
import closeMenu from "./../../assets/images/icons/close_menu.svg";
import { getFormDataFromRef } from "../../helpers/form";
import { withTranslation } from "react-i18next";

const { SubMenu } = Menu;

class ProductEditFile extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.id = this.props.match.params.id;
    this.state = {
      isLoading: true,
      listDis: [],
      listTemp: [],
      listBlock: [],
      listBuilding: [],
      listFloor: [],
      status: false,
      listDerection: [],
      errors: [],
      openModelNew: false,
      openKeys: [],
      dataListFIle: [],
      idCategory: null,
      path: [],
      name: "",
      parent: null,
      name_parent: "",
      rootSubmenuKeys: [],
      compare_flg: false,
      isShowUpdate: false,
      fileListSelect: [],
      template_product_id: null
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      await getDataByID(GET_PRODUCT_INFO, id)
        .then((res) => {
          this.setState({
            data: res.data.data,
            isLoading: false,
            template_product_id: res?.data?.data?.template_product_id
          });
          return Promise.resolve({ res });
        })
        .catch((error) => {
          let errs = destructServerMessage(error);
          return Promise.reject(error);
        });
      this.getListCategory();
      this.loadFileSelectList(id);
    }
  };

  loadFileSelectList = async (id) => {
    await getDataByID(GET_LIST_FILE_PRODUCT_SELECT, id).then((res) => {
      this.setState({
        fileListSelect: res.data.data,
      });
    });
  };

  onChangeDocument = (data) => {
    this.setState({
      path: data,
    });
  };

  getListCategory = async () => {
    console.log(this.state.template_product_id);
    await getDataByID(GET_LIST_FILE_PRODUCT_TEMPLATE, this.state.template_product_id)
      .then((res) => {
        const arrTmp = res?.data?.data?.map((item) => {
          return item.id.toString();
        });
        this.setState({
          dataListFIle: res.data.data,
          isLoading: false,
          rootSubmenuKeys: arrTmp,
        });
        return Promise.resolve({ res });
      })
      .catch((error) => {
        let errs = destructServerMessage(error);
        return Promise.reject(error);
      });
  };

  addNew = (e) => {
    e.preventDefault();
    const { id } = this.props.match.params;
    const { path, name, parent } = this.state;
    let formData = getFormDataFromRef(this.refs);
    formData = {
      ...formData,
      path: path,
      name: formData.name ? formData.name : name,
      parent: formData.parent ? formData.parent : parent,
      product_id: this.id,
      compare_flg: this.myRef.current.checked ? 1 : 2,
    };
    putData(CREATE_FILE_PRODUCT, this.state.idCategory, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({
          errors: [],
          compare_flg: this.myRef.current.checked,
          name: formData.name ? formData.name : name,
        });
        this.getListCategory(id);
      })
      .catch((err) => {
        if (this.state.idCategory) {
          let errs = destructServerErrors(err);
          this.setState({
            errors: errs,
          });
        } else {
          showMessage(this.props.t('noImageSelectMsg'), false);
        }
      });
  };

  openModelAdd = (id) => {
    this.setState({
      openModelNew: true,
      idCategory: id,
    });
  };

  newFolder = (status) => {
    this.setState({
      openModelNew: status,
    });
  };

  onOpenChange = (keys) => {
    const latestOpenKey = keys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    );
    if (this.state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({
        openKeys: keys,
      });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  itemMenu = (listChild, id) => {
    var result = null;
    result = listChild?.map((item, index) => {
      return (
        <SubMenu
          key={item.id}
          onTitleClick={(e) =>
            this.getItem(
              e,
              item.id,
              item.path,
              item.name,
              item.parent,
              item.name_parent,
              item.compare_flg
            )
          }
          title={item.name}
        >
          {this.itemMenu(item.children, item.id)}
        </SubMenu>
      );
    });
    return result;
  };

  getAgain = () => {
    let { id } = this.props.match.params;
    this.getListCategory(id);
    this.loadFileSelectList(id);
  };

  getItem = (e, id, path, name, parent, name_parent, compare_flg) => {
    this.setState({
      idCategory: id,
      path,
      name,
      name_parent,
      parent,
      compare_flg: compare_flg === 1 ? true : false,
      isShowUpdate: false,
    });
  };

  clickUpload = () => {
    document.querySelector("#upload-input").click();
  };

  onChangeImage = (e) => {
    let file = e.target.files[0];
    if (file) {
      uploadDocument(file)
        .then((res) => {
          let data = `${BASE_IMG}${res.data.file}`;
          let image = [data, ...this.state.path];
          this.setState({ path: image, errors: {} });
          this.props.onChange(image);
        })
        .catch((err) => {
          this.setState({ errors: err.error_upload });
        });
    }
  };

  showFormEdit = () => {
    this.setState({
      isShowUpdate: !this.state.isShowUpdate,
    });
  };

  onDelete = (id) => {
    confirmDelete().then((res) => {
      if (res.value == true) {
        this.removeFileRequest(id);
      }
    });
  };

  removeFileRequest = (id) => {
    let idCate = this.props.match.params.id;
    deleteById(DELETE_LIST_FILE_PRODUCT, id)
      .then((res) => {
        showMessage(res.data.message, true);
        this.getListCategory(idCate);
        this.setState({
          name: "",
          isShowUpdate: false,
          path: [],
        });
      })
      .catch((err) => {
        afterRemove(this.props.t('responseFail'), false);
      });
  };

  render() {
    const {
      errors,
      status,
      isLoading,
      openModelNew,
      dataListFIle,
      idCategory,
      path,
      compare_flg,
      name,
      parent,
      isShowUpdate,
      fileListSelect,
      name_parent,
    } = this.state;
    const { t } = this.props;

    let checkboxesAll = document.getElementsByName("compare_flg");
    if (checkboxesAll.length > 0) {
      if (compare_flg) {
        checkboxesAll[0].checked = true;
      } else {
        checkboxesAll[0].checked = false;
      }
    }

    if (status) {
      return (
        <Redirect
          to={{
            pathname: "/product",
          }}
        />
      );
    }

    return isLoading ? (
      ""
    ) : (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('productUpdateTitle')}</p>
            </div>
            <div className="col-md-6 d-flex pl-0">
              <Link to="/product" className="ml-auto">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          {openModelNew && (
            <NewCategory
              idProduct={this.id}
              idCategory={idCategory}
              className="modal-small"
              newFolder={this.newFolder}
              open={openModelNew}
              getAgain={this.getAgain}
            />
          )}

          <ProductManager idProduct={this.id} />
          <div className="row detail-product-picture">
            <div className="col-4">
              <div className="block-left">
                <p className="title-menu">{t('productImageCategory')}</p>
                <Menu
                  mode="inline"
                  openKeys={this.state.openKeys}
                  onOpenChange={this.onOpenChange}
                >
                  {dataListFIle?.map((item, index) => {
                    return (
                      <SubMenu
                        key={item.id}
                        onTitleClick={(e) =>
                          this.getItem(
                            e,
                            item.id,
                            item.path,
                            item.name,
                            item.parent,
                            item.name_parent,
                            item.compare_flg
                          )
                        }
                        title={`${item.name}`}
                      >
                        {this.itemMenu(item.children, item.id)}
                      </SubMenu>
                    );
                  })}
                </Menu>
              </div>
            </div>
            <div className="col-8">
              <div className="block-right">
                <p className="title-menu" onClick={this.showFormEdit}>
                  {name}
                </p>
                <div className="mb-3 block-checkbox-custom">
                  <CheckboxRow
                    errors={[]}
                    id="compare_flg"
                    ref={this.myRef}
                    label={`${t('compareImage')}:`}
                    value="compare_flg"
                    name="compare_flg"
                    disabled
                    defaultChecked={compare_flg || false}
                  />
                </div>
                <GroupImageDetailProduct
                  label={t('interiorImages')}
                  name="layout_images"
                  title={t('selectForDownload')}
                  errors={errors}
                  onChange={this.onChangeDocument}
                  order={2}
                  listImg={path}
                  className="remove-delete-item"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('product')(withRouter(ProductEditFile));
