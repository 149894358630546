import React, { Component } from 'react'
import DistributorInfo from './DistributorInfo'
import DistributorCanvas from './DistributorCanvas'
import Table from '../../component/common/Table'
import { withTranslation } from 'react-i18next';

class DistributorChart extends Component {
  render() {
    const { t } = this.props;

    const tableHeaderCart = [
      {
        label: t('title'),
        index: "title",

      },
      {
        label: t('quantity'),
        index: "amount",
        option: {
          className: "text-center",
        },
      },
      {
        label: t('rate'),
        index: "percent",
        option: {
          className: "text-center",
        },
      }
    ];

    const tableHeaderSales = [
      {
        label: t('saleName'),
        index: "name",

      },
      {
        label: t('saleQuantity'),
        index: "amount",
        option: {
          className: "text-center",
        },
      },
      {
        label: t('revenue'),
        index: "money",
        option: {
          className: "text-center",
        },
      }
    ];

    const dataCart = [
      {
        id: 1,
        title: t('totalProduct'),
        amount: 150,
        percent: '100%'
      },
      {
        id: 2,
        title: t('reservationQuantity'),
        amount: 30,
        percent: '20%'
      }, {
        id: 3,
        title: t('depositQuantity'),
        amount: 50,
        percent: '33%'
      }, {
        id: 4,
        title:  t('paidQuantity'),
        amount: 10,
        percent: '5%'
      }
    ]

    const dataSales = [
      {
        id: 1,
        name: "Nguyen Nam",
        amount: 150,
        money: '10 milion'
      },
      {
        id: 2,
        name: "Van bac",
        amount: 30,
        money: '20k'
      }, {
        id: 3,
        name: "Van A",
        amount: 50,
        money: '30k'
      }, {
        id: 4,
        name: "Van B",
        amount: 10,
        money: '40k'
      }
    ]

    return (
      <>
        <div className="container-fluid container-shadow">
          <p className="name-header">{t('sampleDistributor')}</p>
          <DistributorInfo />

          <div className="row mt-5">
            <div className="col-md-4 name-header-part">
              <p>{t('customerInfo')}</p>
              <div className="customer-info">
                <span >
                {t('totalCustomer')}:
                </span>
                <p>
                  500 {t('customerUnit')}
                </p>
              </div>
              <div className="customer-info">
                <span >
                {t('totalNewCustomer')}:
                </span>
                <p>
                  100 {t('customerUnit')}
                </p>
              </div>
              <div className="customer-info">
                <span >
                {t('totalLike')}:
                </span>
                <p>
                  500 k
                </p>
              </div>
            </div>
            <div className='col-md-8'>
              <DistributorCanvas />
            </div>
          </div>

          <div className="border-chart"></div>
          <div className="row table-border">
            <div className="col-md-6 chart-table name-header-part px-5">
              <p>{t('cartInfo')}</p>
              <Table fixed={true} tableHeaders={tableHeaderCart} tableData={dataCart} isLoading={false} />
            </div>
            <div className="col-md-6 chart-table name-header-part px-5">
              <p>{t('topSale')}</p>
              <Table fixed={true} tableHeaders={tableHeaderSales} tableData={dataSales} isLoading={false} />
            </div>
          </div>
        </div>
      </>
    )
  }
}


export default withTranslation('distributor')(DistributorChart)