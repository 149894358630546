import React, { Component } from "react";
import Autocomplete from "react-autocomplete";
import { postData } from "../../services/base_services";
import { checkError, getError } from "../../helpers/error";
import Alert from "./../common/Alert";

class InputCustomer extends Component {
  constructor(props, context) {
    super(props, context);
    this.timer = null;
    this.source = props.source;
    this.state = {
      listUser: [],
      search_user: "",
      selected_user: "",
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.default_value !== prevState.default_value) {
      return { someState: nextProps.default_value };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.default_value !== this.props.default_value) {
      let { default_value } = this.props;
      if (default_value) {
        this.props.onChange(default_value.id);
        this.setState({
          search_user: default_value.name,
          selected_user: default_value.id,
        });
      }
    }
  }

  onAutoCompleteUser = (e) => {
    clearTimeout(this.timer);
    let search_user = e.target.value;
    this.setState({ search_user: search_user });
    this.loadingUser();
    this.timer = setTimeout(() => {
      this.triggerChange(search_user);
    }, 500);
  };

  loadingUser = () => {
    this.setState({
      listUser: [{ id: "", name: "Đang tải..." }],
    });
  };

  triggerChange = (targetValue) => {
    if (!targetValue) {
      this.props.onChange(null);
    }
    postData(this.source, { search_text: targetValue }).then((res) => {
      if (res.data.data.length > 0) {
        this.setState({ listUser: res.data.data });
      } else {
        this.setState({
          listUser: [{ id: "", name: "Không tìm thấy kết quả" }],
        });
      }
    });
  };

  onSelectUser = (e, value) => {
    if (value.id) {
      this.props.onChange(value.id);
      this.setState({
        search_user: value.name,
        selected_user: value.id,
      });
    }
  };

  // TURN OFF AUTOCOMPLETE CHROME
  onFocus = (event) => {
    if (event.target.autocomplete) {
      event.target.autocomplete = "whatever";
    }
  };

  render() {
    let { name, errors, label, default_value, source, ...input } = this.props;
    return (
      <div className={checkError(errors, name)}>
        {label && <label>{label}</label>}
        <Autocomplete
          getItemValue={(item) => item.name}
          items={this.state.listUser}
          renderItem={(item, isHighlighted) => (
            <div
              key={item.id}
              style={{ background: isHighlighted ? "lightgray" : "white" }}
            >
              {`${item.name}`}
            </div>
          )}
          renderMenu={function (items, value, style) {
            return (
              <div
                style={{ ...style, ...this.menuStyle }}
                className="menu-autocomplete"
                children={items}
              />
            );
          }}
          value={this.state.search_user}
          onChange={(e) => this.onAutoCompleteUser(e)}
          onSelect={(e, value) => this.onSelectUser(e, value)}
          inputProps={{ name: name, onFocus: this.onFocus, ...input }}
          wrapperProps={{ className: "autocomple-input" }}
        />
        <Alert messsage={getError(errors, name)} />
      </div>
    );
  }
}

export default InputCustomer;
