import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Can from '../../services/Can';
import { useCustomers } from '../../hooks/customer';
import { useQuery, usePagination } from '../../hooks/';
import { CUSTOMER_STATUS_COLORS, CUSTOMER_ASSIGN_STATUS } from '../../constants/Variable';
import { Table, Tag } from 'antd';
import DevideCustomerForm from './DevideCustomerForm';
import { objectToQuery } from '../../helpers';
import classNames from 'classnames';

const DevideCustomer = (props) => {
  let params = useQuery();
  const [{ customers, loading, paginate }, handleSubmit] = useCustomers();
  const history = useHistory();
  const [selectedCustomers, setSelectedCustomer] = useState([]);
  const [selectedKeys, setSelectedKey] = useState([]);
  const pagination = usePagination(paginate);

  const tableCloumns = [
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: "Số điện thoại",
      dataIndex: 'phone',
      key: 'template_product',
    },
    {
      title: "Email",
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: "Trạng thái",
      dataIndex: null,
      key: 'status',
      render: ({ status, status_lable }) => <Tag color={CUSTOMER_STATUS_COLORS[status]}>{status_lable}</Tag>,
    },
    {
      title: 'Nhà phân phối',
      dataIndex: null,
      key: 'saler',
      render: (res) => 'Không',
    },
  ];

  useEffect(() => {
    handleSubmit(params);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCustomer(selectedRows);
      setSelectedKey(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return {
        name: 'customer_id',
        key: record.id,
        disabled: record.status >= CUSTOMER_ASSIGN_STATUS
      }
    },
  };

  const handleTableChange = (pagination) => {
    params.page = pagination.current
    history.push({
      pathname: history.location.pathname,
      search: objectToQuery(params)
    });
    handleSubmit(params);
  }

  const resetSelected = useCallback(() => {
    setSelectedCustomer([]);
    setSelectedKey([]);
  }, [])

  // Reload table when assign success
  const onDevideSuccess = () => {
    handleSubmit(params);
    resetSelected();
  }

  return (
    <div className="customer-distribution">
      <div className="container-fluid container-shadow">

        <div className="top-table">
          <h3 className="title-cart-top">Phân phối khách Hàng</h3>
          <div className="button-title d-flex">
            <Can I="customer.list" a="customers">
              <Link to="/customer" className="btn btn-danger btn-sm">Trở về</Link>
            </Can>
          </div>
        </div>

        <div className="distribution-table">
          <div className={classNames('distribution-action', { show: selectedKeys.length > 0 })}>
            {
              selectedKeys.length > 0 && <DevideCustomerForm customers={selectedCustomers} onSuccess={onDevideSuccess} />
            }
            <button className="btn btn-outline-primary btn-sm ml-3" onClick={resetSelected}>
              Bỏ chọn
            </button>
            <span className="text-success text-small ml-3">({selectedKeys.length} khách hàng được chọn)</span>
          </div>
          <Table
            rowSelection={{
              ...rowSelection,
              type: 'checkbox',
              selectedRowKeys: selectedKeys
            }}
            rowKey={record => record.id}
            columns={tableCloumns}
            pagination={pagination}
            dataSource={customers}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>

      </div>
    </div>
  );
}

export default DevideCustomer;