import React, { Component } from "react";
import { Switch } from "react-router-dom";
import CartTable from "./../container/Cart/CartTable";
import TableAdd from "./../container/Cart/TableAdd";
import AuthRoute from "./AuthRoute";

const routes = [
  {
    path: "/cart",
    component: () => <CartTable />,
    exact: true
  },
  {
    path: "/cart/add/:id",
    component: () => <TableAdd />,
    exact: true
  }
];

class CartRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
            {routes.map((route) => (
              <AuthRoute key={route.path} {...route} />
            ))}
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default CartRoutes;
