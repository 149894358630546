import React, { Component } from "react";
import InputText from "../../component/common/InputText";
import InputCurrency from "../../component/common/InputCurrency";
import { postData } from "../../services/base_services";
import { NEW_PHASE_URL } from "../../constants/Config";
import ButtonSave from "../../component/common/ButtonSave";
import { getFormDataFromRef } from "../../helpers/form";
import { Link, Redirect } from "react-router-dom";
import { destructServerErrors } from "../../helpers/error";
import { showMessage } from "../../helpers/table";
import Datepicker from "../../component/common/Datepicker";
import TextArea from "../../component/common/TextArea";
import { withTranslation } from "react-i18next";

class ProductPhaseNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      errors: [],
    };
  }

  addNew = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    let val_start = formData.start_date;
    let val_end = formData.end_date;
    formData.start_date = val_start?.split("-").reverse().join("-");
    formData.end_date = val_end?.split("-").reverse().join("-");
    formData = { ...formData, description: this.description };
    postData(NEW_PHASE_URL, formData)
      .then((res) => {
        showMessage(this.props.t('createSuccess', {model: this.props.t('phase')}), true);
        this.setState({
          errors: [],
          status: true,
        });
      })
      .catch((err) => {
        let errs = destructServerErrors(err);
        this.setState({
          errors: errs,
        });
      });
  };

  onChangeTextArea = (data) => {
    this.description = data;
  };

  render() {
    const { errors, status } = this.state;
    const { t } = this.props;

    if (status) {
      return (
        <Redirect
          to={{
            pathname: "/product/phase",
          }}
        />
      );
    }

    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>{t('phaseNew')}</p>
            </div>
            <div className="col-md-6 d-flex">
              <ButtonSave onClick={this.addNew} text={t('save')} />
              <Link to="/product/phase">
                <ButtonSave text={t('cancel')} className="btn-delete" />
              </Link>
            </div>
          </div>
          <div className="row new-product-temp pb-4">
            <div className="col-md-12">
              <p className=" new-product-temp-title">{t('basicInfomation')}</p>
            </div>
            <div className="col-md-6">
              <InputText
                className="form-control input-form"
                placeholder={t('phaseName')}
                name="name"
                ref="name"
                label={`${t('phaseName')}:`}
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6">
              <InputCurrency
                className="form-control input-form"
                placeholder={t('openSaleNumber')}
                name="number_product"
                ref="number_product"
                errors={errors}
                type="text"
                label={`${t('openSaleNumber')}:`}
                defaultValue={null}
              />
            </div>
            <div className="col-md-6">
              <Datepicker
                defaultValue={null}
                name="start_date"
                ref="start_date"
                label={`${t('startAt')}:`}
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
              />
            </div>
            <div className="col-md-6">
              <Datepicker
                defaultValue={null}
                name="end_date"
                ref="end_date"
                errors={errors}
                clearIcon={false}
                className="form-control input-form"
                format={"dd-MM-yyyy"}
                label={`${t('endAt')}:`}
              />
            </div>
            <div className="col-md-12">
              <TextArea
                name="description"
                ref="description"
                label={`${t('note')}:`}
                className="text-area-custom"
                errors={errors}
                rows="3"
                onChange={this.onChangeTextArea}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation('product')(ProductPhaseNew);
