import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-multi-carousel/lib/styles.css';
import Routes from './../routes';

function App() {
  return (
    <Router>
      <Routes />
    </Router>
  );
}

export default App;
