import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Slider, Select } from "antd";

class FilterCart extends Component {
  constructor(props) {
    super(props);
    this.directions = [];
    this.message = null;
    this.search = "";
    this.state = {
      errors: {},
      value: "Choose the number of rooms",
      dataBedRoom: [],
      dataBathRoom: [],
      dataPrice: null,
      dataArea: null,
      dataFilter: [],
    };
  }

  handleChangeArea = (dataArea) => {
    this.setState({ dataArea });
  };
  handleChangePrice = (dataPrice) => {
    this.setState({ dataPrice });
  };
  handleChangeBedRoom = (dataBedRoom) => {
    this.setState({
      dataBedRoom,
    });
  };

  handleChangeBathRoom = (dataBathRoom) => {
    this.setState({ dataBathRoom });
  };

  handleClearAllFilter = () => {
    this.setState({
      dataBedRoom: [],
      dataBathRoom: [],
      dataPrice: "",
      dataArea: "",
    });
    this.directions = [];
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
  };

  onChoiceDirection = (e) => {
    const target = e.target;
    let value = parseInt(target.value);
    if (target.checked) {
      this.directions.push(value);
    } else {
      this.directions.splice(this.directions.indexOf(value), 1);
    }
  };

  render() {
    const Option = Select;
    const { max, min, listDirection } = this.props;
    const { dataBedRoom, dataBathRoom, dataArea, dataPrice } = this.state;
    return (
      <div className={`cart_filter ${this.props.className}`}>
        <div className="cart_filter_title">Filter Results</div>
        <div className="cart_filter_select_group p-2 d-flex">
          <div className="cart_filter--items d-flex">
            <label>BedRoom</label>
            <Select
              onChange={(e) => this.handleChangeBedRoom(e)}
              value={dataBedRoom}
              className=""
              mode="multiple"
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="All">All</Option>
            </Select>
          </div>
          <div className="cart_filter--items d-flex">
            <label>BathRooms</label>
            <Select
              onChange={(e) => this.handleChangeBathRoom(e)}
              value={dataBathRoom}
              mode="multiple"
              className=""
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="All">All</Option>
            </Select>
          </div>
          <div className="form-group">
            <label>AREA (m2)</label>
            <div className="icon-wrapper">
              <span>{min}</span>
              <Slider
                {...this.props}
                onChange={this.handleChangeArea}
                value={dataArea}
              />
              <span>{max}</span>
            </div>
          </div>
          <div className="form-group">
            <label>PRICE</label>
            <div className="icon-wrapper">
              <span>{min}</span>
              <Slider
                {...this.props}
                onChange={this.handleChangePrice}
                value={dataPrice}
              />
              <span>{max}</span>
            </div>
          </div>
          <div className="block-filter-direction row">
            {listDirection?.map((item) => {
              return (
                <div className="col-3">
                  <div className="item-direction">
                    <input
                      type="checkbox"
                      name="direction[]"
                      id={`direction-${item.id}`}
                      value={item.id}
                      onChange={this.onChoiceDirection}
                    />
                    <label htmlFor={`direction-${item.id}`}>
                      {item.key_name}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="cart_filter--group-btn">
          <button
            onClick={this.handleClearAllFilter}
            className="btn-custom text-uppercase"
          >
            clear all
          </button>
          <button
            onClick={() =>
              this.props.onSubmitFilter(
                dataBedRoom,
                dataBathRoom,
                dataArea,
                dataPrice,
                this.directions
              )
            }
            className="btn-custom text-uppercase"
          >
            apply filter
          </button>
        </div>
      </div>
    );
  }
}

export default withRouter(FilterCart);
