import { postDataWithParams, getDataByID, getTakenData, postData, putData } from './../services/base_services';
import { GET_BUILDING_URL } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors, destructServerMessage } from '../helpers/error'

const buildingRequest = status => {
    return {
        type: types.BUILDING_REQUEST,
        status
    }
}

const getBuilding = (data, paginate) => {
    return {
        type: types.GET_BUILDING_LIST,
        data,
        paginate
    }
}

export const getBuildingRequest = (search, params) => dispatch => {
    dispatch(buildingRequest(true));
    postDataWithParams(GET_BUILDING_URL, search, params).then(res => {
        dispatch(getBuilding(res.data, res.paginate));
        dispatch(buildingRequest(false));
    }).catch(error => {
        dispatch(buildingRequest(false));
        return Promise.reject(error)
    })
}

export const resetState = () => {
    return {
        type: types.RESET_BUILDING_BDS
    }
}



