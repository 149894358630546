import React, { useState, useEffect } from "react";
import {
  getError,
  checkErrorSingle,
  checkErrorMultikey,
} from "../../helpers/error";
import Alert from "./Alert";
import close from "../../assets/images/close.png";
import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const GroupImageDetailProduct = (props) => {
  const { name, listImg, onChange, className } = props;
  const [error, setError] = useState({});
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(listImg);
  }, [listImg]);

  const removeImage = (key) => {
    window._.remove(data, function (n, index) {
      return index == key;
    });
    setData([...data]);
    onChange(data);
  };

  return (
    <div
      className={`${checkErrorMultikey(error, [
        "file",
        name,
      ])} ${checkErrorSingle(error, "file")}`}
    >
      <div className={`upload-field upload-field-custom up-gr-img ${className}`}>
        <Carousel
          arrows={false}
          responsive={responsive}
          infinite={true}
          showDots={true}
        >
          {data.map((item, index) => (
            <div key={index}>
              <div className>
                <a data-fancybox="images" href={item} key={index}>
                  <img src={item} style={{ textAlign: "center" }} />
                </a>
              </div>
              <div className="remove-custom">
                <span className="remove" onClick={() => removeImage(index)}>
                  <img src={close} />
                </span>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
      <Alert messsage={getError(error, "file")} />
    </div>
  );
};

export default GroupImageDetailProduct;
