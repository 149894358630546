import React, { Component } from "react";
import { Switch } from "react-router-dom";
import SaleTable from "./../container/Sale/SaleTable";
import SaleNew from "./../container/Sale/SaleNew";
import SaleDetail from "./../container/Sale/SaleDetail";
import SaleApartment from "./../container/Sale/SaleApartment";
import SaleCustomer from "./../container/Sale/SaleCustomer";
import SaleHistory from "./../container/Sale/SaleHistory";
import AuthRoute from "./AuthRoute";

const routes = [
  {
    path: "/sale_list",
    component: () => <SaleTable />,
    exact: true,
    meta: {
      action: "sale.list",
      subject: "sales",
    },
  },
  {
    path: "/sale_list/new",
    component: () => <SaleNew />,
    exact: true,
    meta: {
      action: "sale.add",
      subject: "sales",
    },
  },
  {
    path: "/sale_list/:id/detail",
    component: () => <SaleDetail />,
    exact: true,
    meta: {
      action: "sale.show",
      subject: "sales",
    },
  },
  {
    path: "/sale_list/:id/apartment",
    component: () => <SaleApartment />,
    exact: true,
  },
  {
    path: "/sale_list/:id/customers",
    component: () => <SaleCustomer />,
    exact: true,
  },
  {
    path: "/sale_list/:id/history",
    component: () => <SaleHistory />,
    exact: true,
  },
];

class SaleRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <Switch>
            {routes.map((route) => (
              <AuthRoute key={route.path} {...route} />
            ))}
          </Switch>
        </React.Fragment>
      );
    }
  }
}

export default SaleRoutes;
