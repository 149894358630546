import React, { Component } from 'react';
import fakeMenu from '../../assets/images/logo-header-v2.png';
import openMenu from '../../assets/images/icons/bar-chart.png';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Can from '../../services/Can';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class Menu extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showMenu: true
    };
  }
  showMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    });
    if (this.state.showMenu) {
      document.querySelector('#menu').style.left = '-225px';
      document.querySelector('#list-menu').style.display = 'none';
      document.querySelector('#page-wrapper').style.marginLeft = '90px';
    } else {
      document.querySelector('#menu').style.left = '0';
      document.querySelector('#list-menu').style.display = 'block';
      document.querySelector('#page-wrapper').style.marginLeft = '280px';
    }
  };

  render() {
    const { setting, t } = this.props;
    return (
      <>
        <div className='left-menu' id='menu'>
          <div className='top-menu'>
            <div className='top-menu-child'>
              <img src={fakeMenu} className='left-menu-logo' alt='icon' />
            </div>
            <div className='m-auto'>
              <img
                className='right-menu-logo'
                src={openMenu}
                onClick={this.showMenu}
                alt='icon'
              />
            </div>
          </div>
          <ul id='list-menu' className='list-first'>
            <li>
              <NavLink
                to='/dashboard'
                activeClassName='active'
                className='nav-link-menu'
              >
                <p>{t('mainBoard')}</p>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/product'
                activeClassName='active'
                className='nav-link-menu'
              >
                <p>{t('productManagement')}</p>
                <ul className='list-sub'>
                  <li>
                    <NavLink
                      to='/product/status'
                      activeClassName='activesub'
                      className='nav-link-menu nav-link-menu-sub'
                    >
                      <p className='-sub'>{t('productStatus')}</p>
                    </NavLink>
                  </li>
                  {setting?.[0]?.status === 1 ? (
                    <li>
                      <NavLink
                        to='/product/bds'
                        activeClassName='activesub'
                        className='nav-link-menu nav-link-menu-sub'
                      >
                        <p className='-sub'>{t('realEstateType')}</p>
                      </NavLink>
                    </li>
                  ) : (
                    ''
                  )}
                  {setting?.[1]?.status === 1 ? (
                    <li>
                      <NavLink
                        to='/product/block'
                        activeClassName='activesub'
                        className='nav-link-menu nav-link-menu-sub'
                      >
                        <p className='-sub'>{t('blockType')}</p>
                      </NavLink>
                    </li>
                  ) : (
                    ''
                  )}
                  {setting?.[2]?.status === 1 ? (
                    <li>
                      <NavLink
                        to='/product/building'
                        activeClassName='activesub'
                        className='nav-link-menu nav-link-menu-sub'
                      >
                        <p className='-sub'>{t('buildingType')}</p>
                      </NavLink>
                    </li>
                  ) : (
                    ''
                  )}

                  <li>
                    <NavLink
                      to='/product/floor'
                      activeClassName='activesub'
                      className='nav-link-menu nav-link-menu-sub'
                    >
                      <p className='-sub'>{t('floorType')}</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/product/phase'
                      activeClassName='activesub'
                      className='nav-link-menu nav-link-menu-sub'
                    >
                      <p className='-sub'>{t('stageType')}</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/product/template'
                      activeClassName='activesub'
                      className='nav-link-menu nav-link-menu-sub'
                    >
                      <p className='-sub'>{t('prototypeProduct')}</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/product/floortemp'
                      activeClassName='activesub'
                      className='nav-link-menu nav-link-menu-sub'
                    >
                      <p className='-sub'>{t('prototypeFloor')}</p>
                    </NavLink>
                  </li>
                </ul>
              </NavLink>
            </li>
            <li>
              <Can I='cart.list-product' a='carts'>
                <NavLink
                  to='/cart'
                  activeClassName='active'
                  className='nav-link-menu'
                >
                  <p>{t('cartManagement')}</p>
                </NavLink>
              </Can>
            </li>
            <li>
              <Can I='distributor.list' a='distributors'>
                <NavLink
                  to='/distributor'
                  activeClassName='active'
                  className='nav-link-menu'
                >
                  <p>{t('distributorManagement')}</p>
                </NavLink>
              </Can>
            </li>
            <li>
              <NavLink
                to='/media'
                activeClassName='active'
                className='nav-link-menu'
              >
                <p>{t('documentManagement')}</p>
              </NavLink>
            </li>

            <li>
              <Can I='sale.list' a='sales'>
                <NavLink
                  to='/sale_list'
                  activeClassName='active'
                  className='nav-link-menu'
                >
                  <p>{t('saleManagement')}</p>
                </NavLink>
              </Can>
            </li>

            {/* <li>
              <Can I="sales_pocily.list" a="sales_pocilys">
                <NavLink
                  to="/sales_policy"
                  activeClassName="active"
                  className="nav-link-menu"
                >
                  <p>Quản lý chính sách bán hàng</p>
                </NavLink>
              </Can>
            </li> */}

            <li>
              <Can I='role.list' a='roles'>
                <NavLink
                  to='/roles'
                  activeClassName='active'
                  className='nav-link-menu'
                >
                  <p>{t('roleManagement')}</p>
                </NavLink>
              </Can>
            </li>

            <li>
              <Can I='customer.list' a='customers'>
                <NavLink
                  to='/customer'
                  activeClassName='active'
                  className='nav-link-menu'
                >
                  <p>{t('customerManagement')}</p>
                </NavLink>
              </Can>
            </li>

            {/* <li>
              <Can I="user.list" a="users">
                <NavLink
                  to="/account"
                  activeClassName="active"
                  className="nav-link-menu"
                >
                  <p>Quản lý tài khoản</p>
                </NavLink>
              </Can>
            </li> */}
            <li>
              <NavLink
                to='/tracking'
                activeClassName='active'
                className='nav-link-menu'
              >
                <p>{t('follow')}</p>
                <ul className='list-sub'>
                  <li>
                    <NavLink
                      to='/tracking/user'
                      activeClassName='activesub'
                      className='nav-link-menu nav-link-menu-sub'
                    >
                      <p className='-sub'>{t('followCustomer')}</p>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to='/tracking/product'
                      activeClassName='activesub'
                      className='nav-link-menu nav-link-menu-sub'
                    >
                      <p className='-sub'>{t('followProduct')}</p>
                    </NavLink>
                  </li>
                </ul>
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/setting_project'
                activeClassName='active'
                className='nav-link-menu'
              >
                <p>{t('projectSetting')}</p>
              </NavLink>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    setting: state.user?.user?.settings
  };
};

export default connect(mapStateToProps, null)(withTranslation('menu')(withRouter(Menu)));
