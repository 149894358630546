import React from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const ProductTemplateManager = (props) => {
  const { idProduct, t } = props;

  return (
    <div className="manager_menu">
      <ul id="list-menu" className="manager_menu_list d-flex">

        <li>
          <NavLink
            to={`/product/template/edit/${idProduct}/information`}
            activeClassName="active"
            className="manager_menu_list_navLink"
          >
            <span>{t('commonInfomation')}</span>
          </NavLink>
        </li>

        <li>
          <NavLink
            to={`/product/template/edit/${idProduct}/picture`}
            activeClassName="active"
            className="manager_menu_list_navLink"
          >
            <span>{t('productTemplateGallery')}</span>
          </NavLink>
        </li>

      </ul>
    </div>
  )
}

export default withTranslation('product')(ProductTemplateManager);
