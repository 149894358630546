import { postDataWithParams, getDataByID, getTakenData, postData,putData, putDataWithUrl } from './../services/base_services';
import { GET_DISTRIBUTOR_SELECT_URL, GET_DISTRIBUTOR_URL, NEW_DISTRIBUTOR_URL, DETAIL_DISTRIBUTOR_URL, UPDATE_DISTRIBUTOR_URL, UPDATE_LIST_CUSTOMER_IN_DISTRIBUTION } from '../constants/Config';
import * as types from './../constants/actionType';
import { destructServerErrors, destructServerMessage } from '../helpers/error';

const distributorRequest = status => {
  return {
    type: types.DISTRIBUTOR_REQUEST,
    status
  }
}

// GET LIST DESIGNER

const getDistributor = (data, paginate) => {
  return {
    type: types.GET_DISTRIBUTOR_LIST,
    data,
    paginate
  }
}



// GET DISTRIBUTOR SELECT
export const getDistributorSelectRequest = () => {
  return getTakenData(GET_DISTRIBUTOR_SELECT_URL).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}

export const getDistributorRequest = (search, params) => dispatch => {
  dispatch(distributorRequest(true));
  postDataWithParams(GET_DISTRIBUTOR_URL, search, params).then(res => {
    dispatch(getDistributor(res.data, res.paginate));
    dispatch(distributorRequest(false));
  }).catch(error => {
    dispatch(distributorRequest(false));
    return Promise.reject(error)
  })
}

export const resetState = () => {
  return {
    type: types.RESET_DISTRIBUTOR
  }
}

const createDistributor = (data, status) => {
  return {
    type: types.CREATE_DISTRIBUTOR,
    data,
    status
  }
}

export const createDistributorRequest = (data) => dispatch => {
  dispatch(distributorRequest(true));
  return postData(NEW_DISTRIBUTOR_URL, data).then(res => {
    dispatch(createDistributor(res.data, true));
    dispatch(distributorRequest(false));
    return Promise.resolve({ res });
  }).catch((error) => {
    let errs = destructServerErrors(error);
    dispatch(createDistributor(errs, false));
    dispatch(distributorRequest(false));
    return Promise.reject(error)
  });
}


const getDistributorDetail = (data, status) => {
  return {
    type: types.GET_DISTRIBUTOR,
    data,
    status
  }
}

export const getDetailDistributorRequest = (id) => dispatch => {
  dispatch(distributorRequest(true));
  return getDataByID(DETAIL_DISTRIBUTOR_URL, id).then(res => {
    dispatch(getDistributorDetail(res.data, true));
    dispatch(distributorRequest(false));
    return Promise.resolve({ res });
  }).catch((error) => {
    let errs = destructServerMessage(error);
    dispatch(getDistributor(errs, false));
    dispatch(distributorRequest(false));
    return Promise.reject(error)
  });
}



const updateDistributor = (data, status) => {
  return {
    type: types.EDIT_DISTRIBUTOR,
    data,
    status
  }
}

export const updateDistributorRequest = (id, data) => dispatch => {
  dispatch(distributorRequest(true));
  return putData(UPDATE_DISTRIBUTOR_URL, id, data).then(res => {
    dispatch(updateDistributor(res.data, true));
    dispatch(distributorRequest(false));
    return Promise.resolve({ res });
  }).catch((error) => {
    let errs = destructServerErrors(error);
    dispatch(updateDistributor(errs, false));
    dispatch(distributorRequest(false));
    return Promise.reject(error)
  });
}


// UPDATE
export const updateRequest = (data) => {
  return putDataWithUrl(UPDATE_LIST_CUSTOMER_IN_DISTRIBUTION, data).then(res => {
    return Promise.resolve({ data: res.data });
  }).catch(err => {
    let msg = destructServerErrors(err);
    return Promise.reject({ err: msg });
  })
}