import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import { Media } from '../container/Media';

const MediaRoutes = props => {
  const routes = [
    {
      path: '/media/:id?',
      render: (props) => <Media {...props}/>,
      exact: true
    }
  ];

  return (
    <div>
      <Switch>
        {
          routes.map((item, index) => {
            return <Route {...item} key={index}/>;
          })
        }
      </Switch>
    </div>
  )
}

export default MediaRoutes
