import React, { useState, useEffect } from 'react';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';
import CurrencyInput from 'react-currency-input';

const InputCurrency = React.forwardRef((props, ref) => {
  let { maxLength, errors, ...input } = props;

  const [text, setText] = useState(input.defaultValue || '');

  const onChangeText = (event, maskedvalue, floatvalue) => {
    setText(floatvalue);
  }

  useEffect(() => {
    setText(input.defaultValue);
  }, [input.defaultValue]);

  return (
    <div className={checkError(errors, input.name)}>
          { input.label && <label className="input-label">{input.label}</label> }
          <CurrencyInput
            precision="0"
            maxLength={maxLength}
            value={text}
            onChangeEvent={onChangeText}
            {...input}
          />
          <input type="hidden" defaultValue={text} ref={ref}/>
          <Alert messsage={getError(errors, input.name)} />
    </div>
  )
});

export default InputCurrency;
